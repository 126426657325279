.people {
    background: transparent url('/user/img/people/all-people-map-bg.png') left top fixed;
    background-size: contain;
    .film-readmore {
        position: relative;
        overflow: hidden;
        top: 5px;
    }
    .readMoreSubWrapper{
        &.expand{
            height: auto !important;
            max-height: none;
        }
    }
    .removePipe.view-btn::before {
        content: "";
        padding-right: 0px;
    }
    .view-btn {
        // background: transparent url('/user/img/people/explore-icon.png') 65px -1px no-repeat;
       /* &:after {
            right: 20px;
            top: 1px;
        }*/
    }
    .read-mores {
        &:hover {
            color: #4d4d4d;
        }
        position: relative;
        bottom: 0px;
        padding-right: 5px;
        left: 0px;
        color: #E61B72;
        font-size: 12px;
        text-transform: uppercase;
        font-family: 'Merriweather Sans';
        cursor: pointer;
        float: left;
        padding-top:4px;
    }
    .logo-with-filter {
        z-index: $zIndex-people-logo;
        margin-left: 3.7%;
        .category-logo {
            padding-top: 32px;
            color: $dark-grey;
        }
        & > a {
            margin-top: 11px;
        }
    }
    .readMoreContentWrapper {
        height: 100%;
        overflow: hidden;
        @media all and (max-width: 992px) {
            height: 118px;
        }
    }
    .slide-nav-list {
        z-index: $zIndex-people-filter-pardah1;
    }
    .people-grid-view {
        width: 75%;
        margin: 0 auto;
        position: relative;
        @include media($tab-landscape) {
            width: 88%;
            margin-left: 122px;
        }
        @include media($tab-portrait) {
            width: 85%;
            margin-left: 115px;
        }
        .people-grid-content-wrapper {
            background: transparent url('/user/img/people/all-people-content-bg.png') left top;
            background-size: 100% auto;
            background-repeat: no-repeat repeat;
            display: inline-block;
            overflow: visible;
            min-height: 900px;
            width: 100%;
            padding-bottom: 15em;
            .people-grid-wrapper {
                margin: 0 40px;
                @include media($tab-portrait) {
                    margin: 0 22px;
                }
                ul {
                    @media all and (max-width: 992px) {
                        float: left;
                        width: 100%;
                    }
                }
                .image {
                    img {
                        width: 99%;
                        border: 3px solid #FFF;
                        box-shadow: 1px 1px 10px #777;
                    }
                }
                .pagination-head {
                    margin-top: 75px;
                }
                .row-wrapper {
                    clear: both;
                    height: 185px;
                    padding-top: 40px;
                    .image {
                        float: left;
                        width: 255px;
                        text-align: left;
                        height: 100%;
                        img {
                            height: 100%;
                        }
                    }
                    .people-wrapper {
                        text-align: left;
                        float: left;
                        width: calc(97% - 255px);
                        margin-left: 2%;
                        @media all and (max-width: 992px) {
                            width: 100%;
                            float: left;
                            margin-top: 15px;
                            margin-left: 0px;
                        }
                        a {
                            &:nth-child(2) {
                                border-right: solid thin;
                                padding-right: 10px;
                            }
                            &:nth-child(3) {
                                margin-left: 10px;
                                background-position: 55px -1px;
                            }
                            &:nth-child(2):not(.read-link) {
                                border: none;
                                padding-right: 0;
                                background-position: 55px -1px;
                            }
                        }
                        .people-info {
                            font-size: $medium;
                            font-weight: 300;
                            font-family: 'Merriweather';
                            color: $grey;
                            height: 120px;
                            overflow: hidden;
                            //  line-height: 1.45em;
                            line-height: 23px;
                            margin-top: 1px;
                            @media all and (max-width: 992px) {
                                height: auto;
                            }
                            .title {
                                text-transform: Capitalize;
                                font-size: $extra-large;
                                color: $dark-grey;
                                margin-right: 5px;
                                display: inline-block;
                                margin-top: 2px;
                                &:hover {
                                    color: $pink;
                                }
                            }
                            .job-title {
                                text-transform: uppercase;
                                font-size: $small;
                                color: $grey;
                                font-family: 'Merriweather Sans';
                            }
                            .comma-space:after {
                                content: "\00a0";
                            }
                        }
                        .explore {
                            background: transparent url('/user/img/people/explore-icon.png') 55px 3px no-repeat;
                            background-size: 15px 16px;
                            display: inline-block;
                            float: left;
                            width: 100px;
                            color: $pink;
                            font-weight: 300;
                            font-size: $small;
                            font-family: 'Merriweather Sans';
                            text-transform: Uppercase;
                            margin-top: 5px;
                            &:hover {
                                color: $dark-grey;
                            }
                        }
                        .read-link {
                            color: $pink;
                            font-weight: 300;
                            font-family: 'Merriweather Sans';
                            font-size: $small;
                            margin-top: 5px;
                            float: left;
                            text-transform: uppercase;
                            &:hover {
                                color: $dark-grey;
                            }
                        }
                    }
                }
            }
        }
    }
    #page-wrapper {
        width: 100%;
        min-height: 750px;
        .category-logo {
            font-size: 18px;
        }
    }
    .sadhus {
        background: transparent url('/user/img/people/sadhus.png') center bottom fixed;
        background-size: contain;
    }
    .yoginis {
        background: transparent url('/user/img/people/yoginis.png') center bottom fixed;
        background-size: contain;
    }
    .hansas {
        background: transparent url('/user/img/people/hansas.png') center bottom fixed;
        background-size: contain;
    }
}
