/*
 This page displays common structure that may include:
 - alphabet filter
 - toggle between original and english links
 - number of items displayed on the page
 - search bar
 - icons for list and grid view
*/


$tab-portrait: new-breakpoint(max-width 768px);

.pagination-head {
    /*margin-top: 75px;
    position: fixed;
    width: inherit; */

    width: 100%;
    padding: 0 0 3% 0;
    margin-top: 90px;
    float: left;


  & > .upper {
    display: inline-block;
    width: 100%;
    float: left;
    padding-bottom: 5px;
    border-bottom: solid 1px #ccc;

    .toggle {
      font-size: $small;
      text-transform: uppercase;
      width: 165px;
      float: left;
      li {
        float: left;

        &:first-child {
          & > span {
            padding-left: 0;
          }
        }

        &:last-child {
          & > span {
            border: none;
          }
        }

        & > span {
          float: left;
          border-right: solid 1px #575756;
          padding: 0 12px;
          line-height: 12px;
          cursor: pointer;
          color: $color-link;
          cursor: pointer;
          text-transform: uppercase;
        }



        & > .active {
          cursor: default;
          color: $color-active-link;
          text-decoration: underline;
        }
      }
    }

    .numeric-count {
      float: left;
      line-height: 12px;
      font-weight: 300;
      font-size: $small;
      color: $color-regular-text;
      text-transform: uppercase;
    }

    /* Do not remove this commented code, it is used for searchbar and view options design */
    /*.search-box {
        float: left;
        margin: 3px 20px 0 0;

        .search {
            width: 150px;
            border-radius: 3px;
            border: solid 1px #ccc;
            height: 28px;
            line-height: 18px;
            outline: 0;
            float: left;
            background: #FFF;
            margin-top: -5px 8px 5px 0;
            display: none;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
        &:after {
            content: '';
            display: inline-block;
            width: 20px;
            height: 19px;
            background: transparent url('/user/img/songs/icons/search.png') 0 0 no-repeat;
            cursor: pointer;
        }
    }

    .view-options {
        float: right;
        &>a {
            background: transparent url('/user/img/songs/icons/grid.png') 0 -1px no-repeat;
            width: 20px;
            height: 18px;
            display: inline-block;

            &.list {
               background-image: url('/user/img/songs/icons/list.png');
               background-position: 0;
            }
        }
    }

    .upper-right {
        float: right;
        width: 110px;
    }*/

  }

  & > .lower {
    .alphabets {
      display: inline-block;
      text-transform: uppercase;
      clear: both;
      text-align: left;
      padding: 7px 0;
      width: 100%;
      li {
        float: left;
        width: 3.7%;
        text-align: right;

        &:first-child {
            text-align: left;
        }
        a {
          color: $grey;
          cursor: pointer;
          font-size: $medium;
          font-weight: 300;

          &.active {
            color: $pink;
            cursor: default;

            &:hover {
                color: $pink;
            }
          }

          &:hover {
            color: $pink;
          }

        }
      }
    }
  }

}
