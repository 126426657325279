 /* Please do not remove any of the commented code below*/

$tab-portrait: new-breakpoint(max-width 768px);
$tab-landscape: new-breakpoint(max-width 1024px);
$desktop: new-breakpoint(min-width 1025px);

.thumbnails-grid {

  @include outer-container(100%);
  @include span-columns(12);

  .media {
    min-height: 250px;
    /*background: red;*/
    @include span-columns(3 of 12);


    @include media($desktop) {
         @include omega(4n);
    }

    @include media($tab-landscape) {
        @include omega-reset(4n);
        @include span-columns(4 of 12);
        @include omega(3n);
    }

    @include media($tab-portrait) {
        @include omega-reset(3n);
        @include span-columns(6 of 12);
        @include omega(2n);
    }
  }


  /*display: inline-block;
  width: 100%;

  .media {
    min-height: 250px;
    width: 25%;
    float: left;
    background: red;
  }*/
  &:after {
    content: "";
    padding-bottom: 6em;
  }
}

