.grid-layout {
    background: transparent url(/user/img/common/bg/bg_pattern.png) fixed repeat;
    background-size: contain;
    margin: 0;

    .logo-with-filter {
        @include media($desktop) {
            margin-left: 3%;

        }
        @include media($tab-landscape) {
            margin-left: 4%;

        }


    }

    .grid-view {
        float: left;
        margin-left: 130px;
        width: 99%;
        width: calc(100% - 130px);

        @include media($tab-portrait) {
            margin-left: 110px !important;
        }

        .pagination-head {


            @include media($desktop) {
                width: 84% !important;
                left: 12%;

            }

            @include media($tab-landscape) {
                width: 79% !important;
                left: 16%;

            }
            @include media($tab-portrait) {
                width: 70% !important;
                left: 20%;

            }


        }
    }


    .grid-wrapper {
        clear: both;
        /*overflow: auto; */
    }
}