.words-wrapper {
    background: transparent url("/user/img/words/all_words_bg.jpg") 0 0 repeat;
    min-height: 1400px;
    width: 1140px;
    margin: 0 auto;
    display: block;
    position: relative;
    background-size: cover;
    background-position: center center;
    @media screen and (max-width: 1280px) {
        width: 100%;
    }

    .homepage-content {
        height: auto !important;
        min-height: 200px;
        margin-top: 1em;
        padding-bottom: 17em;
        z-index: 2;
    }

    .page-header {
        width: 90%;
        padding-top: 3%;
        @media screen and (max-width: 1280px) {
          padding-top: 50px;
        }

        .logo-container > a {
            position: relative;
            top: 13px;
            color: $dark-grey;
            cursor: default;
        }
    }

    .pagination-head {
        width: 85%;
        margin-left: 2%;
        margin-top: 48px;
    }

    &:after,
    &:before {
        content: '';
        display: inline-block;
        background: transparent url("/user/img/mainLandingPage/parallax/side_pattern.png") 0 0;
        width: 45px;
        background-repeat: no-repeat repeat;
        position: absolute;
        top: 0;
        height: 100%;
        @media screen and (max-width: 1280px) {
            display: none;
        }
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .thumbnailWrapper {
        padding-bottom: 20px;

        .thumbnail-desc {
            position: relative;
            cursor: pointer;
            display: inline-block;
            margin: 0 auto;

            &:hover {
                .sub-title,
                .title {
                    color: #ee2a7b;
                }
            }

            .title {
                font-family: 'Merriweather';
                font-size: 16px;
                font-weight: 300;
                color: #4e4e4e;
                text-transform: capitalize;
                line-height: 1.5em;
            }

            .sub-title {
                font-family: 'Merriweather';
                font-size: 14px;
                font-weight: 300;
                font-style: italic;
                color: #989798;
                text-transform: capitalize;
                line-height: 1.2em;
            }

            .show-desc {
                position: absolute;
                top: 40px;
                right: -240px;
            }

            .bubble {
                position: relative;
                z-index: 1;
                width: 249px;
                background: #fff;
                padding: 0 10px 3px;
                cursor: pointer;
                text-align: left;
                box-shadow: 0 -6px 7px #a7a7a7;
                font-weight: 200;
                font-size: 12px;
                color: #868686;
                line-height: 18px;

                &:after,
                &:before {
                    content: '';
                    background-size: cover;
                    display: inline-block;
                    height: 20px;
                    left: 0;
                    width: 265px;
                    position: absolute;
                }

                &:before {
                    background: rgba(255, 255, 255, 0) url("/user/img/common/directives/thumbnail/top.png") left top no-repeat;
                    top: -19px;
                }

                &:after {
                    content: '';
                    background: rgba(255, 255, 255, 0) url("/user/img/common/directives/thumbnail/bottom.png") left -5px no-repeat;
                    bottom: -20px;
                }

                .more-info {
                    color: #a7a9ac;
                    font-size: 10px;
                }
            }
        }
    }

    .toggle {
        margin-bottom: 3px !important;
    }

    .upper {
        padding-bottom: 3px;
    }
}
