#disqus_thread {
    @media all and (max-width: 1024px) {
        margin-top: 0px;
    }
}
.couplet-page-main-wrap{
      height: auto;
      min-height: 100%;
      width: 100%;
      background: transparent url(/user/img/couplets/couplets_bg.jpg) repeat;
      background-size: cover;
      position: relative;
}
.couplet-details-page-wrap{
      width: 100%;
      background: transparent url(/user/img/couplets/couplets_bg.jpg) repeat;
      position: relative;
      background-position:center 0%;
}
#couplets_details {
    height: 100%;
    min-height: 100%;
    width: 100%;
    /*background: transparent url(/user/img/couplets/couplets_bg.jpg) repeat;*/
    background-size: cover;
    position: relative;
    .notes-desc.text-typography > div {
        padding-top:15px;
        padding-bottom:15px;
    }
    .notes-desc.text-typography > div p{
        margin:0px !important;
        padding-top:10px;
    }
    @media(max-width: 1250px) and (min-width: 1025px) {
        background-position: 45%;
    }
    @media(max-width: 1024px) {
        background-position: 40%;
    }
    @media(max-width:992px) {
        background-position: center;
        background: transparent url(/user/img/couplets/Couplet_background-992.jpg) repeat;
    }
    @media(min-width: 768px) and (max-width: 770px) {
        background: transparent url(/user/img/couplets/Couplet_background-06.jpg) repeat;
    }
    & .page-header {
        background: transparent url(/user/img/couplets/pardah_long.png) -800px bottom no-repeat;
    }
    .page-title {
        margin-left: 100px;
        text-align: left;
    }
    .hindi {
        font-family: 'Lohit Devanagari', serif !important;
        color: #808080 !important;
        line-height: 1.8em !important;
        & p {
            font-family: 'Lohit Devanagari', serif !important;
            color: #808080 !important;
            line-height: 1.5em !important;
            font-size:18px !important;
            font-weight:100;
        }
    }
    .translation-block {
        height: 30px;
        margin-top: 10px;
    }
    .shareItems {
        position: absolute;
        bottom: 70px;
    }
    .link {
        cursor: pointer;
        &:hover {
            color: $pink;
        }
    }
    .attributed {
        color: $clickable-grey;
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .sub-main {
        font-size: 16px !important;
        font-style: italic;
        color: $color-regular-text;
        font-family: 'Merriweather';
    }
    .attributeIconsBlock {
        text-align: center;
        position: absolute;
        font-size: 14px;
        bottom: 80px;
        width: 100%;
        font-weight: 300;
        & span {
            font-size: 14px;
            & .link {
                font-size: 14px;
                color: $dark-grey;
                &:hover {
                    color: $pink;
                }
            }
        }
        & .translation {
            & .link {
                color: $pink;
                &:hover {
                    color: $dark-grey !important;
                }
            }
        }
    }
    .coupletsScrollWidth {
        width: 550px;
        max-height: 150px;
    }
    .page-header {
        //background: transparent url(/user/img/couplets/pardah_long.png) -800px bottom no-repeat;
    }
    .coupletsDetailScroll {
        height: 175px;
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 10px;
        margin-top: 15px;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    ul.share-options {
        text-align: left;
        float: left;
        position: relative;
        font-size: 16px;
        width: auto;
        top: 30px;
        left: 55px;
        li {
            padding: 10px;
            float: left;
            color: #666;
            font-weight: 200;
            line-height: 20px;
            a {
                background: transparent url(/user/img/common/icons/sprite_icons.png) 0 0 no-repeat;
                display: inline-block;
                width: 26px;
                height: 23px;
                &.favorite {
                    background: transparent url(/user/img/songs/songs_sprite.png) 0 0 no-repeat;
                    background-position: 0 -25px;
                    height: 22px;
                }
                &.share {
                    width: 16px;
                    height: 15px;
                    background-position: -285px -15px;
                    &.active {
                        width: 16px;
                        height: 15px;
                        background-position: -199px -241px;
                        cursor: default;
                    }
                }
                &.comment {
                    background-position: -87px -280px;
                    width: 21px;
                    height: 16px;
                    &:hover {
                        background-position: -228px -280px;
                    }
                }
                &.comment-icon-active {
                    background-position: -228px -280px !important;
                }
                &.download {
                    width: 18px;
                    height: 17px;
                    background-position: -189px -15px;
                    &.active {
                        width: 18px;
                        height: 17px;
                        background-position: -15px -62px;
                        cursor: default;
                    }
                }
            }
        }
    }
    .ajab-wrapper {
        width: 98%;
        padding: 0;
        display: block;
        margin: 0 auto;
        .logo-container {
            position: absolute;
            & .page-logo {
                color: $pink;
                padding: 4px;
                background-color: white;
                border-radius: 30px;
                opacity: 0.85;
                &:hover {
                    color: $dark-grey;
                }
            }
            @media screen and (max-width: 768px) {
                left: 0.5%;
            }
            & > a {
                font-size: 14px;
                color: $dark-grey;
            }
        }
        .content-wrapper {
            box-sizing: border-box;
            padding: 2%;
            width: 70%;
            text-align: left;
            margin: -30px auto 0;
            min-height: 1300px;
            font-weight: 300;
        }
    }
    .englishTranslation,
    .coupletsPoet {
        color: $clickable-grey;
    }
    .coupletsPoet {
        color: $clickable-grey;
        margin-bottom: 10px;
        & .link {
            color: $pink;
            &:hover {
                color: $dark-grey;
            }
        }
    }
    .translation-toggle {
        & .link {
            color: $pink;
            &:hover {
                color: $dark-grey;
            }
        }
        & .active {
            color: $dark-grey !important;
            text-decoration: underline;
        }
        text-align: center;
        color: $clickable-grey;
        font-size: 12px;
    }
    .translation-icons {
        text-align: center;
        position: relative;
        .no-margin-left {
            margin-left: 0 !important;
        }
        ul {
            display: inline-block;
            width: auto;
            & > li {
                float: left;
                margin-left: 30px;
                &:first-child {
                    margin-left: 0;
                }
                & > a {
                    float: left;
                    width: 36px;
                    height: 36px;
                    background: transparent url(/user/img/common/icons/sprite_icons.png) 0 0 no-repeat;
                    &.active {
                        cursor: default;
                    }
                }
                &.original {
                    & > a {
                        width: 39px;
                        height: 39px;
                        background-position: -84px -200px;
                        &:hover,
                        &.active {
                            width: 39px;
                            height: 39px;
                            background-position: -153px -131px;
                        }
                        &.disabled {
                            width: 39px;
                            height: 39px;
                            background-position: -15px -200px;
                        }
                    }
                }
                &.transliteration {
                    & > a {
                        width: 39px;
                        height: 40px;
                        background-position: -15px -270px;
                        &:hover,
                        &.active {
                            width: 39px;
                            height: 40px;
                            background-position: -289px -60px;
                        }
                        &.disabled {
                            width: 39px;
                            height: 40px;
                            background-position: -291px -200px;
                        }
                    }
                }
                &.translation {
                    & > a {
                        width: 39px;
                        height: 39px;
                        background-position: -84px -131px;
                        &:hover,
                        &.active {
                            width: 39px;
                            height: 39px;
                            background-position: -111px -62px;
                        }
                        &.disabled {
                            width: 39px;
                            height: 39px;
                            background-position: -15px -131px;
                        }
                    }
                }
            }
        }
    }
    .footer-block-couplets {
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    .couplets_container {}
    .couplets_content {
        width: 550px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-weight: 200;
        height: 100%;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        @media all and (max-width: 1024px) {
            height: calc(100% - 140px);
            padding-bottom: 75px;
        }
        @media (max-width:1024px) and (min-width:993px) {
            width: 600px;
        }
        & div {
            @media (max-width: 1024px) and (min-width: 993px) {
                width: 100% !important;
            }
        }
        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
            font-weight: 100 !important;
            color: #4D4D4D !important;
        }
        & h1 {
            font-size: $double-x-large !important;
            padding: 0 !important;
            margin-bottom: 5px;
            margin-top: 5px;
            span {
                font-size: inherit;
            }
        }
        & h2 {
            font-size: $extra-large !important;
            padding: 0 !important;
            margin-bottom: 5px;
            margin-top: 5px;
            span {
                font-size: inherit;
            }
        }
        & h3 {
            font-size: $large !important;
            padding: 0 !important;
            margin-bottom: 5px;
            margin-top: 5px;
            span {
                font-size: inherit;
            }
        }
        & h4 {
            font-size: $medium-large !important;
            padding: 0 !important;
            margin-bottom: 5px;
            margin-top: 5px;
            span {
                font-size: inherit;
            }
        }
        & h5 {
            font-size: $small !important;
            padding: 0 !important;
            margin-bottom: 5px;
            margin-top: 5px;
            span {
                font-size: inherit;
            }
        }
        & h6 {
            font-size: $extra-small !important;
            padding: 0 !important;
            margin-bottom: 5px;
            margin-top: 5px;
            span {
                font-size: inherit;
            }
        }
        & p {
            font-size: 15px;
            font-family: 'Merriweather';
            line-height: 1.4em;
            color: $dark-grey;
            width: 510px;
            margin-left: auto;
            margin-right: auto;
            text-align: center!important;
            @media all and (max-width: 1024px) {
                width: 100%;
            }
        }
        & span {
            font-size: inherit;
            & .link {
                font-size: 14px;
                color: $pink;
                &:hover {
                    color: $dark-grey;
                }
            }
        }
    }
    .left-nav {
        width: 70px;
        left: 0;
        top: -5px;
        position: absolute;
        display: inline-block;
        text-align: left;
        -webkit-transition: all 0.3s linear -0.1s;
        transition: all 0.3s linear -0.1s;
        margin-top: 155px;
        & ul {
            margin-left: 0;
            & li {
                width: 85px;
                padding: 0 32px;
                padding-bottom: 2px;
                padding-left: 15px;
                & a {
                    display: block;
                    font-weight: 300;
                    font-family: 'Merriweather';
                    color: #e61b72;
                    font-size: 14px;
                    margin-top: 8px;
                    margin-bottom: 3px;
                    &:hover {
                        color: $dark-grey;
                    }
                }
                & a.active {
                    cursor: default;
                    color: #4d4d4d;
                    font-weight: 300;
                    text-decoration: underline;
                }
            }
            .seperater {
                background: transparent url('/user/img/songs/sep2.png');
                background-size: 300px 10px;
                background-position: left bottom;
                background-repeat: no-repeat;
                height: 7px;
            }
        }
    }
    .ajab-wrapper {
        width: 98%;
        padding: 0;
        display: block;
        margin: 0 auto;
        .logo-container {
            position: absolute;
            @media screen and (max-width: 768px) {
                left: 0.5%;
            }
            & > a {
                font-size: 18px;
                color: $pink;
                &:hover {
                    color: $dark-grey;
                }
            }
        }
        .content-wrapper {
            box-sizing: border-box;
            padding: 2%;
            width: 70%;
            text-align: left;
            margin: -30px auto 0;
            min-height: 1300px;
            font-weight: 300;
        }
    }
    .translation-icons {
        text-align: center;
        ul {
            display: inline-block;
            width: auto;
            & > li {
                float: left;
                margin-left: 30px;
                &:first-child {
                    margin-left: 0;
                }
                & > a {
                    float: left;
                    width: 36px;
                    height: 36px;
                    background: transparent url(/user/img/common/icons/sprite_icons.png) 0 0 no-repeat;
                    &.active {
                        cursor: default;
                    }
                }
                &.original {
                    & > a {
                        width: 39px;
                        height: 39px;
                        background-position: -84px -200px;
                        &:hover,
                        &.active {
                            width: 39px;
                            height: 39px;
                            background-position: -153px -131px;
                        }
                        &.disabled {
                            width: 39px;
                            height: 39px;
                            background-position: -15px -200px;
                        }
                    }
                }
                &.transliteration {
                    & > a {
                        width: 39px;
                        height: 40px;
                        background-position: -15px -270px;
                        &:hover,
                        &.active {
                            width: 39px;
                            height: 40px;
                            background-position: -289px -60px;
                        }
                        &.disabled {
                            width: 39px;
                            height: 40px;
                            background-position: -291px -200px;
                        }
                    }
                }
                &.translation {
                    & > a {
                        width: 39px;
                        height: 39px;
                        background-position: -84px -131px;
                        &:hover,
                        &.active {
                            width: 39px;
                            height: 39px;
                            background-position: -111px -62px;
                        }
                        &.disabled {
                            width: 39px;
                            height: 39px;
                            background-position: -15px -131px;
                        }
                    }
                }
            }
        }
    }
    .lyrics-footer {
        background: transparent url(/user/img/couplets/couplets_bottom.png) 0 0 no-repeat;
        height: 75px;
        width: 100%;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0px;
        .no-notes-margin {
            left: 5px !important;
        }
        .notes-link {
            position: absolute;
            left: 15px;
            bottom: 22px;
            font-size: $medium;
            color: $grey;
            font-weight: 300;
            & > a {
                font-size: $medium;
                color: $pink;
                text-transform: lowercase;
                font-family: 'Merriweather';
                font-weight: 500;
                &:hover {
                    color: $dark-grey;
                }
            }
        }
        .notes-toggle-active {
            color: $dark-grey !important;
            /*text-decoration: underline !important;*/
        }
        .play-icon {
            position: absolute;
            right: 13%;
            bottom: 40px;
            background: transparent url(/user/img/songs/songs_sprite.png) -52px 0 no-repeat;
            width: 50px;
            height: 102px;
            display: inline-block;
        }
        .crew-details {
            position: absolute;
            left: 30%;
            height: 45px;
            margin-left: -20px;
            top: 25px;
            width: 600px;
            line-height: 1.2em;
            text-align: center;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            @media all and (max-width: 1024px) {
                right: 0px;
                left: 15%;
                margin: 0px;
            }
            & .crew-block {
                margin-bottom: 10px;
                .crew-no-margin {
                    margin-right: 0 !important;
                }
                & .crew-person-block {
                    display: inline-block;
                    font-size: 14px;
                    font-family: 'Merriweather';
                    margin-left: 30px;
                    & .italicise {
                        font-style: italic !important;
                        font-weight: 100;
                    }
                    &:first-child {
                        margin-left: 0px;
                    }
                    & .crew-name {
                        & .italicise {
                            font-style: italic !important;
                            font-weight: 100;
                        }
                        & a {
                            color: $grey;
                            font-weight: 300;
                            font-family: 'merriweather';
                            &:hover {
                                color: $pink;
                            }
                        }
                    }
                    & .crew-dept {
                        color: $light-grey;
                        font-family: 'Merriweather Sans';
                        font-size: 10px;
                    }
                }
            }
        }
    }
    .footer-block-couplets {
        width: 100%;
        position: absolute;
        bottom: 0;
    }
}

.disqus_thread {
    @media all and (max-width: 1024px) {
        margin-top: 0px;
    }
}

.notes-hide {
    display: none;
}

.comment-block-active {
    display: none;
}

.comment-block {
    height: 100%;
    min-height: 100%;
    width: 100%;
    /*background: transparent url(/user/img/couplets/couplets_bg.jpg) repeat;*/
    background-size: cover;
    position: relative;
    @media(max-width: 1024px) {
        background-position: 40%;
    }
    @media(max-width:992px) {
        background-position: center;
        background: transparent url(/user/img/couplets/Couplet_background-992.jpg) 0 -130px repeat;
    }
    @media(min-width: 768px) and (max-width: 770px) {
        background: transparent url(/user/img/couplets/Couplet_background-06.jpg) repeat;
    }
    iframe {
        @media(max-width: 1024px) {
            margin-top: 150px;
            height: auto !important;
        }
    }
}

.notes-detail {
    width: 100%;
   /* background: transparent url(/user/img/couplets/couplets_bg.jpg) repeat;*/
    background-size: cover;
    position: relative;
    & .footer-container {
        margin-top: 75px;
    }
    & .notes-desc-bg {
        background: transparent url(/user/img/couplets/notes-bg.png) repeat;
        width: 100%;
    }
    & .notes-desc {
        width: 750px;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        color: $dark-grey;
        font-family: 'Merriweather';
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $dark-grey !important;
        }
        & p {
            line-height: 1.5em;
            padding-bottom: 10px;
            text-align: left;
            margin:0px !important;
        }
        & .link {
            color: $pink;
            &:hover {
                color: $dark-grey;
            }
        }
        & .credits {
            & b {
                margin-bottom: 5px;
                display: block;
            }
            & ul {
                & li {
                    padding-bottom: 5px;
                }
            }
        }
    }
}

.player {
    position: absolute;
    width: 300px;
    top: 160px;
    left: 95px;
    display: none;
    .close-icon {
        background-color: white;
        color: #e61b72;
        width: 40px;
        height: 40px;
        padding: 10px;
        font-weight: bold;
        border-radius: 30px;
        text-align: center;
        line-height: 24px;
        font-size: 18px;
        position: absolute;
        right: -20px;
        top: -20px;
        cursor: pointer;
    }
    iframe {
        height: 300px;
    }
}
