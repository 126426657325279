.thumbnail.reflections {

    .en-title {
        font-family: 'Merriweather Sans';
        font-size: $medium;
        font-style: normal;
        color: $dark-grey;

        .author {
            text-transform: uppercase;
        }


    }

}