#glossary_page {
    min-height: 1210px;
    width: 100%;
    &.envelope-letter-bg {
        background: transparent url(/user/img/glossary/glossary_bg.jpg) 0 0 repeat fixed;
        background-size: contain;
    }
    &.yellow-trapezium-bg {
        background: transparent url(/user/img/glossary/yellow.jpg) 0 0 repeat fixed;
        background-size: contain;
    }
    &.chakras-bg {
        background: transparent url(/user/img/glossary/chakras.jpg) 0 0 repeat fixed;
        background-size: contain;
    }
    &.benzene-bg {
        background: transparent url(/user/img/glossary/benzene.jpg) 0 0 repeat fixed;
        background-size: contain;
    }
    &.ameoba-bg {
        background: transparent url(/user/img/glossary/ameoba.jpg) 0 0 repeat fixed;
        background-size: contain;
    }
    .ajab-wrapper {
        width: 90%;
        padding: 0;
        display: block;
        margin: 0 auto;
        .logo-container {
            position: fixed;
            top: 30px;
            left: 2.5%;
            @media screen and (max-width: 768px) {
                left: 0.5%;
            }
            & > a {
                font-size: 15px;
                color: $dark-grey;
            }
        }
        .content-wrapper {
            box-sizing: border-box;
            padding: 8% 4%;
            width: 85%;
            text-align: left;
            margin: -30px auto 0;
            min-height: 1300px;
            background: transparent url(/user/img/glossary/white_bg.png) 0 0 no-repeat repeat;
            background-size: contain;
            font-weight: 300;
            @media all and (max-width: 1024px) {
              padding-bottom: 150px;
            }
            dl {
                margin-bottom: 3em;
            }
            .word-title {
                font-family: 'Merriweather';
                font-size: $extra-large;
                color: $dark-grey;
                width: 100%;
                text-transform: capitalize;
                &.root-word {
                    color: $pink;
                }
                .related {
                    font-family: 'Merriweather Sans';
                    font-size: $small;
                    color: $grey;
                    .hindi {
                        font-family: 'Lohit Devanagari Sans';
                        font-size: $small-dev;
                    }
                }
            }
            .word-meaning {
                width: 100%;
                font-size: $medium;
                font-family: 'Merriweather';
                line-height: 21px;
                color: $grey;
                margin: 0;
                margin-top: 8px;
                line-height: 1.45em;
            }
            .pagination-head {
                display: inline-block;
                width: 100%;
                padding: 0% 0 2%;
                margin-top: -6px;
                margin-left: 2px;
                @media all and (max-width: 1024px) {
                    margin-top: 25px;
                }
                & > .upper {
                    display: inline-block;
                    width: 100%;
                    float: left;
                    padding-bottom: 5px;
                    border-bottom: solid 1px #ccc;
                }
            }
        }
    }
}
