.logo-with-filter {

    position: fixed;
    left: 0;
    top: 30px;
    margin-left: 30px;
    z-index: $zIndex-songs-leftNav;

    .nav-elements-wrapper {
      position: relative;
      .category-logo {
        background: transparent url('/user/img/songs/songs_logo.png') 0 0 no-repeat;
        width: 85px;
        height: 85px;
        padding-top: 30px;
        color: $pink;
        font-size: 20px;
        font-weight: 300;

        &:hover {
           color: $dark-grey;
           cursor: pointer;
        }
      }


        .category-logo-all {
        background: transparent url('/user/img/songs/songs_logo.png') 0 0 no-repeat;
        width: 85px;
        height: 85px;
        padding-top: 30px;
        color: $dark-grey;
        font-size: 20px;
        font-weight: 300;

        &:hover {
           color: $dark-grey;
           cursor: default;
        }
      }

       .category-logo-reflection {
        background: transparent url('/user/img/songs/songs_logo.png') 0 0 no-repeat;
        width: 85px;
        height: 85px;
        padding-top: 30px;
        color: $dark-grey;
        font-size: 20px;
        font-weight: 300;

        &:hover {
           color: $dark-grey;
           cursor: default;
        }
      }

      .filter-button {
        display: block;
        margin-top: 7px;
        font-size: $small;
        text-transform: uppercase;
        color: $color-link;
        margin-left: 10px;

        & > .collapse {
          background: transparent url('/user/img/common/icons/minus.png') 0 0 no-repeat;
          display: inline-block;
          height: 9px;
          width: 9px;

          &.expand {
            background-image: url('/user/img/common/icons/cross.png');

          }
        }

      }
    }
}