#searchUI_page {
  .no-results {
    min-height: 600px;
    margin: 0 auto;
    position: relative;
    background-size: cover;
    font-family: 'Merriweather Sans', 'Merriweather', lucida grande, arial, tahoma, verdana, sans serif;
    background: transparent url('/user/img/search/no-results.png') 0 0 repeat fixed;
  }
  .no-results-container {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 200px;
    line-height: 1.5em;
    position: relative;
    h1 {
      font-size: $large;
      margin-bottom: 10px;
    }
    p {
      color: $light-grey;
      font-family: 'Merriweather';
    }
  }
  .ajab-wrapper {
    width: 100%;
    .logo-container {
      margin-bottom: 20px;
      a {
        cursor: default!important;
        text-align: center !important;
        color: #4D4D4D !important;
      }
    }
  }
  .reset-image {
    background: transparent url('/user/img/songs/underline_curved.png') no-repeat;
    margin-top: 25px;
    width: 14em;
    background-size: contain;
    background-position: -2em 0;
    margin-left: -20px;
    a {
      text-transform: uppercase;
      font-weight: 300;
      font-family: 'Merriweather Sans';
      color: $grey;
      cursor: pointer;
      font-size: 10px;
      padding-top: 2em;
      display: block;
      margin-left: 20px;
      &:hover {
        color: $pink;
      }
    }
  }
  .searchContainer {
    min-height: 900px;
    margin: 0 auto;
    display: block;
    position: relative;
    background-size: cover;
    font-family: 'Merriweather Sans', 'Merriweather';
    background: transparent url('/user/img/search/search_bg.png') 0 0 repeat fixed;
    .category-logo {
      font-size: 16px;
      text-align: center;
      padding-top: 27px;
      color: $dark-grey;
      cursor: default;
      a {
        cursor: default;
      }
    }
    .filter {
      margin-top: 50px;
      .filterByText {
        margin-top: 50px;
        line-height: 1.5em;
        font-size: 10px;
        text-transform: uppercase;
        color: #b3b3b3;
        font-family: "merriweather sans";
      }
      ul {
        margin: 0;
        li {
          margin-top: 10px;
          margin-bottom: 10px;
          font-family: 'Merriweather';
          font-size: 14px;
          color: $grey;
          cursor: pointer;
        }
        .active {
          color: $pink;
        }
      }
    }
    .searchFilterParda {
      width: 242px;
      background: url('/user/img/search/search_parda.png') repeat fixed;
      height: 100%;
      position: fixed;
    }
    .searchResultsContainer {
      width: calc(100% - 150px);
      position: relative;
      left: 220px;
      padding: 50px;
      @media(max-width: 992px) {
        padding-left: 40px;
        left: 205px;
      }
      .searchRelatedTo {
        .song-video-cateIcon {
          background: url("/user/img/common/icons/gray_play.png") left 1px no-repeat;
          padding-left: 11px;
          margin-left: 11px;
          color: #808080;
          vertical-align: middle;
          display: inline-block;
          width: 9px;
          height: 16px;
        }
        .song-audio-cateIcon {
          background: transparent url('/user/img/common/icons/a_grey.png') left -1px no-repeat;
          padding-left: 17px;
          margin-left: 3px;
          color: $grey;
        }
      }
      span.search-author-block{
        width: 100%;
        display: inline-block;
      }
      span.title-text-category {
        font-family: 'Merriweather Sans';
        color: $grey;
        text-transform: lowercase;
        font-size: 12px;
        display: inline-block;
        margin-bottom:2px;
      }
      span.title-authors-names {
        text-transform: uppercase;
        font-family: 'Merriweather Sans';
        color: $grey;
        font-size: 12px;
        margin-bottom:2px;
      }
      span.first-letter-caps:first-letter {
        text-transform: uppercase;
      }
      .searchResults {
        width: 70%;
        padding-bottom: 200px;
        @media(max-width: 992px) {
          width: 100%;
          padding-right: 40px;
        }
        .resultsBlock {
          padding-top: 15px;
          padding-bottom: 15px;
          line-height: 20px;
          border-bottom: 1px dotted #808080;
          .resultsContent {
            position: relative;
            height: 100%;
            word-wrap: break-word;
            .resultsUIHeight {
              height: 120px;
              overflow: hidden;
            }
            .resultsUIHeight.thumbIsNotThere {
              height: auto;
              max-height: 120px;
            }
          }
          .upper-case {
            text-transform: uppercase;
          }
          .has-thumbnail-content {
            float: left !important;
            margin-left: 20px;
            width: calc(100% - 280px);
            .relatedTo {
              position: absolute;
              bottom: -7px;
              left: 0;
            }
          }
          .resultsImage {
            float: left;
            width: 255px;
            height: 100%;
            img {
              width: 99%;
              height: 100%;
              border: 3px solid #fff;
              box-shadow: 1px 1px 10px #777;
            }
          }
          p {
            font-size: 12px;
            color: $dark-grey;
            font-family: 'Merriweather';

          }
          h1 {
            font-weight: 400;
            &:hover {
              color: $dark-grey;
            }
            span {
              font-size: 12px;
              color: $grey;
              font-family: 'Merriweather Sans';
            }
            span.second-title-text {
              font-size: 100%;
              font-family: 'Merriweather';
              font-weight: normal;
              font-style:italic;
            }
          }
          .relatedTo {
            margin-top: 10px;
            font-size: 12px;
            color: $grey;
            text-transform: uppercase;
            .word-text {
              color: #808080;
              font-size: 16px;
              text-transform: uppercase;
              font-family: "Merriweather";
              font-weight: 300;
              padding-left: 5px;
            }
          }
        }
        .has-thumbnail {
          height: 181px;
        }
        .youSearched {
          margin-bottom: 10px;
          margin-top: 50px;
          font-family: 'Merriweather Sans';
          font-size: 18px;
          font-weight: 100;
          color: $grey;
          padding-bottom: 10px;
          border-bottom: 1px dotted $grey;
          span {
            font-family: 'Merriweather';
            font-size: 28px;
            color: $dark-grey;
          }
        }
      }
    }
  }
}
.word-title {
  .pink-text {
    color: $pink;
  }
  .grey-text {
    color: $grey;
  }
}
