#forgotPassword_page {
  width: 100%;
  min-height: 500px;
  background-color: #ccc;
}
.errorMsg{
  color: #E61B72;
  font-size: 12px;
}
.clearfix {
  clear: both;
}
.signup-error-messages{
  color: #E61B72;
  font-size: 12px;
}

.forgot_container {
  padding-top: 100px;
  padding-bottom: 200px;

  .forgot_box {
    width: 500px;
    min-height: 200px;
    padding: 30px;
    background-color: #fff;
    @include border-top-radius(20px);
    @include border-right-radius(20px);
    @include border-bottom-radius(20px);
    @include border-left-radius(20px);
    box-shadow: #8E8E8E 2px 0px 10px;
    margin-left: auto;
    margin-right: auto;
    .close {
      float: right;
      color: #4D4D4D;
      font-size: 12px;
    }
    h1 {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: $extra-large;
      color: $dark-grey;
    }
    .forgot_content {
      color: $grey;
      font-family: 'Merriweather Sans';
      font-size: $medium;
      .keepMe {
        a {
          color: $pink;
          float: right;
        }
      }
      p {
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 1.5em;
      }
      .join {
          float: left;
          width: 100px;
          background: #E61B72;
          margin: 10px 20px 10px 0px;
          text-align: center;
          line-height: 30px;
          color: #FFF;
          border-radius: 4px;
          outline: none;
          cursor: pointer !important;
          border: none;
          font-size: 14px;
          box-shadow: 0px 5px 0px 0px #bf145d;
          &:disabled {
              background: $light-grey !important;
              cursor: default !important;
              margin-top: 15px;
              box-shadow: 0px 0px;
          }
      }

      .join:hover {
          margin-top: 15px;
          margin-bottom: 5px;
          box-shadow: 0px 0px 0px 0px;
      }

      .tandc {
        a {
          text-decoration: underline;
        }
      }
      .signin {
        a {
          color: $pink;
        }
      }
      .dottedLine {
        height: 3px;
        border-bottom: 1px dotted $grey;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .form-control {
      margin-top: 5px;
      margin-bottom: 5px;
      input {
        padding: 10px;
        outline: none;
        width: 100%;
      }
      .col {
        float: left;
        width: 50%;
        .firstName  {
          width: 99%;
        }
      }
    }
  }
  .error-or-success-msg{
    color: #E61B72;
    margin-top: 10px;
  }
}
