#participate_page {

    min-height: 500px;
    background: transparent url(/user/img/participate/participate_landing.jpg) 0 0 repeat fixed;
    background-size: 100%;
    background-color: #EEE;
    .thumbnail img {
        height: 100%;
    }
    .clearfix {
        clear: both;
    }
    #userContribute a {
        cursor: pointer;
    }
    .logo-with-filter {
        position: absolute !important;
        z-index: 1;
    }
    .cursor_pointer {
        cursor: pointer;
    }
    .participateThumbailBlock {
        margin-top: 20px;
    }
    .participate-thumb {
        /* width: 250px;*/
        /* display: block;*/
        /* margin: 0 auto;*/
    }
    .participate-thumb .thumbnail {
        /* width: 100%;*/
        width: 250px;
        margin: 0 auto;
    }
    #container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        // @media all and (max-width: 1024px) {
        //     flex-direction: row;
        //     height: auto !important;
        // }
        // .participate-thumb {
        //     display: block;
        // }
        .media {
            min-height: 0px;
            width: 33.33%;
            margin-bottom: 48px;
            float: left;
            // @include media($tab-landscape) {
            //     width: 33%;
            //     position: relative !important;
            //     top:auto !important;
            //     left: auto !important;
            //     transform:none !important;
            // }
            @include media($tab-portrait) {
                width: 50%;
            }
            .bubble {
                position: relative;
                top: -4px;
                left: -9px;
            }
        }
    }
    .category-logo {
        padding-top: 33px !important;
        color: #4d4d4d !important;
        cursor: default !important;
        font-size: 18px !important;
        a {
            cursor: default !important;
            padding: 4px;
            border-radius: 30px;
            background-color: #FFF;
            margin-left: -8px;
                opacity: 0.85;
        }
    }
    .participateCount {
        text-align: center;
        font-size: 10px;
        color: $light-grey;
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: uppercase;
    }
    .contribute-block {
        padding-top: 150px;
        width: 900px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 200px;
        @media all and (max-width: 1024px) {
            width: 100%;
        }
        .contribute-header {
            min-height: 100px;
            border-bottom: 1px #CCC solid;
            position: relative;
            margin-left: 25px;
            margin-right: 25px;
            .latest-toggle-block {
                position: absolute;
                right: 0;
                bottom: 4px;
                ul {
                    li {
                        display: inline-block;
                        &:first-child {
                            border-right: 1px $grey solid;
                            margin-right: 3px;
                            padding-right: 5px;
                        }
                        a {
                            font-size: $small;
                            text-transform: uppercase;
                            color: $pink;
                        }
                        .active {
                            color: $dark-grey;
                            text-decoration: underline;
                            ;
                        }
                    }
                }
            }
            .participate-most-viewed ul {
                li {
                    &:first-child {
                        padding-right: 0px;
                        margin-right: 0px;
                        border-right: 0px;
                    }
                }
            }
            .contribute-desc {
                color: $dark-grey;
                font-family: 'Merriweather';
                font-size: 14px;
            }
            .contribute {
                float: left;
                border-right: 1px $dark-grey solid;
                padding-right: 30px;
                width: 50%;
                text-align: right;
            }
            .join {
                float: left;
                padding-left: 30px;
                width: 50%;
            }
            h2 {
                color: $pink;
                margin-bottom: 10px;
                font-family: 'Merriweather Sans';
                font-size: $extra-large;
                margin-bottom: 5px;
                font-weight: 500;
            }
        }
    }
}
