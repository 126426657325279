a.read-more,
a.read-less {
    cursor: pointer;
    color: #E61B72;
    font-family: 'Merriweather Sans';
    font-size: 12px;
    text-transform: uppercase;
    &:hover {
        color: #4D4D4D;
    }
}

.film-readmore {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    top:8px !important;
 }
.read-mores{
    padding-top: 0px !important;
}

.view-btn {
    color: #E61B72 !important;
    position: relative;
    // background: transparent url('/user/img/people/explore-icon.png') 65px 3px no-repeat;
    // background-size: 15px 16px;
    display: inline-block;
    width: 100px;
    font-family: 'Merriweather Sans';
    font-size: 12px;
    text-transform: uppercase;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    &:hover {
        color: #4D4D4D !important;
    }
}

.view-btn::before {
    padding-right: 5px;
    content: "|"
}

.view-btn::after {
    content: "";
    width: 10px;
    height: 10px;
    position: relative;
    background: transparent url(/user/img/common/icons/play-arrow.png) 0 0 no-repeat;
    display: inline-block;
    background-size: contain;
    margin-left: 6px;
    margin-top: -1.7px;
}


/*.view-btn::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 8px solid #E61B72;
    right: 17px;
    top: 4px;
    position: absolute;
}*/

.no-pipe {
    background-position: 55px 3px !important;
}

.no-pipe::before {
    content: none !important;
    padding-right: 0px !important;
}

span.more-text {
    display: none;
}

span.more-text.show {
    display: inline !important;
}

.ellipsis {
    color: #808080;
    cursor: default;
}
