.song-explore {
    a.song-related-reset{
        text-transform: uppercase;
        font-weight: 300;
        font-family: 'Merriweather Sans';
        color: #E61B72;
        cursor: pointer;
        font-size: 10px;
    }
    span.song-related-reset-span{
        margin-left:20px !important;
    }
    .grid-view {
        position: relative;
        display: block;
        & > .left-nav {
            top: -15px;
        }
    }

    & .exploreDescription {
           font-family: 'Merriweather';
            margin-right: auto;
            margin-bottom: 30px;
            line-height: 1.3em;
            color: $dark-grey;
            font-weight: 300;
            margin-top: 155px;
            text-align:left;
            padding-bottom: 5px;
            border-bottom: $light-grey solid 1px;

        & p {
            font-size: 14px;
        }

        & .word-italic {
            font-style:italic;
            color : $light-grey;
        }

        & a {
          color: $pink;
          cursor: pointer;
          &:hover {
            color: $dark-grey;
            cursor: pointer;
          }
           &:active {
            color: $dark-grey;
            cursor: pointer;
          }

        }

        @media (max-width:1024px) {
          margin-top: 0px;
        }

       }

      .page-header{
        @media(max-width: 1024px){
             position: relative;
             height: auto;
        }
        .page-title{
          span{
          @media(max-width: 1024px){
               line-height: 1.8em;
          }
          }
        }
      }

  .main-content {
      /*margin-top: 125px;*/
      @media(max-width: 1024px){
        margin-top:50px;
      }
   .left-nav {
      width: 70px;
      text-align: left;
      margin-top: 35px;
      transition: all 0.3s linear -0.1s;
        li {
           width: 85px;
           height: 20px;
           padding: 0 32px;
           padding-bottom: 2px;
           padding-left: 15px;
           background-image: none;
        }

       .seperater {
         background: transparent url('/user/img/songs/sep2.png');
         background-size: 300px 10px;
         background-position: left bottom;
         background-repeat: no-repeat;
         height: 8px;
       }


      .bg-none {
        background: none;
      }
    }
  }


}
