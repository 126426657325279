#contact_page {
    width: 100%;
    min-height: 900px;

    .font-same-style {
        font-family: 'Merriweather Sans';
        font-size: 14px;
    }

    .error-or-success-msg {
        color: $clickable-grey;
        margin-top: 10px;
        font-family: 'Merriweather Sans', sans-serif;
        font-weight: 300;
        line-height: 1.3em;
        width: 666px;
        position: absolute;
        margin-left: 20px;
        margin-top: 5px;
    }

    .ajab-wrapper {
        width: 90%;
        padding: 0;
        display: block;
        margin: 0 auto;

        .logo-container {
            position: fixed;
            top: 30px;
            left: 2.5%;
            @media screen and (max-width: 768px) {
                left: 0.5%;
            }

            & > a {
                font-size: 15px;
                color: $dark-grey;
            }
        }
    }

    &.contact_bg_1 {
        background: transparent url("/user/img/contact/contact_bg_1.jpg") 0 0 repeat fixed;
        background-size: contain;
        @media all and (max-width: 992px) {
            background-size: cover;
        }
    }

    &.contact_bg_2 {
        background: transparent url("/user/img/contact/contact_bg_2.jpg") 0 0 repeat fixed;
        background-size: contain;
        @media all and (max-width: 992px) {
            background-size: cover;
        }
    }

    &.contact_bg_3 {
        background: transparent url("/user/img/contact/contact_bg_3.jpg") 0 0 repeat fixed;
        background-size: contain;
        @media all and (max-width: 992px) {
            background-size: cover;
        }
    }

    &.contact_bg_4 {
        background: transparent url("/user/img/contact/contact_bg_4.jpg") 0 0 repeat fixed;
        background-size: contain;
        @media all and (max-width: 992px) {
            background-size: cover;
        }
    }

    .contactWrapper {
        background: transparent url(/user/img/contact/contact_bg.png)no-repeat;
        width: 844px;
        height: 695px;
        margin: 100px auto 0;
        padding: 60px 40px;
        @media all and (max-width: 992px) {
            width: 695px;
            height: 1100px;
            background: transparent url(/user/img/contact/contact_bg_992.png)no-repeat;
            background-position: center 0;
        }

        h3 {
            font-size: $extra-large;
            color: $clickable-grey;
            margin-bottom: 15px;
            text-align: left;
            @media all and (max-width: 992px) {
                text-align: center;
            }
        }

        .contactForm {
            width: 50%;
            float: left;
            text-align: left;
            @media all and (max-width: 992px) {
                text-align: center;
                margin: 0 auto;
                float: none;
            }

            input,
            textarea {
                outline: none;
                //  font-family: 'Merriweather';
                font-family: 'Merriweather Sans', sans serif;
                font-weight: 400;
            }

            .form-block {
                margin-bottom: 10px;

                input[type='text'],
                textarea {
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #CCC;
                }

                input[type='email'] {
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #CCC;
                }

                textarea {
                    resize: none;
                }
            }

            .send {
                background-color: $pink;
                color: #FFF;
                text-transform: uppercase;
                padding: 5px 10px;
                cursor: pointer;
                border: none;
                @include border-top-radius(5px);
                @include border-right-radius(5px);
                @include border-bottom-radius(5px);
                @include border-left-radius(5px);

                &:disabled {
                    background-color: $light-grey !important;
                    cursor: default !important;
                }
            }
        }

        .contactAddress {
            float: left;
            width: 50%;
            line-height: 1.5em;
            text-align: left;
            font-size: $medium;
            padding-left: 45px;
            @media all and (max-width: 992px) {
                width: 100%;
                text-align: center;
                padding-left: 0;
            }

            p {
                margin-bottom: 0;
                color: $dark-grey;
            }

            .mailLink {
                margin-top: 20px;
                display: inline-block;
            }

            a {
                color: $pink;
            }

            .phone-numbers {
                margin-top: 20px;
            }
        }
    }
}
