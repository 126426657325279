
.thumbnail.songs,.thumbnail.song {

  .summary {

    .categoryName {
      position: relative;
      top: 2px;
    }


  }

}
