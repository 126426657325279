#reset_password_page {
  width: 100%;
  min-height: 500px;
  background-color: #ccc;
}
.errorMsg{
  color: #E61B72;
  font-size: 12px;
}
.clearfix {
  clear: both;
}
.signup-error-messages{
  color: #E61B72;
  font-size: 12px;
}

.reset_container {
  padding-top: 100px;
  padding-bottom: 200px;

  .reset_box {
    width: 500px;
    min-height: 200px;
    padding: 30px;
    background-color: #fff;
    @include border-top-radius(20px);
    @include border-right-radius(20px);
    @include border-bottom-radius(20px);
    @include border-left-radius(20px);
    box-shadow: #8E8E8E 2px 0px 10px;
    margin-left: auto;
    margin-right: auto;
    .close {
      float: right;
      color: #4D4D4D;
      font-size: 12px;
    }
    h1 {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: $extra-large;
      color: $dark-grey;
    }
    .reset_content {
      color: $grey;
      font-family: 'Merriweather Sans';
      font-size: $medium;
      .keepMe {
        a {
          color: $pink;
          float: right;
        }
      }
      p {
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 1.5em;
      }
      .join {
        background-color: $pink;
        color: #FFF;
        padding: 10px;
        @include border-top-radius(10px);
        @include border-right-radius(10px);
        @include border-bottom-radius(10px);
        @include border-left-radius(10px);
        border: none;
        cursor: pointer;
        outline: none;
        &:disabled {
            background-color: $light-grey !important;
            cursor: default !important;
        }
      }

      .tandc {
        a {
          text-decoration: underline;
        }
      }
      .signin {
        a {
          color: $pink;
        }
      }
      .dottedLine {
        height: 3px;
        border-bottom: 1px dotted $grey;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .form-control {
      margin-top: 5px;
      margin-bottom: 5px;
      input {
        padding: 10px;
        outline: none;
        width: 100%;
      }
      .col {
        float: left;
        width: 50%;
        .firstName  {
          width: 99%;
        }
      }
    }
  }
  .error-or-success-msg{
    color: #E61B72;
    margin-top: 10px;
  }
}
