[contentEditable=true]:empty:not(:focus):before {
    content: attr(data-text)
}

.covermgs {
    margin-top: 10px;
    float: left;
    width: 100%;
}

#contribute_page {

    min-height: 500px;
    background: transparent url(/user/img/participate/contribute.jpg) 0 0 repeat fixed;
    background-size: 100%;
    background-color: #EEE;
    .clearfix {
        clear: both;
    }
    #contribute-placeholder .ng-scope.ng-isolate-scope.ta-toolbar.btn-toolbar {
        display: none;
    }
    .contribute-error-message {
        display: inline-block;
        color: $pink;
        margin-top: 10px;
    }
    .category-logo {
        padding-top: 33px !important;
        color: #4d4d4d !important;
        cursor: default !important;
        font-size: 18px !important;
        a {
            cursor: default !important;
            padding: 4px;
            border-radius: 30px;
            background-color: #FFF;
            margin-left: -8px;
        }
    }
    .contribute-form {
        width: 640px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 100px;
        padding-bottom: 200px;
        input[type="checkbox"].terms-cond {
            float: left;
            width: auto;
            margin-right: 5px;
            margin-left: 0px;
        }
        .terms-cond-text {
            color: #4D4D4D;
        }
        .contribute-text {
            margin-top: 30px;
            margin-bottom: 15px;
            color: $grey;
            font-size: $small;
            line-height: 1.7em;
            a {
                color: $pink;
                &:hover {
                    color: $grey;
                }
            }
        }
        .submit-text {
            margin-top: 10px;
        }
        h1 {
            color: $grey;
            font-size: $extra-large;
            font-size: 14px;
            margin-top: 25px;
            margin-bottom: 5px;
            font-family: 'Merriweather Sans';
            line-height: 20px;
        }
        .thumbnail-col {
            width: 50%;
            float: left;
        }
        .thumbnail-col-4 {
            width: 40%;
            float: left;
        }
        .thumbnail-col-2 {
            width: 20%;
            float: left;
        }
        .add-cat-or {
            text-align: center;
            margin-top: 10px;
        }
        .submit-btn {
            margin-top: 10px;
            input[type='submit'] {
                float: left;
                width: 17%;
                margin-right: 5%;
                border: 1px $grey solid;
                background: #FFF;
                color: $pink;
                cursor: pointer;
                text-transform: uppercase;
            }
            p {
                float: left;
                width: 70%;
                line-height: 35px;
            }
        }
        .form-group {
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 12px;
            color: $dark-grey;
            .share-url {
                color: $grey;
                margin-top: 5px;
                margin-bottom: 10px;
            }
            input,
            select,
            textarea {
                width: 100%;
                padding: 12px;
                outline: none;
                font-size: 14px;
            }
            .choose-file {
                padding-left: 0px;
                padding-top: 5px;
            }
            .choose-file-wrapper {
                width: 100%;
            }
        }
        .submit-btn {
            margin-bottom: 5px;
        }
    }
}