.header-with-carousel {
  header-box {
    position: absolute;
  }

  .page-header {

    background: transparent url(/user/img/songs/pardah_long.png) -800px bottom no-repeat;
    height: 138px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    left: 0;
    @media all and (max-width: 1024px) {
      position: relative;
      height: auto;
      min-height: 138px;
    }

    .page-title {
      float: left;
      margin: 35px 0 0 1.2%;
      font-family: 'Merriweather';
      font-weight: 300;
      .main {
        font-size: 21px;
        margin-right: 5px;
        color: $dark-grey;
      }
      .sub-main {
        font-size: $medium-large;
        color: #999999;
        font-style: italic;

      }
    }
  }

  .lower-pardah {
    background: transparent url(/user/img/songs/pardah_long.png) -800px bottom no-repeat;
    min-height: 185px;
    width: 100%;
    position: relative;

    .versions {

      .jcarousel-control-prev, .jcarousel-control-next {
        background: transparent url(/user/img/songs/arrow-left.png) 0 0 no-repeat;
        width: 28px;
        height: 39px;
        display: inline-block;
        text-shadow: none;
        border-radius: 0;
        box-shadow: none;
        top: 48%;
        z-index: 10 !important;
      }
      .jcarousel-control-prev {
        left: 10px;
        width: 37px;
        height: 58px;
      }

      .jcarousel-control-next {
        background-image: url(/user/img/songs/arrow-right.png);
        right: 10px;
        width: 37px;
        height: 58px;
      }
      .carousel-wrapper {
        border: none;
        box-shadow: none;
        margin: 0;
        border-radius: 0;
        width: 100%;
        padding: 137px 0 25px 0;
        height: 450px;
        @media all and (max-width: 1024px) {
          padding-top: 15px;
        }

        .carousel-box {
          /*width: 90%;*/
          text-align: center;
          margin: 0 auto;
          padding: 12px 0 0;


          @media screen and (max-width: 1380px) {
            width: 94%;
          }

          @media screen and (max-width: 1280px) {
            width: 77%;
          }

          @media screen and (max-width: 1024px) {
            width: 96%;
          }

          @media screen and (max-width: 768px) {
            width: 86%;
          }

        }

        .jcarousel {
            position: relative;
            width: 1200px;

            overflow: hidden;
            @media screen and (max-width: 1200px) {
                width: 900px;
            }
            @media screen and (max-width: 950px) {
                width: 600px;
            }



          & > ul {
            height: 450px;

            li {

              border: none;
              width: 300px;

            }



          }
          .jcarousel-control-next,.jcarousel-control-prev{
            z-index: 20;
          }


        }


        /*UI: WIP */
        .thumbnailWrapper {
          margin: 0;
          .thumbnail-media {
            height: 120px;
            width: 215px;
          }

          .desc-wrapper {
            .thumbnail-desc {
              margin: 0 0 0 17px;
              width: 215px;
              .item-title {
                font-size: 16px;
              }
              &:before {
                background: rgba(255, 255, 255, 0) url(/user/img/common/directives/thumbnail/top-small.png) -1px top no-repeat;
                width: 236px;
              }
            }
            .lower-curve {
              background: rgba(255, 255, 255, 0) url(/user/img/common/directives/thumbnail/bottom-small.png) left -5px no-repeat;
              margin-left: 15px;
            }
          }

          &.active {
            .desc-wrapper {
              .thumbnail-desc {
                background-color: #f2f2f2;
                .item-title {
                  color: #333;
                }
                &:before {
                  background: transparent url(/user/img/common/directives/thumbnail/top-small-grey.png) -1px top no-repeat;
                }
              }
              .lower-curve {
                background: transparent url(/user/img/common/directives/thumbnail/bottom-small-grey.png) left -5px no-repeat;
              }
            }
          }
        }
      }
    }

    .see-more {
      width: 100%;
      display: inline-block;
      position: absolute;
      bottom: 67px;
      .version {
        position: absolute;
        top: 16px;
        left: 48%;
        font-family: 'Merriweather';
        font-size: $small;
        color: $pink;
        font-weight: 300;

      }
      .arrow {
        background: transparent url(/user/img/songs/down-arrow1.png) 4px 4px no-repeat;
        height: 13px;
        width: 19px;
        display: inline-block;
        &.up-arrow {
          background-image: url(/user/img/songs/up-arrow1.png);
        }
      }

    }
  }
}

/* Common Header Design with carousel end here*/
