.text-typography {
  font-family: 'Merriweather';
  color: $dark-grey !important;
  word-break: break-all;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 200;
    color: $dark-grey !important;
  }
  h1 {
    font-size: $double-x-large !important;
    padding: 0 !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  h2 {
    font-size: $extra-large !important;
    padding: 0 !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  h3 {
    font-size: $large !important;
    padding: 0 !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  h4 {
    font-size: $medium-large !important;
    padding: 0 !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  h5 {
    font-size: $small !important;
    padding: 0 !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  h6 {
    font-size: $extra-small !important;
    padding: 0 !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  p, div{
    font-size: $medium !important;
    padding: 0 !important;
    margin-bottom: 5px;
    margin-top: 5px;
    line-height: 1.5em;
    word-break: break-word;
    word-break: keep-all;
  }
div{
  margin: 0px !important;
}


  i {
    font-style: italic;
  }

  .hindi {
    font-family: 'Lohit Devanagari', serif !important;
    font-size: 16px;
  }

  .pink {
    color: $pink;

  }

  .light-grey {
    color: $light-grey;
  }

  .grey {
    color: $grey;
  }

  .dark-grey {
    color: $dark-grey;
  }

  blockquote {
    margin-left: 15px;
  }

  ul {
        padding-left: 20px;
        margin-bottom: 10px;
        list-style: disc;
  }

  ol {
    padding-left: 20px;
    margin-bottom: 10px;
  }

  img {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  a {
    color : $pink;
     &:hover {
       text-decoration: underline;
       color:$dark-grey;
     }
  }

}
