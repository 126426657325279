#expressions {
    width: 100%;
    opacity: 0;
    background: transparent url(/user/img/stories/story-new-bg.png) 0 0 repeat fixed;
    min-height: 100vh;
    @media all and (max-width: 1024px) {
        background: transparent url(/user/img/stories/story-ipad-land-bg.png) 0 0 repeat fixed;
        float: left;
        width: 100%;
        margin-bottom: -200px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    @media all and (max-width: 800px) {
        background: transparent url(/user/img/stories/story-ipad-bg.png) 0 0 repeat fixed;
        background-position: center;
    }
    .bubble{
        position: relative !important;
        top:auto !important;
        .summary:after{
            position:relative !important;
            left: -20px !important;
            bottom: 0px !important;
            float: left;
        }
    }
    .heading-block {
        float: left;
        width: 100%;
        @media all and (max-width: 992px) {
            padding-right: 5%;
        }
    }
    .stroy-ripple1 {
        background: transparent url(/user/img/stories/story-ripple1.png);
        width: 500px;
        height: 469px;
        background-size: cover;
        position: fixed;
        right: -135px;
        top: -96px;
        opacity: 0.15;
        @media all and (max-width: 1024px) {
            display: none;
        }
    }
    .stroy-ripple2 {
        background: transparent url(/user/img/stories/story-ripple2.png);
        width: 61vw;
        height: 130vh;
        background-size:contain;
        background-repeat: no-repeat;
        background-position: center;
        position: fixed;
        left: -20vw;
        top: -5vh;
        opacity: 0.2;
        @media all and (max-width: 1024px) {
            display: none;
        }
    }
    .stroy-ripple3 {
        background: transparent url(/user/img/stories/story-ripple3.png);
        width: 80vw;
        /* height: 155vh;*/
        height: 170vh;
        background-repeat: no-repeat;
        background-size: contain;
        position: fixed;
        left: 50%;
        margin-left: -25vw;
        bottom: -79vh;
        opacity: 0.2;
        @media all and (max-width: 1024px) {
            display: none;
        }
    }
    .anti-clock-animation {
        animation-name: ReverseCircle;
        animation-duration: 120s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        -webkit-animation-name: ReverseCircle;
        -webkit-animation-duration: 120s;
        -webkit-animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-name: ReverseCircle;
        -moz-animation-duration: 120s;
        -moz-animation-timing-function: linear;
        -moz-animation-iteration-count: infinite;
        -o-animation-name: ReverseCircle;
        -o-animation-duration: 120s;
        -o-animation-timing-function: linear;
        -o-animation-iteration-count: infinite;
    }
    .clock-animation {
        -webkit-animation-name: inner-circle;
        animation-name: inner-circle;
        animation-duration: 30s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        -webkit-animation-name: reverse-inner-circle;
        -webkit-animation-duration: 30s;
        -webkit-animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-name: reverse-inner-circle;
        -moz-animation-duration: 30s;
        -moz-animation-timing-function: linear;
        -moz-animation-iteration-count: infinite;
        -o-animation-name: reverse-inner-circle;
        -o-animation-duration: 30s;
        -o-animation-timing-function: linear;
        -o-animation-iteration-count: infinite;
    }
    .clock-animation-ripple2 {
        -webkit-animation-name: inner-circle;
        animation-name: inner-circle;
        animation-duration: 80s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        -webkit-animation-name: reverse-inner-circle;
        -webkit-animation-duration: 80s;
        -webkit-animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-name: reverse-inner-circle;
        -moz-animation-duration: 80s;
        -moz-animation-timing-function: linear;
        -moz-animation-iteration-count: infinite;
        -o-animation-name: reverse-inner-circle;
        -o-animation-duration: 80s;
        -o-animation-timing-function: linear;
        -o-animation-iteration-count: infinite;
    }
    /* Reverse Inner Circle Animation*/
    @-webkit-keyframes reverse-inner-circle {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes reverse-inner-circle {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    /* Reverse Circle Animation*/
    @-webkit-keyframes ReverseCircle {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
        }
    }
    @keyframes ReverseCircle {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
        }
    }
    .clearfix {
        clear: both;
    }
    .logo-with-filter {
        z-index: 80;
        position: absolute;
    }
    .storiesCount {
        text-align: center;
        font-size: 10px;
        color: $grey;
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: uppercase;
        margin-left: 10px;
    }
    .stories-thumbnail-block {
        min-height: 400px;
        width: 100%;
        float: left;
    }
    #container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        // @media all and (max-width: 992px) {
        //     display: block;
        //     height: auto !important;
        // }
        /* height: 900px !important;*/
        .media {
            min-height: 280px;
            width: 33%;
            margin-bottom: 15px;
            float: left;
            @media all and (max-width: 992px) {
                // position: relative !important;
                // left: auto !important;
                // top: auto !important;
                // float: left;
                width: 50%;
                // -webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;
            }
        }
    }
    /* .media:nth-child(4) ,  .media:nth-child(5) , .media:nth-child(6) {*/
    /*     top: 300px !important;*/
    /* }*/
    /**/
    /* .media:nth-child(7) ,  .media:nth-child(8) , .media:nth-child(9) {*/
    /*     top: 600px !important;*/
    /* }*/
    /**/
    /* .media:nth-child(10) ,  .media:nth-child(11) , .media:nth-child(12) {*/
    /*     top: 900px !important;*/
    /* }*/
    /**/
    /* .media:nth-child(13) ,  .media:nth-child(14) , .media:nth-child(15) {*/
    /*     top: 1200px !important;*/
    /* }*/
    .emptySpace {
        height: 150px;
    }
    .category-logo {
        padding-top: 34px !important;
        color: #4d4d4d !important;
        cursor: default !important;
        font-size: 18px !important;
        margin-left: 7px;
        font-family: 'Merriweather';
        a {
            cursor: pointer !important;
            padding: 4px;
            border-radius: 30px;
            margin-left: 8px;
            color: $dark-grey;
            &:hover {
                color: $dark-grey;
            }
        }
    }
    .emptySpace {
        height: 120px;
    }
    .all-stories {
        padding-top: 80px;
        padding-bottom: 200px;
        width: 840px;
        margin-left: auto;
        margin-right: auto;
        @media all and (max-width: 1200px) {
            width: calc(100% - 150px);
            float: left;
            margin-left: 150px;
        }
        h1 {
            color: $dark-grey;
            font-size: $large;
            margin-bottom: 5px;
            line-height: 20px;
        }
        h2 {
            color: $dark-grey;
            font-size: $medium;
            margin-bottom: 5px;
        }
        .line-block {
            height: 35px;
            .line {
                margin-right: 10px;
                float: left;
                border-top: 1px $grey solid;
                margin-top: 5px;
                width: 798px;
                @media all and (max-width: 992px) {
                    width: 100%;
                }
            }
            .see-all {
                float: left;
                color: $pink;
                font-size: $small;
            }
        }
    }
}
