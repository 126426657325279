#contribute-thumbnail {
  min-height: 300px;
.thumbnail .bubble .summary:after{
  @media all and (max-width: 1250px) and (min-width: 1025px){
      top:-5px;
  }

}
  .contribute-echo-thubnail{
    width: 100%;
  }

  .contribute-link {
    margin-top: 5px;
    margin-bottom: 5px;
    @media all and (max-width: 1024px) {
      margin-bottom: 0px;
    }

    a {
      color: $pink;
      font-size: $small;
    }
    span{
      color: $pink;
      font-size: $small;
    }
  }

}
