#resources_page {
    background-size: 100%;

    .clearfix {
        clear: both;
    }

    #resources_people_page .person-labelOrAuthor {
        font-size: 12px;
    }

    #resources_film_page .film-second-titles {
        font-size: 14px;
        color: #333333;
    }

    .no-margin-left {
        width: 100% !important;
        margin: 0 !important;
    }

    .resources-search {
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 1px #ccc solid;
        outline: none;
        margin-bottom: 20px;
        font-family: 'FontAwesome' , 'Merriweather Sans';
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .category-logo {
        padding-top: 33px !important;
        color: #4d4d4d !important;
        cursor: default !important;
        font-size: 18px !important;

        a {
            cursor: default !important;
            padding: 4px;
            border-radius: 30px;
            background-color: #FFF;
            margin-left: -3px;
            opacity: 0.85;
        }
    }

    .resources_bg_1 {
        background: transparent url("/user/img/about/about_bg_1.jpg") 0 0 repeat fixed;
        background-size: 100%;
    }

    .resources_bg_2 {
        background: transparent url("/user/img/about/about_bg_2.jpg") 0 0 repeat fixed;
        background-size: 100%;
    }

    .resources_bg_3 {
        background: transparent url("/user/img/about/about_bg_3.jpg") 0 0 repeat fixed;
        background-size: 100%;
    }

    .resources-wrapper {
        min-height: 1000px;
        margin: 0 auto;
        display: block;
        position: relative;
        background-size: cover;
        font-family: $font-stack;
        @media screen and (max-width: 1280px) {
            width: 100%;
        }

        .leftMenu {
            height: 480px;
            width: 58%;
            overflow-y: auto;
            line-height: 1.5em;
            padding: 8%;
            @media all and (max-width: 1024px) {
                width: 150px;
            }
            // @media screen and (max-width: 980px) {
            //   width: 45%;
            // }
            // @media screen and (max-width: 880px) {
            //   width: 40%;
            // }
            // @media screen and (max-width: 800px) {
            //   width: 35%;
            // }
        }

        .page-header {
            float: left;
            margin-bottom: 20px;
            margin-left: 15px;
            min-height: 120px;
        }

        .active {
            text-decoration: underline;
            color: $dark-grey;
        }

        .sidebar-container {
            position: fixed;
            width: 20%;
        }

        .spacing {
            height: 100px;
        }

        .ajab-wrapper {
            .logo-container {
                margin-bottom: 20px;

                a {
                    cursor: default!important;
                    text-align: center !important;
                    color: #4D4D4D !important;
                }
            }
        }

        .resources-container {
            padding: 80px 50px 50px;
            @media screen and (max-width: 1280px) {
                padding: 80px 20px 50px;
            }
        }

        .resources-content-parda {
            width: 65%;
            margin: 0 auto;
            position: relative;
            @media screen and (max-width: 1180px) {
                width: calc(100% - 150px);
                margin: 0;
                float: right;
            }
        }

        .resources-parda-width {
            background: transparent url('/user/img/people/all-people-content-bg.png') left top;
            background-size: 100% auto;
            min-height: 1200px;
            width: 100%;
        }
        /*  &:before, &:after {
     content: '';
     display: inline-block;
     background: transparent url(/user/img/mainLandingPage/parallax/side_pattern.png) 0 0;
     width: 45px;
     background-repeat: no-repeat repeat;
     position: absolute;
     top: 0;
     height: 100%;

     @media screen and (max-width: 1280px) {
       display: none;
     }
   }
   &:before {
     left: 0px;
   }
   &:after {
     right: 0;
     -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
     -o-transform: rotate(180deg);
     transform: rotate(180deg);
   }*/
        .resources-content-block {
            font-family: "Merriweather";
            color: $dark-grey;
            font-size: $medium;

            & .resources-nav {
                width: 20%;
                float: left;
                text-align: left;

                & h4 {
                    margin-top: 5px;
                    margin-bottom: 5px;
                    margin-left: 15px;
                    color: $pink;
                    font-size: $medium;
                    font-weight: 400;

                    &.active {
                        color: $dark-grey;
                    }
                }

                & ul {
                    padding-left: 15px;

                    & li {
                        font-size: 14px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        list-style: none;

                        &:before {}

                        a {
                            word-break: break-word;
                        }
                    }
                }
            }

            .separator {
                height: 6px;
                background: transparent url('/user/img/songs/sep2.png');
                background-size: 300px 10px;
                background-position: left bottom;
                background-repeat: no-repeat;
                width: 150px;
                padding: 0 32px 5px 30px;
                margin-left: -5px;
            }

            & .resources-content {
                margin-right: 20px;
                width: 62%;
                float: left;
                text-align: left;
                padding-bottom: 150px;
                @media screen and (max-width: 990px) {
                    width: 52%;
                    margin-right: 1%;
                }

                .resources-header-name {
                    font-size: $extra-large;
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 990px) {
                    margin-right: 0;
                }

                & h3 {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    color: $color-heading;
                    font-size: $large;
                }

                & p {
                    font-size: 14px;
                    line-height: 1.8em;
                    /* &:first-of-type:first-letter {
                      font-size: 24px;
                      font-weight: 200;
                  }*/
                }
            }

            & .resources-image {
                width: 35%;
                float: right;
                font-family: "Merriweather";

                &:after {
                    clear: both;
                }

                .image-block {
                    margin-bottom: 0;
                    width: 255px;
                    float: right;

                    img {
                        width: 100%;
                        border: 3px #FFF solid;
                        box-shadow: $light-grey 2px 2px 10px;
                        height: 140px;
                        margin-bottom: 5px;
                    }

                    .image-caption {
                        line-height: 1.5em;
                        margin-bottom: 10px;
                        text-align: left;
                        color: $grey;
                        font-size: 12px;
                        word-break: break-word;
                    }
                }
            }

            & .clearfix {
                clear: both;
            }
        }
    }
}
