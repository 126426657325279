
.disabled {
  display: none;
}

.media-introduction {
  display: inline-block;
  text-align: left;
  width: 100%;
  line-height: 23px;

  position: absolute;
  left: 0;

  &.word {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }

  youtube, sound-cloud {
    display: inline-block;
    width: 853px;
    height: 480px;

    @media screen and (max-width: 1280px) {
      width: 640px;
      height: 360px;
    }

    @media screen and (max-width: 769px) {
      width: 530px;
      height: 315px;
    }

    iframe, .mediaBox {
      border: solid 5px #fff;
      box-shadow: #777 0 0 10px;
      margin: 5px 0;
    }

    iframe {
      height: inherit;
    }
  }

  .moreDesc {
    color: $grey;
    font-size: $large;
    font-style: italic;
    font-weight: 300;
    line-height: 27px;
    font-family: 'Merriweather';
  }

  .artistName {
    text-transform: uppercase;
    font-weight: 300;
    font-size: $small;
    font-family: 'Merriweather Sans';
    line-height: 26px;
    color: $grey;
    font-weight: 400;

    small {
        text-transform: lowercase;
        font-weight: 300;
    }

    .artist-text {
      color: #999999;
      font-size: 12px;
      margin-left: 1px;
      text-transform: lowercase;
      font-weight: 300;

    }
    .author-caps {
        text-transform: uppercase;
        font-weight: 300;

    }
    .title-case {
        text-transform: initial;
        font-weight: 300;
    }
  }



  &.song {
    position: static;
    margin: 0 auto;
    width: auto;
  }

  @media screen and (max-width: 990px) {
    margin-top: 10%;
  }

  ul.audio-video-options {
    float: right;
    display: inline-flex;
    display: webkit-inline-flex;
    margin-top: -12px;
    & > li {
      border-right: solid 1px #666;
      line-height: 2px;
      margin: 7px 0 0;
      padding: 0 7px;
      height: 15px;

      &:last-child {
        border: none;
        padding-right: 0;
      }
      & > a {
        background-image: url(/user/img/common/icons/sprite_icons.png);
        background-repeat: no-repeat;
        display: block;
        &.active {
            cursor: default;
        }

        &.video-icon {
            width: 10px;
            height: 12px;
            background-position: -233px -200px;
            &:hover, &.active {
                  width: 10px;
                  height: 12px;
                  background-position: -249px -61px;
            }


        }


        &.audio-icon {
            width: 14px;
            height: 22px;
            background-position: -145px -16px;

            &:hover, &.active {
                width: 14px;
                height: 17px;
                background-position: -15px -16px;

            }


        }
      }
    }
  }
}

.youtubeVideo {
  height: 100%;
  margin-top: 5px;
}

.mediaContent {
  display: inline-block;
  width: 100%;
  height: 90%;
}

.mediaPagination {
  float: right;
  color: #fff;
  text-transform: capitalize;
}

.artistName {

}

.context {
  font-size: $large;
  font-weight: 300;
  color: $pink;

  &:hover {
    color: $color-hover-link;
    cursor: pointer;
  }
}

/*.context small {
    font-weight: normal;
    font-style: italic;
    text-transform: capitalize;
}*/
.link {
  color: #fff;
}

.creditCont {
  margin-top: 20px;
  color: #999;
  width: 95%;
  padding-left: 300px;
  padding-right: 225px;
  text-align: center;
  font-family: 'Merriweather';
  .creditBox {
    display: inline-block;
    padding: 0 10px;
    font-family: 'Merriweather Sans';
    font-size: $extra-small;
    font-weight: 300;
    line-height: 11px;
    text-transform: uppercase;
    color: $color-disabled-link;

    .title-dark {
      font-size: 14px;
      color: $color-active-link;
      text-transform: none;
      font-family: "Merriweather";
    }

    .link-text {
        color: $grey;
        cursor: pointer;
        font-family: 'Merriweather';
        font-size: $medium;
        text-transform: capitalize;
        &:hover {
            color: $pink;
        }
    }
    .italic-text {
        font-style: italic;
    }
  }
}

.mediaFooter {
  text-align: center;
  position: fixed;
  bottom: 2.8%;
  width: 100%;
  left: 0;
  z-index: $zIndex-lightbox-footer;

  .infoText {
    color: $grey;
    font-size: $medium;
    font-family: 'Merriweather Sans';
    font-weight: 300;
    a {
      font-size: $double-x-large;
      color: $pink;
      font-family: 'Merriweather';
      text-transform: capitalize;
      &.sub {
        font-size: $extra-large;
      }
      &:hover {
        color: $dark-grey;
      }
    }
  }
}



#content-wrapper {
  background: transparent url(/user/img/words/content_bg.png) -4px 0 no-repeat;
  padding: 2% 3% 5% 3%;
  background-size: 100% 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  .content-bg {
    overflow: auto;
    height: 450px;
    padding-right: 2em;

    @media screen and (max-width: 768px) {
      height: 665px;
    }
    .header {
        text-transform: capitalize;
        color: $light-grey;
        font-weight: 300;
    }
    .title-pink {
        display: inline;
        color: $pink;
        font-size: $large;
    }
    .title-italic {
        font-style: italic;
        font-size: $medium-large;
    }

  }

}
