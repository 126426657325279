
.all-reflections .filter-button .collapse{
    background: transparent url('/user/img/common/icons/minus.png') 0 0 no-repeat;
    display: inline-block;
    height: 9px;
    width: 9px;
}
.all-reflections .filter-button .expand{
    background: transparent url('/user/img/common/icons/cross.png') 0 0 no-repeat;
    display: inline-block;
    height: 9px;
    width: 9px;
}
.all-songs .filter-button .collapse{
    background: transparent url('/user/img/common/icons/minus.png') 0 0 no-repeat;
    display: inline-block;
    height: 9px;
    width: 9px;
}
.all-songs .filter-button .expand{
    background: transparent url('/user/img/common/icons/cross.png') 0 0 no-repeat;
    display: inline-block;
    height: 9px;
    width: 9px;
}
.couplet-page .filter-button .collapse{
    background: transparent url('/user/img/common/icons/minus.png') 0 0 no-repeat;
    display: inline-block;
    height: 9px;
    width: 9px;
}
.couplet-page .filter-button .expand{
    background: transparent url('/user/img/common/icons/cross.png') 0 0 no-repeat;
    display: inline-block;
    height: 9px;
    width: 9px;
}
.filter-logo.nav-elements-wrapper {
  position: relative;
  z-index: 11111;
  left: 55px;
  top: 9px;
  width: 100px;
  .category-logo {
    background: transparent url('/user/img/songs/songs_logo.png') 0 0 no-repeat;
    width: 85px;
    height: 85px;
    padding-top: 30px;
    color: $pink;
    font-size: 20px;
    font-weight: 300;
  }

  .filter-button {
    display: block;
    margin-top: 7px;
    font-size: $small;
    text-transform: uppercase;
    color: $color-link;
    margin-left: 10px;
  }
}
.slide-nav-filter-menu{
  position: fixed;
  width: 240px;
  height: 100vh;
  z-index: 200;
  .slide-nav-filter-sub-menu{
    width: 250px;
    background: transparent url('/user/img/songs/parda180px.png') right -5px repeat-y;
    background-size: cover;
    height: 100vh;
    position: fixed;
    top: 0px;
    z-index: 1000;
    .filters {
      margin-top: 156px;
      margin-right: 80px;
      margin-left: 30px;
      text-align: left;
      & > li {
        font-family: 'Merriweather';
        padding: 5px 0;
        font-weight: 300;
        font-size: $medium-large;
        text-transform: lowercase;

        &.head {
          line-height: 1.5em;
          font-size: $extra-small;
          text-transform: uppercase;
          color: $color-regular-text-light;
          font-family: "merriweather sans";
        }
        &.ng-hide {
          display: none;
        }
        &.reset-image {
          background: transparent url('/user/img/songs/underline_curved.png') no-repeat;
          margin-top: 10px;
          width: 14em;
          background-size: contain;
          background-position: -2em 0;

        }

        a {
          text-transform: lowercase;
          color: $color-link;

          &.active {
            color: $color-active-link;
            &:hover {
                cursor: default;
            }
          }

          &.disable {
            cursor: default;
            color: $color-disabled-link;
            &:hover {
              color: none;
            }
          }
          &.reset {
            text-transform: uppercase;
            font-weight: 300;
            font-family: 'Merriweather Sans';
            color: $color-link;
            cursor: pointer;
            font-size: $extra-small;
            padding-top: 2em;
            display: block;
          }
        }

        .child-list {
          margin: 10px 0;
          line-height: 1em;
          & span {
            color: $color-sub-link;
            font-size: 12px;
            font-family: 'Merriweather Sans';
            text-transform: capitalize;
            margin: 0;
            font-weight: 300;
            & a {
              cursor: pointer;
              color: $color-link;
              line-height: 15px;
              margin-left: 10px;
              font-weight: 700;

            }
          }
        }
      }
    }
    .note-text {
      font-size: 12px;
      padding-left: 10px;
      padding-right: 30px;
      line-height: 1.5em;
      color: $grey;
      margin-left: 20px;
      position: absolute;
      bottom: 20px;
    }
  }
}
 .slide-nav-list {
    padding: 0;
    margin-top: -2px;
    margin-left: -5px;
    position: absolute;
    left: -250px;
    top: 0;
    min-height: 600px;
    z-index: 180;
    width: 250px;
    &.ng-hide-add {

     @include animation(pardah1-slideLeft 0.5s forwards);
    }

    &.ng-hide-remove {
      @include animation(pardah1-slideRight 0.5s forwards);
    }

    .first-layer-list {
      width: 250px;
      background: transparent url('/user/img/songs/parda180px.png') right -5px repeat-y;
      background-size: cover;
      height: 100%;
      position: fixed;
    }

    .filters {
      margin-top: 200px;
      margin-right: 80px;
      margin-left: 30px;
      text-align: left;
      & > li {
        font-family: 'Merriweather';
        padding: 5px 0;
        font-weight: 300;
        font-size: $medium-large;
        text-transform: lowercase;

        &.head {
          line-height: 1.5em;
          font-size: $extra-small;
          text-transform: uppercase;
          color: $color-regular-text-light;
          font-family: "merriweather sans";
        }
        &.ng-hide {
          display: none;
        }
        &.reset-image {
          background: transparent url('/user/img/songs/underline_curved.png') no-repeat;
          margin-top: 10px;
          width: 14em;
          background-size: contain;
          background-position: -2em 0;

        }

        a {
          text-transform: lowercase;
          color: $color-link;

          &.active {
            color: $color-active-link;
            &:hover {
                cursor: default;
            }
          }

          &.disable {
            cursor: default;
            color: $color-disabled-link;
            &:hover {
              color: none;
            }
          }
          &.reset {
            text-transform: uppercase;
            font-weight: 300;
            font-family: 'Merriweather Sans';
            color: $color-link;
            cursor: pointer;
            font-size: $extra-small;
            padding-top: 2em;
            display: block;
          }
        }

        .child-list {
          margin: 10px 0;
          line-height: 1em;
          & span {
            color: $color-sub-link;
            font-size: 12px;
            font-family: 'Merriweather Sans';
            text-transform: capitalize;
            margin: 0;
            font-weight: 300;
            & a {
              cursor: pointer;
              color: $color-link;
              line-height: 15px;
              margin-left: 10px;
              font-weight: 700;

            }
          }
        }
      }

    }
  }
@include keyframes(pardah1-slideLeft) {
  0% {
    left: 0;
  }
  100% {
    left: -250px;
  }
}

@include keyframes(pardah1-slideRight) {
  0% {
    left: -250px;
  }
  100% {
    left: 0;
  }
}
