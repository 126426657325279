// Moving Petals

.animation-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 100px;
  overflow: hidden;
    @media screen and (max-width: 1280px) {
        top: 117px;
    }
}

.movingPetals {
  @include retina-image("/user/img/words/white_petals", 899px 1763px);
  width: 899px;
  height: 1763px;
  left: 60px;
  top: 50px;
  position: absolute;
  animation-name: movingpetals;
  animation-duration: 70s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: movingpetals;
  -webkit-animation-duration: 70s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: movingpetals;
  -moz-animation-duration: 70s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: movingpetals;
  -o-animation-duration: 70s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  animation-delay: -2s;
  -moz-animation-delay: -2s;
  -webkit-animation-delay: -2s;
  -o-animation-delay: -2s;
}
@-webkit-keyframes movingpetals {
  0% {
    -webkit-transform: translate(100%, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
  }
}
@-moz-keyframes movingpetals {
  0% {
    -moz-transform: translate(100%, 0);
  }
  100% {
    -moz-transform: translate(-100%, 0);
  }
}
@-o-keyframes movingpetals {
  0% {
    -o-transform: translate(100%, 0);
  }
  100% {
    -o-transform: translate(-100%, 0);
  }
}

// Leaves one Top to Bottom

.leaves-one-t2b {
  @include retina-image("/user/img/words/leaves-one", 872px 783px);
  width: 872px;
  height: 2610px;
  left: 150px;
  position: absolute;
  animation-name: LeavesOneToptoBottom;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: LeavesOneToptoBottom;
  -webkit-animation-duration: 60s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: LeavesOneToptoBottom;
  -moz-animation-duration: 60s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: LeavesOneToptoBottom;
  -o-animation-duration: 60s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  animation-delay: -2s;
  -moz-animation-delay: -2s;
  -webkit-animation-delay: -2s;
  -o-animation-delay: -2s;
  background-position: 0 0;
}
@-webkit-keyframes LeavesOneToptoBottom {
  0%   {background-position: 0 0;}
  100% {background-position: 0 783px;}
}
@-moz-keyframes LeavesOneToptoBottom {
  0%   {background-position: 0 0;}
  100% {background-position: 0 783px;}
}
@-o-keyframes LeavesOneToptoBottom {
  0%   {background-position: 0 0;}
  100% {background-position: 0 783px;}
}

// Leaves Two Top to Bottom

.leaves-two-t2b {
  @include retina-image("/user/img/words/leaves-two", 757px 847px);
  top: 1px;
  width: 757px;
  height: 2610px;
  left: 150px;
  position: absolute;
  animation-name: LeavesTwoToptoBottom;
  animation-duration: 150s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: LeavesTwoToptoBottom;
  -webkit-animation-duration: 150s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: LeavesTwoToptoBottom;
  -moz-animation-duration: 150s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: LeavesTwoToptoBottom;
  -o-animation-duration: 150s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  animation-delay: -2s;
  -moz-animation-delay: -2s;
  -webkit-animation-delay: -2s;
  -o-animation-delay: -2s;
}
@-webkit-keyframes LeavesTwoToptoBottom {
  0%   {background-position: 0 0;}
  100% {background-position: 0 847px;}
}
@-moz-keyframes LeavesTwoToptoBottom {
  0%   {background-position: 0 0;}
  100% {background-position: 0 847px;}
}
@-o-keyframes LeavesTwoToptoBottom {
  0%   {background-position: 0 0;}
  100% {background-position: 0 847px;}
}

// Leaves Large
.largeLeaves {
  @include retina-image("/user/img/words/large_leaves", 961px 821px);
  width: 961px;
  height: 1200px;
  position: absolute;
  left: 60px;
  animation-name: largeLeavesAnimation;
  animation-duration: 70s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: largeLeavesAnimation;
  -webkit-animation-duration: 70s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: largeLeavesAnimation;
  -moz-animation-duration: 70s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: largeLeavesAnimation;
  -o-animation-duration: 70s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  animation-delay: -2s;
  -moz-animation-delay: -2s;
  -webkit-animation-delay: -2s;
  -o-animation-delay: -2s;
}
@-webkit-keyframes largeLeavesAnimation {
  0% {
    -webkit-transform: translate(0, -100%);
  }
  100% {
    -webkit-transform: translate(0,100%);
  }
}
@-moz-keyframes largeLeavesAnimation {
  0% {
    -moz-transform: translate(0, -100%);
  }
  100% {
    -moz-transform: translate(0,100%);
  }
}
@-o-keyframes largeLeavesAnimation {
  0% {
    -o-transform: translate(0, -100%);
  }
  100% {
    -o-transform: translate(0, 100%);
  }
}

// Medium Leaves
.mediumLeaves {
  @include retina-image("/user/img/words/medium_leaves", 743px 1025px);
  width: 743px;
  height: 100%;
  left: 100px;
  position: absolute;
  animation-name: mediumLeavesAnimation;
  animation-duration: 70s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: mediumLeavesAnimation;
  -webkit-animation-duration: 170s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: mediumLeavesAnimation;
  -moz-animation-duration: 170s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: mediumLeavesAnimation;
  -o-animation-duration: 170s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  animation-delay: -2s;
  -moz-animation-delay: -2s;
  -webkit-animation-delay: -2s;
  -o-animation-delay: -2s;
}
@-webkit-keyframes mediumLeavesAnimation {
  0% {
    -webkit-transform: translate(0, -100%);
  }
  100% {
    -webkit-transform: translate(0,100%);
  }
}
@-moz-keyframes mediumLeavesAnimation {
  0% {
    -moz-transform: translate(0, -100%);
  }
  100% {
    -moz-transform: translate(0,100%);
  }
}
@-o-keyframes mediumLeavesAnimation {
  0% {
    -o-transform: translate(0, -100%);
  }
  100% {
    -o-transform: translate(0, 100%);
  }
}

// Small Leaves
.smallLeaves {
  overflow: hidden;
  top: 200px;
  width: 300px;
  height: 300px;
  position: absolute;
  left: 0;
  animation-name: circle;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: circle;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: circle;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: circle;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
}
.smallLeavesRotation {
  @include retina-image("/user/img/words/small_leaves", 30 22 no-repeat);
  width: 30px;
  height: 22px;
  animation-name: inner-circle;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: inner-circle;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: inner-circle;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: inner-circle;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
}

// Med Leaf
.medLeaf {
  // z-index: 1;
  // right: 90px;
  // overflow: hidden;
  // top: 165px;
  // width: 400px;
  // height: 400px;
  // position: absolute;
  // animation-name: ReverseCircle;
  // animation-duration: 30s;
  // animation-timing-function: linear;
  // animation-iteration-count: infinite;
  // -webkit-animation-name: ReverseCircle;
  // -webkit-animation-duration: 30s;
  // -webkit-animation-timing-function: linear;
  // -webkit-animation-iteration-count: infinite;
  // -moz-animation-name: ReverseCircle;
  // -moz-animation-duration: 30s;
  // -moz-animation-timing-function: linear;
  // -moz-animation-iteration-count: infinite;
  // -o-animation-name: ReverseCircle;
  // -o-animation-duration: 30s;
  // -o-animation-timing-function: linear;
  // -o-animation-iteration-count: infinite;
}
.words-wrapper:before{
  z-index: 1;
}
.medLeafRotation1{
  @include retina-image("/user/img/words/leaf-word-anim", 400 400 no-repeat);
  width: 400px;
  height: 400px;
  background-size: cover;
  animation-name: ReverseCircle;
  animation-duration: 30s;
  position: absolute;
  top: 671px;
  left: 0px;
  // animation-timing-function: linear;
  // animation-iteration-count: infinite;
  // -webkit-animation-name: ReverseCircle;
  // -webkit-animation-duration: 30s;
  // -webkit-animation-timing-function: linear;
  // -webkit-animation-iteration-count: infinite;
  // -moz-animation-name: ReverseCircle;
  // -moz-animation-duration: 30s;
  // -moz-animation-timing-function: linear;
  // -moz-animation-iteration-count: infinite;
  // -o-animation-name: ReverseCircle;
  // -o-animation-duration: 30s;
  // -o-animation-timing-function: linear;
  // -o-animation-iteration-count: infinite;


  animation-name: ReverseCircle;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: ReverseCircle;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: ReverseCircle;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: ReverseCircle;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;

}
.medLeafRotation {
  @include retina-image("/user/img/words/leaf-word-anim", 400 400 no-repeat);
  width: 400px;
  height: 400px;
  background-size: cover;
  animation-name: ReverseCircle;
  animation-duration: 30s;
  position: absolute;
  top: 165px;
  right: 0px;
  // animation-timing-function: linear;
  // animation-iteration-count: infinite;
  // -webkit-animation-name: ReverseCircle;
  // -webkit-animation-duration: 30s;
  // -webkit-animation-timing-function: linear;
  // -webkit-animation-iteration-count: infinite;
  // -moz-animation-name: ReverseCircle;
  // -moz-animation-duration: 30s;
  // -moz-animation-timing-function: linear;
  // -moz-animation-iteration-count: infinite;
  // -o-animation-name: ReverseCircle;
  // -o-animation-duration: 30s;
  // -o-animation-timing-function: linear;
  // -o-animation-iteration-count: infinite;


  animation-name: ReverseCircle;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: ReverseCircle;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: ReverseCircle;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: ReverseCircle;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
}

.medLeaf2 {
  left: 90px !important;
  right: 0 !important;
  top: 550px !important;

}

// Large Leaf
.largeLeaf {
  overflow: hidden;
  top: 700px;
  width: 400px;
  height: 400px;
  position: absolute;
  animation-name: circle;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: circle;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: circle;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: circle;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
}
.largeLeafRotation {
  @include retina-image("/user/img/words/large_leaf", 144 135 no-repeat);
  width: 144px;
  height: 135px;
  animation-name: inner-circle;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: inner-circle;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: inner-circle;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: inner-circle;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
}

// Two Leaves
.twoLeaves {
  right: 20px;
  overflow: hidden;
  top: 1100px;
  width: 400px;
  height: 400px;
  position: absolute;
  animation-name: ReverseCircle;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: ReverseCircle;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: ReverseCircle;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: ReverseCircle;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
}
.twoLeavesRotation {
  @include retina-image("/user/img/words/two-leaves", 253 291 no-repeat);
  width: 253px;
  height: 291px;
  animation-name: inner-circle;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: reverse-inner-circle;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: reverse-inner-circle;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: reverse-inner-circle;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
}

// Circle Animation
@keyframes circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Inner Circle Animation
@keyframes inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-moz-keyframes inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

// Reverse Circle Animation
@keyframes ReverseCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes ReverseCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-moz-keyframes ReverseCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes ReverseCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

// Reverse Inner Circle Animation
@keyframes reverse-inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes reverse-inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes reverse-inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes reverse-inner-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Words View
.wordsView {
  @include retina-image("/user/img/words/words_view", 922px 2610px repeat);
  width: 922px;
  background-position: center center;
  background-size: cover;
  height: 2610px;
  opacity: 0.5;
  position: absolute;
  left: 125px;
  animation-name: wordsAnimation;
  animation-duration: 180s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: wordsAnimation;
  -webkit-animation-duration: 180s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: wordsAnimation;
  -moz-animation-duration: 180s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: wordsAnimation;
  -o-animation-duration: 180s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  background-position: 0 0;
  @media all and (max-width:1024px) {
    left: 0px;
  }
}
@-webkit-keyframes wordsAnimation {
  0%   {background-position: 0 0;}
  100% {background-position: 0 -2610px;}
}
@-moz-keyframes wordsAnimation {
  0%   {background-position: 0 0;}
  100% {background-position: 0 -2610px;}
}
@-o-keyframes wordsAnimation {
  0%   {background-position: 0 0;}
  100% {background-position: 0 -2610px;}
}

@media (max-width:1025px){
  .wordsView{
    background-size: contain;
    background-repeat: repeat-y;
  }
}
