$small-desktop: new-breakpoint(max-width 1380px);
$generic-notebook: new-breakpoint(max-width 1280px);
$medium-desktop: new-breakpoint(max-width 1024px);

.reflection-parallax {
  background-color: #fff;
  .thumbnail .bubble .summary .thumbnailDetails.toggleBySlide{
    @media all and (max-width: 1024px) {
      display: none !important;
    }
  }
}
.featured-reflections {
  overflow: hidden;
  margin-top: -5%;
  width: 1140px;
  height: 3380px;
  margin-left: auto;
  margin-right: auto;
  @media all and (max-width: 992px) {
    width: 100%;
  }

  .logo-block {
    width: 120px;
    min-height: 100px;
    position: fixed;
    top: 10px;
    left: 12%;
    cursor: pointer;
    z-index: 10;
    @include media($small-desktop) {
        left: 12%;
    }
    @include media($generic-notebook) {
        left: 9%;
    }
    @include media($medium-desktop) {
      left: 2%;
      margin-top: -10px;
    }
    .logo-container {
      // position: static;
      // margin-top: 13px;
      // margin-left: 8px;
      .all-link{
        margin-left: auto !important;
      }
    }
  }

  .logo-container {
    z-index: 10;
  }

  .rounded-corner {
    font-size: 18px;
    opacity: 0.85;
    padding: 4px;
    margin-left: -10px;
    border-radius: 30px;
    background-color: #FFF;
  }

  .all-link {
    z-index: 10;
    color: $pink;
    text-transform: uppercase;
    font-size: 13px;
    display: inline-block;
    line-height: 45px;
    font-weight: 300;
    margin-left: -35px;
    @include media($small-desktop) {
        line-height: 45px;
    }
    @include media($generic-notebook) {
        left: 9%;
        line-height: 60px;
    }
    @include media($medium-desktop) {
      left: 3%;
      margin-top: -10px;
      line-height: 60px;
    }

  }
  .reflection-parallax-container {
    position: relative;
    margin: 0 auto;
    @media screen and (max-width: 1024px) {
      width: auto;
    }
    @media screen and (max-width: 768px) {
      width: auto;
    }
    .reflection-parallax-bg {
      background: url(/user/img/reflections/parallax/background_image.jpg) 0 0;
      width: 100%;
      top: 0;
      left: 0;
      height: 3600px;
      position: absolute;
      @media all and (max-width: 1024px) {
         background: url(/user/img/reflections/reflections_ipad.jpg) 0 0;
         background-position: center;
      }
    }
    .transparent-bg {
      background: url(/user/img/reflections/parallax/background_transparent.png) 0 0;
      width: 100%;
      top: 0;
      left: 0;
      height: 3600px;
      position: absolute;
      @media all and (max-width: 1024px) {
        display: none;
      }
    }





    .edges {
      width: 100%;
      top: 0;
      left: 0;
      height: 4000px;
      position: absolute;
      @media screen and (max-width: 1140px) {
        display: none;
      }
      &::before {
        content: '';
        display: inline-block;
        background: transparent url(/user/img/mainLandingPage/parallax/side_pattern.png) 0 0;
        width: 30px;
        background-repeat: no-repeat repeat;
        height: 100%;
      }
      &::after {
        float: right;
        transform: rotate(180deg);
        content: '';
        display: inline-block;
        background: transparent url(/user/img/mainLandingPage/parallax/side_pattern.png) 0 0;
        width: 30px;
        background-repeat: no-repeat repeat;
        height: 100%;
      }
    }
  }
  // Animation End
  .homepage-content {
    width: 70%;
    position: relative;
    height: 3600px !important;
    margin: 18% auto 0;
  }
  .logo-container {
    a {
      font-size: 12px;
    }
  }
  .shift {
    &1 {
      top: 1%;
      left: 16%;
      position: absolute;
      @media screen and (max-width: 1024px) {
        left: 3%;
        top: 0%;
      }
    }
    &2 {
      left: 62%;
      position: absolute;
      top: 8%;
    }
    &3 {
      left: 18%;
      top: 20%;
      position: absolute;
      @media screen and (max-width: 1024px) {
        left: 27%;
      }
    }
    &4 {
      top: 26%;
      left: 61%;
      position: absolute;
      @media screen and (max-width: 1024px) {
        left: 2%;
        top: 30%;
      }
    }
    &5 {
      left: 37%;
      top: 36%;
      position: absolute;
      @media all and (max-width: 1024px) {
            left: 60%;
      }
    }
    &6 {
      left: 11%;
      top: 44%;
      position: absolute;
      @media screen and (max-width: 768px) {
        top: 46%;
      }
    }
    &7 {
      left: 35%;
      top: 53%;
      position: absolute;
      @media screen and (max-width: 1024px) {
        left: 60%;
      }
    }
    &8 {
      left: 58%;
      top: 61%;
      position: absolute;
      @media screen and (max-width: 1024px) {
        left: 18%;
      }
    }
    &9 {
      left: 12%;
      top: 71%;
      position: absolute;
      @media screen and (max-width: 1024px) {
        left: 4%;
      }
    }

  }
}

// Eyes Animation

.eyes {
  @include retina-image("/user/img/reflections/parallax/eyes", 828px 1541px);
  width: 828px;
  height: 1541px;
  left: 60px;
  top: 200px;
  position: absolute;
  animation-name: movingEyes;
  animation-duration: 70s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: movingEyes;
  -webkit-animation-duration: 70s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: movingEyes;
  -moz-animation-duration: 70s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: movingEyes;
  -o-animation-duration: 70s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  animation-delay: -2s;
  -moz-animation-delay: -2s;
  -webkit-animation-delay: -2s;
  -o-animation-delay: -2s;
}
@-webkit-keyframes movingEyes {
  0% {
    -webkit-transform: translate(100%, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
  }
}
@-moz-keyframes movingEyes {
  0% {
    -moz-transform: translate(100%, 0);
  }
  100% {
    -moz-transform: translate(-100%, 0);
  }
}
@-o-keyframes movingEyes {
  0% {
    -o-transform: translate(100%, 0);
  }
  100% {
    -o-transform: translate(-100%, 0);
  }
}

// Tree Animation
// Tree View
.tree {
  @include retina-image("/user/img/reflections/parallax/tree", 1280px 3600px repeat);
  width: 1280px;
  height: 3600px;
  opacity: 0.05;
  position: absolute;
  animation-name: treeAnimation;
  animation-duration: 180s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: treeAnimation;
  -webkit-animation-duration: 180s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: treeAnimation;
  -moz-animation-duration: 180s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: treeAnimation;
  -o-animation-duration: 180s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  background-position: 0 0;
  @media all and (max-width: 1024px) {
    display: none;
  }
}

@-webkit-keyframes treeAnimation {
  0%   {background-position: 0 0;}
  100% {background-position: 0 -2610px;}
}
@-moz-keyframes treeAnimation {
  0%   {background-position: 0 0;}
  100% {background-position: 0 -2610px;}
}
@-o-keyframes treeAnimation {
  0%   {background-position: 0 0;}
  100% {background-position: 0 -2610px;}
}

// Fish Animation
// fishRotation
.fishRotation {
  z-index: 1;
  overflow: hidden;
  width: 1280px;
  height: 1991px;
  top: calc(100vh - 100px);
  position: absolute;
  animation-name: fishCircle;
  animation-duration: 150s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: fishCircle;
  -webkit-animation-duration: 150s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: fishCircle;
  -moz-animation-duration: 150s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: fishCircle;
  -o-animation-duration: 150s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  @media all and (max-width: 1024px) {
    display: none;
  }
}

@keyframes fishCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes fishCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes fishCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes fishCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fish {
  @include retina-image("/user/img/reflections/parallax/fish-sqare", 1280 1280 no-repeat);
  width: 1280px;
  height: 1280px;
  animation-name: reverse-inner-circle;
  animation-duration: 150s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: reverse-inner-circle;
  -webkit-animation-duration: 150s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: reverse-inner-circle;
  -moz-animation-duration: 150s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: reverse-inner-circle;
  -o-animation-duration: 150s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  @media all and (max-width: 1024px) {
    display: none;
  }
}

// Chakra Animation

.chakra {
  @include retina-image("/user/img/reflections/parallax/chakra", 72px 73px repeat);
  left: 160px;
  top: 350px;
  overflow: hidden;
  width: 72px;
  height: 73px;
  position: absolute;
  animation-name: chakraRotation;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: chakraRotation;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: chakraRotation;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: chakraRotation;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  @media all and (max-width: 1024px) {
    display: none;
  }
}

.chakra1 {
  @include retina-image("/user/img/reflections/parallax/chakra-2", 103px 114px repeat);
  right: 100px;
  top: 800px;
  overflow: hidden;
  width: 103px;
  height: 114px;
  position: absolute;
  animation-name: chakraRotation;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: chakraRotation;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: chakraRotation;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: chakraRotation;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  @media all and (max-width: 1024px) {
    display: none;
  }
}

.chakra2 {
  @include retina-image("/user/img/reflections/parallax/chakra-3", 70px 78px repeat);
  left: 160px;
  top: 1500px;
  overflow: hidden;
  width: 70px;
  height: 78px;
  position: absolute;
  animation-name: chakraRotation;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: chakraRotation;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: chakraRotation;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: chakraRotation;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  @media all and (max-width: 1024px) {
    display: none;
  }
}

.chakra3 {
  @include retina-image("/user/img/reflections/parallax/chakra-4", 53px 59px repeat);
  right: 150px;
  top: 2200px;
  overflow: hidden;
  width: 53px;
  height: 59px;
  position: absolute;
  animation-name: chakraRotation;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: chakraRotation;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: chakraRotation;
  -moz-animation-duration: 30s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -o-animation-name: chakraRotation;
  -o-animation-duration: 30s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  @media all and (max-width: 1024px) {
    display: none;
  }
}

@keyframes chakraRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes chakraRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes chakraRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes chakraRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
