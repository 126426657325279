.jspVerticalBar {
  width: 5px;

  .jspTrack {
    background: #fff;
    border-left: dotted 2px #7e8080;

    .jspDrag {
      left: -2px;
      background: #dedede;
      border-radius: 30px;
    }
  }
}
