$lower-resolution: new-breakpoint(max-width 1024px);
.words-details {
    background: transparent url(/user/img/words/word_details_bg.png) left top fixed;
    width: 100%;
    background-size: cover;
    height: 100%;
    .left-nav {
        li {
            padding-left: 25px !important;
        }
    }
    .text-share-options {
        text-align: left !important;
        .share,
        .comment {
            display: inline-block !important;
        }
        .comment {
            background-position: -85px -260px !important;
        }
        .comment-active {
            background-position: -225px -260px !important;
        }
    }
    .wordsDetailsTitleContainer {
        width: 80%;
        margin: 0 auto;
        position: relative;
        padding-left: 50px;
        padding-right: 50px;
    }
    .words-detail-content {
        width: 90%;
        margin: 0 auto;
        font-family: 'Merriweather';
        font-weight: 100;
        text-align: left;
        .main {
            font-size: 30px;
            margin-right: 5px;
            color: #4d4d4d;
        }
        .sub-main {
            font-size: 28px;
            color: #808080;
            font-style: italic;
        }
    }
    .comma-space:after {
        content: "\00a0";
    }
    .page-header {
        height: 150px;
        padding-left: 14px;
        .logo-container {
            padding-left: 6px;
            padding-top: 5px;
            float: left;
        }
        &> a {
            top: 5px;
            position: relative;
        }
        .page-title {
            width: 60%;
            text-align: left;
            margin-top: 26px;
            color: #4d4d4d;
            font-weight: 300;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            line-height: 24px;
            height: 95px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin: 0 17px;
            p {
                width: 100%;
            }
            .sub-main + span {
                & > span {
                    &:first-child {
                        margin-left: 4px;
                    }
                }
            }
            @include media($lower-resolution) {
                width: 55%;
            }
            .sub-main {
                font-size: $large;
                color: $grey;
            }
            .header-translit-text {
                color: $dark-grey;
                white-space: nowrap;
                font-size: $medium;
                font-weight: 300;
                font-family: 'merriweather';
            }
            .header-translate-text {
                color: $light-grey;
                font-style: italic;
                display: inline-block;
                white-space: nowrap;
            }
        }
    }
    .lower-pardah .versions .jcarousel-control-prev,
    .lower-pardah .versions .jcarousel-control-next {
        top: 50%;
        @media all and (max-width: 1024px) {
            top: 30%;
        }
    }
    .lower-pardah {
        min-height: 196px;
    }
    .main-content {
        margin-top: 10px;
        .left-nav {
            top: 15px;
        }
        .detail-text-wrapper {
            padding-top: 0;
            .header {
                text-align: left;
                padding-left: 0;
                h3 {
                    line-height: 1.5em;
                    margin-bottom: 10px;
                }
                .header-reflection-audiovideo {
                    width: 853px;
                    margin: 0 auto;
                    h3 {
                        margin-bottom: 2px !important;
                    }
                }
                .author-name {
                    color: $pink;
                    font-weight: 300;
                    font-size: $medium;
                    text-transform: uppercase;
                    font-family: 'Merriweather Sans';
                }
                .header-intro-by {
                    color: $dark-grey;
                    font-size: $medium;
                    font-weight: 300;
                }
                .header-verb {
                    color: $grey;
                    font-size: $medium;
                    font-weight: 300;
                    text-transform: lowercase;
                    font-family: 'Merriweather Sans';
                }
                .header-title {
                    font-size: $extra-large;
                    color: $dark-grey;
                    font-weight: 300;
                    line-height: 30px;
                    font-family: 'Merriweather';
                }
                .header-excerpt-italic {
                    font-size: $small;
                    color: $grey;
                    font-weight: 300;
                    font-style: italic;
                    font-family: 'Merriweather';
                }
            }
        }
    }
}

.details {
    .text-share-options {
        background: transparent url(/user/img/words/word_dt_ct_separator.png) 4px 0 no-repeat;
        background-size: 1000px 7px;
        width: 100%;
        text-align: left;
        .share {
            height: 15px;
            background: transparent url(/user/img/common/icons/sprite_icons.png) -277px 5px no-repeat;
            width: 16px;
            padding: 23px 19px;
            line-height: 20px;
            display: inline-block;
            &:before {
                content: '';
            }
            &.active {
                background-position: -199px -221px;
                cursor: default;
                margin-left: 8px;
            }
        }
        .comment {
            background-position: -85px -260px !important;
            &.comment-active{

              background-position: -225px -260px !important;
            }
        }
    }
}
