.thumbnail {
    text-align: left;
    .episode-detail-text {
        font-size: 12px;
        font-family: 'Merriweather Sans';
        color: #4D4D4D;
    }
    .summary {
        font-weight: 100;
        line-height: 16px;
        .title {
            margin:0px;
            font-size: $large;
            font-weight: 100;
            padding-top: 2px;
            &:hover {
                color: $dark-grey;
            }
        }
        .en-title {
            color: $color-regular-text;
            font-style: italic;
            padding-top: 3px;
            font-size: $medium-large;
            line-height: 19px;
            // float: left;
            width: 100%;
            @media all and (max-width: 1024px) {
                padding-bottom: 0px;
            }
        }
        .singers {
            color: $dark-grey;
            font-size: $small;
            font-weight: 100;
            padding-bottom: 4px;
            padding-top: 2px;
            display: inline-block;
            width: 100%;
            font-family: "Merriweather Sans", "Merriweather", lucida grande, arial, tahoma, verdana, sans serif;
            &.contribute-singers{
                padding-bottom: 0px;
            }
        }
        .thumbnailDetails {
            /*margin-top: 5px;*/
            .desc,
            .poet {
                line-height: 18px;
                color: $grey;
                font-size: $small;
            }
            .poet {
                line-height: 16px;
            }
        }
        .footer {
            margin-top: 3px;
            color: $color-regular-text;
            line-height: 20px;
            font-size: $small;
            text-align: right;
            text-transform: uppercase;
            bottom: -17px;
            z-index: 100;
            position: relative;
            @media all and (max-width: 1024) {
                margin-top: 0px;
                padding-top: 3px;
            }
            .play-video-icon {
                background: url(/user/img/common/icons/gray_play.png) left 2px no-repeat;
                padding-left: 12px;
                margin-left: 3px;
                color: $grey;
                vertical-align: middle;
            }
            .play-audio-icon {
                background: transparent url('/user/img/common/icons/a_grey.png') left -1px no-repeat;
                padding-left: 17px;
                margin-left: 3px;
                color: $grey;
                vertical-align: middle;
            }
            .more-info {
                color: $grey;
                font-size: $medium-large;
                text-transform: uppercase;
                font-family: "Merriweather";
                font-weight: 300;
            }
            .duration {
                position: relative;
            }
        }
    }
}
