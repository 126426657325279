 #resources_page {
   .read-mores,.read-more-film,.people-readmore,.more-readmore{
     &:hover {
       color: #4d4d4d;
     }

     padding-right: 5px;
     left: 0px;
     color: #E61B72;
     font-size: 12px;
     text-transform: uppercase;
     font-family: 'Merriweather Sans';
     cursor: pointer;
     float: left;

   }
   .read-mores{
     padding-bottom:5px;
     padding-top:5px;
   }
   .read-more-film{
     padding-bottom:5px;
     padding-top:5px;
   }
   .people-readmore{
     padding-top: 10px;
   }
   .more-readmore{
     padding-bottom:5px;
     padding-top:5px;
   }
   .booksMainWrapper {
     overflow: hidden;
   }
   .booksMainWrapper{
     margin-top: 5px;
     margin-bottom: 20px;
   }
   .books-each-wrapper .read-mores{
    padding-top: 10px !important;
   }
    .books-block {
      text-align: left;
      line-height: normal;
      height: 130px;
      overflow: hidden;
        .readMoreContent{
          line-height: 24px;
        }
        .books-labelOrAuthor {
          color: #B3B3B3;
          font-family: 'Merriweather Sans';
          text-transform: uppercase;
          font-size: 12px;
          margin-top: 5px;
          line-height: 20px;
        }
        .books-title {
          color: $color-heading;
          font-family: 'Merriweather';
          font-size: 18px;
          text-transform: capitalize;
          line-height: 20px;
          margin-top: 0px;
          margin-bottom: 0px;
          font-weight: 100;
        }

        .books-second-title {
          font-size: 14px;
          font-style: italic;
          color: #333333;
          margin-top: 0px;
          margin-bottom: 0px;
          line-height: 20px;
        }
        .books-info {
          color: #b3b3b3;
          font-family: 'Merriweather Sans';
          font-size: 12px;
          text-transform: capitalize;
          margin-top: 0px;
          margin-bottom: 0px;
          line-height: 18px;
          font-style: italic;
        }

        .books-desc {
          line-height: 20px;
          color: $grey;
          font-family: 'Merriweather';
          font-size: $small;
          /*text-transform: capitalize;*/
          margin-top: 5px;
          margin-bottom: 5px;
        }
    }
 }
