/****************  TODO: Refactor all these magical numbers *****************/

.sprite {
    background-image: url(/user/img/common/icons/sprite_icons.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite-a_dark {
    width: 14px;
    height: 17px;
    background-position: -15px -15px;
}

.sprite-a_grey {
    width: 13px;
    height: 17px;
    background-position: -59px -15px;
}

.sprite-a_light {
    width: 13px;
    height: 17px;
    background-position: -102px -15px;
}

.sprite-a_pink {
    width: 14px;
    height: 17px;
    background-position: -145px -15px;
}

.sprite-dowload_pink {
    width: 18px;
    height: 17px;
    background-position: -189px -15px;
}

.sprite-download_dark {
    width: 18px;
    height: 17px;
    background-position: -237px -15px;
}

.sprite-download_grey {
    width: 18px;
    height: 17px;
    background-position: -15px -62px;
}

.sprite-download_light {
    width: 18px;
    height: 17px;
    background-position: -63px -62px;
}

.sprite-eng_dark {
    width: 39px;
    height: 39px;
    background-position: -111px -62px;
}

.sprite-eng_grey {
    width: 39px;
    height: 39px;
    background-position: -180px -62px;
}

.sprite-eng_light {
    width: 39px;
    height: 39px;
    background-position: -15px -131px;
}

.sprite-eng_pink {
    width: 39px;
    height: 39px;
    background-position: -84px -131px;
}

.sprite-original_dark {
    width: 39px;
    height: 39px;
    background-position: -153px -131px;
}

.sprite-original_grey {
    width: 39px;
    height: 39px;
    background-position: -222px -131px;
}

.sprite-original_light {
    width: 39px;
    height: 39px;
    background-position: -15px -200px;
}

.sprite-original_pink {
    width: 39px;
    height: 39px;
    background-position: -84px -200px;
}

.sprite-p_dark {
    width: 10px;
    height: 11px;
    background-position: -249px -62px;
}

.sprite-p_grey {
    width: 10px;
    height: 11px;
    background-position: -153px -200px;
}

.sprite-p_light {
    width: 10px;
    height: 11px;
    background-position: -193px -200px;
}

.sprite-p_pink {
    width: 10px;
    height: 11px;
    background-position: -233px -200px;
}

.sprite-share_dark {
    width: 16px;
    height: 15px;
    background-position: -153px -241px;
}

.sprite-share_grey {
    width: 16px;
    height: 15px;
    background-position: -199px -241px;
}

.sprite-share_light {
    width: 16px;
    height: 15px;
    background-position: -245px -241px;
}

.sprite-share_pink {
    width: 16px;
    height: 15px;
    background-position: -285px -15px;
}

.sprite-translit_dark {
    width: 39px;
    height: 40px;
    background-position: -289px -60px;
}

.sprite-translit_grey {
    width: 39px;
    height: 40px;
    background-position: -291px -130px;
}

.sprite-translit_light {
    width: 39px;
    height: 40px;
    background-position: -291px -200px;
}

.sprite-translit_pink {
    width: 39px;
    height: 40px;
    background-position: -15px -270px;
}

/****************************************  Footer Icons **************************************************************/
.footer-sprite {
    background-image: url(/user/img/common/directives/footer/icons/social-sites-icons.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite-fbook-dark {
    width: 19px;
    height: 20px;
    background-position: -15px -15px;
}

.sprite-fbook-grey {
    width: 20px;
    height: 20px;
    background-position: -64px -15px;
}

.sprite-fbook-light {
    width: 19px;
    height: 20px;
    background-position: -114px -15px;
}

.sprite-fbook-pink {
    width: 19px;
    height: 20px;
    background-position: -163px -15px;
}

.sprite-ok-dark {
    width: 32px;
    height: 24px;
    background-position: -15px -65px;
}

.sprite-ok-grey {
    width: 32px;
    height: 24px;
    background-position: -77px -65px;
}

.sprite-ok-light {
    width: 32px;
    height: 24px;
    background-position: -139px -65px;
}

.sprite-ok-pink {
    width: 33px;
    height: 24px;
    background-position: -15px -119px;
}

.sprite-scloud-dark {
    width: 39px;
    height: 18px;
    background-position: -78px -119px;
}

.sprite-scloud-grey {
    width: 40px;
    height: 18px;
    background-position: -147px -119px;
}

.sprite-scloud-light {
    width: 40px;
    height: 18px;
    background-position: -78px -167px;
}

.sprite-scloud-pink {
    width: 39px;
    height: 18px;
    background-position: -148px -167px;
}

.sprite-utube-dark {
    width: 19px;
    height: 23px;
    background-position: -212px -15px;
}

.sprite-utube-grey {
    width: 20px;
    height: 23px;
    background-position: -217px -68px;
}

.sprite-utube-light {
    width: 19px;
    height: 23px;
    background-position: -217px -121px;
}

.sprite-utube-pink {
    width: 19px;
    height: 23px;
    background-position: -15px -174px;
}

