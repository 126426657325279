$font-stack: 'Merriweather Sans', 'Merriweather', lucida grande, arial, tahoma, verdana, sans serif;

body {
  font-family: $font-stack;
}

a, a:visited, a:link {
  text-decoration: none;
  color: inherit;
  outline: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Merriweather';
  color: $color-link;
  font-weight: 100;
}

h1 {

}

h2 {

}

h3 {
  font-size: 16px;
  line-height: 20px;
}

h4 {

}

ul {
  list-style: none;
}

.link a {
  color: $color-link
}

.active {
  color: $color-active-link;
}

.original-html-size {

  h1 {
    font-size: 2em;
    line-height: 1.2em;
    color: $dark-grey;
  }
  h2 {
    font-size: 1.5em;
    line-height: 1.2em;
    color: $dark-grey;
  }
  h3 {
    font-size: 1.17em;
    line-height: 1.2em;
    color: $dark-grey;
  }
  h4 {
    font-size: 1.12em;
    line-height: 1.2em;
    color: $dark-grey;
  }
  h5 {
    font-size: .83em;
    line-height: 1.2em;
    color: $dark-grey;
  }

  h6 {
    font-size: .75em;
    line-height: 1.2em;
    color: $dark-grey;
  }
}