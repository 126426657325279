.signin_popup {
  position: fixed;
  left: 31.5%;
  z-index: 100;
  top: 30%;
  display: none;
  z-index: 1000;
  @media all and (max-width: 1024px) {
    left: calc(50% - 250px);
  }
  .clearfix {
    clear: both;
  }
.signinInfo{
  font-size: 12px !important;
}
.errorMessages{
  color: $pink;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.signup_container {
  padding-top: 0px;
  padding-bottom: 0px;

  .signup_box {
    width: 500px;
    min-height: 200px;
    padding: 30px;
    background-color: #fff;
    @include border-top-radius(20px);
    @include border-right-radius(20px);
    @include border-bottom-radius(20px);
    @include border-left-radius(20px);
    box-shadow: #8E8E8E 2px 0px 10px;
    margin-left: auto;
    margin-right: auto;
    h1 {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: $extra-large;
      color: $dark-grey;
    }
    .signup_content {
      color: $grey;
      font-family: 'Merriweather Sans';
      font-size: $medium;
      .sign-in-error-message{
        color: $pink;
      }
      .keepMe {
        a {
          color: $pink;
          float: right;
        }
      }
      p {
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 1.5em;
      }
      .join {
          float: left;
          width: 100px;
          background: #E61B72;
          margin: 25px 20px 10px 10px;
          text-align: center;
          line-height: 30px;
          color: #FFF;
          border-radius: 4px;
          outline: none;
          cursor: pointer !important;
          border: none;
          font-size: 14px;
          box-shadow: 0px 5px 0px 0px #bf145d;
          &:disabled {
              background: $light-grey !important;
              cursor: default !important;
          }
      }

      .join:hover {
          margin-top: 15px;
          margin-bottom: 5px;
          box-shadow: 0px 0px 0px 0px;
      }

      .tandc {
        a {
          text-decoration: underline;
        }
      }
      .signin {
        a {
          color: $pink;
        }
      }
      .dottedLine {
        height: 3px;
        border-bottom: 1px dotted $grey;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .form-control-nobostr{
      margin-top: 5px;
      margin-bottom: 5px;
      input {
        padding: 10px;
        outline: none;
        width: 100%;
        font-family: 14px;
      }
      .col {
        float: left;
        width: 50%;
        .firstInput {
          width: 99%;
        }
      }
    }
  }
}
}
