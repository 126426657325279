body.songs-details {
    .songs-details-hindi-text.hindi{

    }
   .main-content {
       padding-bottom: 0;
       .left-nav {
         width: 70px;
         text-align: left;
         margin-top: 35px;
         transition: all 0.3s linear -0.1s;
           li {
              width: 85px;
              height: 20px;
              padding: 0 32px;
              padding-bottom: 2px;
              padding-left: 15px;
              background-image: none;
           }

          .seperater {
            background: transparent url('/user/img/songs/sep2.png');
            background-size: 300px 10px;
            background-position: left bottom;
            background-repeat: no-repeat;
            height: 8px;
          }
         .bg-none {
           background: none;
         }
       }
   }
  #poem_block {
    height: 15px;
  }
  #poem_container {
    clear: both;
    width: 853px;
    margin: 0 auto;
    display: inline-block;

    .text-typography {
      text-align: center !important;
    }

    @media screen and (max-width: 1280px) {
      width: 640px;
    }

    @media screen and (max-width: 768px) {
      width: 530px;
    }
  }

  #audio_video_container {

    .rendition-wrapper {
      ul {
        float: right;
        padding: 0 0 6px;
        li {
          float: left;
          color: #999;
          & > a {
            color: #999;
            margin: 0 3px;

            &.active {
              color: $color-link-active;
            }
          }
        }
      }
    }


  }

  #poem_container {
/*    text-align: center;*/
    margin-top: -25px;
    font-weight: 300;
    line-height: 27px;
    padding-top: 20px;
    h1,h2,h3,h4,h5,h6 {
        color: $grey;
    }

    .song-heading {
      color: $grey;
      font-size: $double-x-large;
      line-height: 52px;
      padding: 0 0 20px;
      font-weight: 300;
      font-family: 'Merriweather';

    }

    .poet-details {
      color: $grey;
      font-size: $medium;
      padding-bottom: 20px;
      position: relative;
      top: -10px;
      a {
        text-transform: uppercase;
        color: $pink;
        &:hover {
            color: $dark-grey;
        }
      }
    }

    .initial-couplets {
      color: $light-grey;
      font-style: italic;
    }

    .lyrics {
      font-size: $medium;
      color: $grey;
      font-family: 'Merriweather';
      line-height: 21px;
      text-align: left;
      padding-bottom: 35px
    }

    .refrain{
      font-style:italic ;
    }

    .hindi {
      & > * {
        font-family: 'Lohit Devanagari', serif;
      }
      .song-heading {
        font-size: $double-x-large-dev;
        color: $light-grey;
      }
      .poet {
        font-size: $medium-dev;
        padding-bottom: 20px;
        position: relative;
        top: -10px;
        a {
            color: $pink;
            &:hover {
                color: $dark-grey;
            }
        }
      }

      .lyrics {
        font-size: 17px !important;
        line-height: 23px;
        font-weight:100 !important;
        color: $grey;
        text-align: left;
        padding-bottom: 35px
      }
    }

  }

  .lyrics-footer {
    background: transparent url(/user/img/songs/song_bottom.png) 0 0 no-repeat;
    height: 100px;
    width: 100%;
    display: inline-block;
    position: relative;
    ul {
      margin: 20px auto 0px;
      display: inline-block;
      width: auto;
      & > li {
        float: left;
        margin-left: 30px;
        &:first-child {
          margin-left: 0;
        }

        & > a {
          float: left;
          width: 36px;
          height: 36px;
          background: transparent url(/user/img/common/icons/sprite_icons.png) 0 0 no-repeat;
          &.active {
            cursor: default;
          }

        }

        &.original {
          & > a {
            width: 39px;
            height: 39px;
            background-position: -84px -200px;

            &:hover, &.active {
                width: 39px;
                height: 39px;
                background-position: -153px -131px;
            }
            &.disabled {
                width: 39px;
                height: 39px;
                background-position: -15px -200px;
            }


          }
        }
        &.transliteration {
          & > a {
            width: 39px;
            height: 40px;
            background-position: -15px -270px;

            &:hover, &.active {
                width: 39px;
                height: 40px;
                background-position: -289px -60px;

            }
            &.disabled {
                width: 39px;
                height: 40px;
                background-position: -291px -200px;
            }

          }
        }
        &.translation {
          & > a {
            width: 39px;
            height: 39px;
            background-position: -84px -131px;

            &:hover, &.active {
                width: 39px;
                height: 39px;
                background-position: -111px -62px;
            }
            &.disabled {
                width: 39px;
                height: 39px;
                background-position: -15px -131px;
            }


          }
        }
      }
    }
    .glossary-link {
      position: absolute;
      right: 30px;
      bottom: 55px;
      font-size: $medium;
      color: $grey;
      font-weight: 300;


      & > a {
        text-transform: uppercase;
        color: $pink;
        &:hover {
            color: $dark-grey;
        }

      }
    }
    .play-icon {
      position: absolute;
      right: 13%;
      bottom: 40px;
      background: transparent url(/user/img/songs/songs_sprite.png) -52px 0px no-repeat;
      width: 50px;
      height: 102px;
      display: inline-block;
    }
  }

  .footer-spacer{
    height: 50px;
  }
  .footer-tree{
    margin-top: 15px;
  }
}
