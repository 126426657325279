.thumbnail {
    margin: 0 auto;
    width: 300px;
    position: relative;
    cursor: pointer;
    z-index: $zIndex-thumbnail;
    &:hover {
        z-index: $zIndex-thumbnail-hover;
    }
    .image {
        width: 250px;
        height: 140px;
        img {
            border: 3px solid #FFF;
            box-shadow: 1px 1px 10px #777;
            max-width: 100%;
        }
    }
    .bubble {
        background: url(/user/img/common/directives/thumbnail/bubble-250-content.png) scroll repeat-y top left;
        width: 270px;
        position: absolute;
        top: 140px;
        left: -10px;
        @media all and (max-width: 1250px) {
            top: 0px;
            height: auto;
            position: relative;
        }
        .summary {
            position: relative; //for the title to move closer to top border
            left: 0px;
            padding: 0 20px;
            &:before {
                background: url(/user/img/common/directives/thumbnail/bubble-250-top.png) scroll repeat-y top left;
                width: 270px;
                height: 30px;
                content: " ";
                position: absolute;
                top: -30px;
                left: 0;
                @media all and (max-width: 1250px){
                  top:-29px;
                }
            }
            &:after {
                background: url(/user/img/common/directives/thumbnail/bubble-250-bottom.png) scroll repeat-y top left;
                width: 270px;
                height: 30px;
                content: " ";
                position: absolute;
                bottom: -30px;
                left: 0;
                @media all and (max-width:1250px) {
                    position: relative;
                    bottom: auto;
                    left: -20px;
                    float: left;
                    top:-1px;
                }
            }
            .thumbnailDetails.toggleBySlide {
                @media all and (max-width: 1024px) {
                    display: block !important;
                }
            }
            .categoryName {
                color: $light-grey;
                vertical-align: bottom;
            }
        }
    }
}

#participate_page {
    .thumbnail img {
        width: 100%;
    }
}
