$small-desktop: new-breakpoint(max-width 1380px);
$generic-notebook: new-breakpoint(max-width 1280px);

.homepage-content {
  width: 70%;
  position: relative;
  height: 2323px !important;
  margin: 18% auto 0;

  .featuring {
      font-size: $medium;
      color: $dark-grey;
      font-family: 'Merriweather Light';
      margin-top: 5px;
      margin-bottom: 3px;
      display: inline-block;
  }

  .number {
    color: $dark-grey !important;
  }

  .featured-songs .thumbnail .bubble .summary .thumbnailDetails.toggleBySlide{
    display: none !important;
  }

  .logo-container {
    left: 13.5%;
    top: 23px !important;
    position: fixed;

    @include media($small-desktop) {
        left: 12%;
    }
    @include media($generic-notebook) {
        left: 9%;
    }
    @include media($tab-landscape) {
        left: 2.5%;
        z-index: $zIndex-innerpage-logo;
    }
    & > a {
        cursor: default;
        color: $dark-grey;
        &:hover {
            color: $dark-grey !important;
        }
    }
  }
}
