@media all and (max-width:992px) {
    .reflections .main-content .detail-text-wrapper {
        /*padding: 0;*/
    }
}

.all-reflections {
    opacity: 0.85;

    .pagination-head {
        position: absolute;
        @include span-columns(10);
        margin-top: 75px;
        height: 150px;
        @include shift(1.5);
        @include media($tab-landscape) {
             @include span-columns(9);
            @include shift(2);
        }
    }

    .thumbnails-grid .thumbnail .bubble {
        @media all and (max-width: 1024px) {
            top: -10px;
        }
    }

    .qualifier.ajab-container {
        position: absolute;
        top: 125px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: scroll;
        top: 94px;
        padding-top: 25px;
    }

    .nav-elements-wrapper {
        .category-logo {
            font-size: 12px;
            padding-top: 35px;
            color: $dark-grey;
        }

        .rounded-corner {
            font-size: 18px;
            background: white;
            padding: 4px;
            margin-left: -10px;
            border-radius: 30px;
            background-color: #FFF;
            opacity: 0.85;
        }
    }
    /*.tooltip {
      display: inline;
      position: relative
  }
  .tooltip:hover:after {
      background: #333;
      border-radius: 4px;
      bottom: 25px;
      left: 40%;
      color: $pink;
      content: attr(title);
      width: auto;
      position: absolute;
      padding: 5px 5px
  }*/
    /* UI: Code for testing: can be removed after actual implementation */
    .slide-nav-list {}

    .second-layer-list {
        position: fixed;
    }
}

.thumbnails-grid .media {
    margin-bottom: 18px;
    @media all and (max-width:1250px) {
        width: 33%;
        margin-right: 0 !important;
    }
    @media all and (max-width: 992px) {
        width: 50%;
    }
    @media all and (min-width: 1251px) {
        width: 25%;
        margin-right: 0;
    }
}
