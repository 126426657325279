// zIndex Back to Front

$zIndex-splash: -999;

$zIndex-parallax-songs: -100;

$zIndex-ajabWrapper: 1;
$zIndex-innerpage-logo: 1;

$zIndex-thumbnail: 1;
$zIndex-thumbnail-hover: $zIndex-thumbnail + 1;

$zIndex-people-logo: 89;
$zIndex-detail-page-header: 89;
$zIndex-people-filter-pardah1: 70;




$zIndex-filter-pardah1: 180;
$zIndex-filter-pardah2: 170;

$zIndex-songs-leftNav: 200;
$zIndex-logo: 300;

$zIndex-footer: 700;

$zIndex-headerWrapper: 800;
$zIndex-headerart: 800;

$zIndex-header-fixed: 900;

$zIndex-lightbox-footer: 1000;
$zIndex-lightbox-overlay: 1000;
$zIndex-lightbox-arrows: 1000;
$zIndex-lightbox-container: 1000;
