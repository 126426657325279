.page-header {
  width: 100%;
  padding: 20px 0 0;
  display: inline-block;
  z-index: $zIndex-logo;
  // @media(max-width:1024px){
  // z-index:9999;
  // }
  .logo-container {
    width: 85px;

    > a {
      background: transparent url(/user/img/songs/songs_logo.png) center center no-repeat;
      width: inherit;
      height: 85px;
      line-height: 85px;
      float: left;
      clear: both;
      color: $pink;
      font-family: 'Merriweather';
      font-size: 18px;
      font-weight: 300;
      cursor: pointer;
      &:hover {
         color: $dark-grey;

      }
      /*.page-logo:hover {
        color: $color-hover-link;
        cursor: pointer;
      }*/
    }
  }

  .sub-info {
    width: inherit;

    .number {
      font-size: 14px;
      color: $grey;
      border-bottom: solid 1px $dark-grey;
      padding: 5px;
      font-family: 'Merriweather Light';
      display: inline-block;
    }

    .all-link {
      color: $color-link;
      text-transform: uppercase;
      font-size: 13px;
      padding-top: 5px;
      display: inline-block;
      line-height: 27px;
      font-weight: 300;
    }
  }
}
