.popup-wrapper {

  .overlay {
    background: url(/user/img/common/bg/lightbox-bg.jpg);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    color: #666;
    background-size: contain;
    overflow: hidden;
    z-index: $zIndex-lightbox-overlay;
  }

  .arrows {
    position: fixed;
    &.left {
      width: 4.5%;
      height: 100%;
      float: left
    }
    &.right {
      height: 100%;
      position: fixed;
      top: 0;
      right: 0;
      z-index: $zIndex-lightbox-arrows;

      .close {
        position: relative;
        display: block;
        margin: 20px 15px 0 0;
        float: right;
        background: transparent url(/user/img/common/directives/popup/close.png);
        height: 16px;
        width: 16px;

        &:hover {
            background-image: url(/user/img/common/directives/popup/close_hover.png);
        }
      }
    }

    .bubble {
      background-color: #B3B3B3;
      display: inline-block;
      width: 13px;
      height: 13px;
      border-radius: 10px;
      float: right;
      right: 15px;
      position: relative;
      top: 5%;
      clear: right;
      margin-bottom: 23px;
      cursor: pointer;
      &.active, &:hover {
        background: transparent url(/user/img/common/directives/popup/pink_half_moon.png);
      }
    }
  }

  .popup-container {
    width: 80%;
    text-align: center;
    position: fixed;
    top: 5%;
    left: 10%;
    z-index: $zIndex-lightbox-container;
  }

}

.fullScreen {
  width: 100%;
  height: 100%;
}

.fullHeight {
  height: 100%;
}











