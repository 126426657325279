.mapBlock {
    background-color: #000;
    position: relative;
    z-index: 10;
}

.spaceBlockHidden {
    margin-top: 0px;
    @media screen and (max-width: 992px) {
        display: none;
    }
}

.visible-lg {
    @media all and (max-width: 992px) {
        display: none;
    }
}

.hidden-lg {
    display: none;
    @media all and (max-width: 992px) {
        display: block;
    }
}

.mapBg {
    position: relative;
    background-repeat: no-repeat;
    background-image: url("/user/img/map/ajabMap.jpg");
    padding: 20px;
    min-height: 4930px;
    font-family: 'Merriweather';
    margin: 0 auto;
    width: 960px;
    @media all and (max-width: 992px) {
        background-image: url("/user/img/map/85dark_ajabmap.jpg");
        height: auto;
        min-height: 0px;
        padding-bottom: 100px;
        background-size: contain;
        width: 100%;
    }
    .youarehere {
        color: #b3b3b3 !important;
    }
    .highlight {
        color: $pink;
        font-size: 22px;
    }
    .medium-highlight {
        color: $pink
    }
    .link {
        text-decoration: underline;
    }
    .aShapeBlock {
        width: 420px;
        height: 950px;
        position: absolute;
        left: 240px;
        color: $light-grey;
        font-family: 'Merriweather';
    }
    .jShapeBlock {
        width: 580px;
        left: 305px;
        top: 710px;
        position: absolute;
        .jContent {
            margin-top: 35px;
        }
    }
    .SecondABlock {
        width: 380px;
        position: absolute;
        left: 230px;
        top: 1400px;
    }
    .bShapeBlock {
        left: 170px;
        top: 2070px;
        width: 450px;
        position: absolute;
    }
    .sShapeBlock {
        left: 30px;
        top: 2860px;
        width: 545px;
        position: absolute;
    }
    .spaceBlock {
        width: 420px;
        top: 3440px;
        position: absolute;
        right: 0px;
    }
    .thirdAShapeBlock {
        width: 495px;
        position: absolute;
        left: 50px;
        top: 4150px;
    }
    .mapTypography {
        line-height: 1.5em;
        font-weight: 200;
        @media all and (max-width: 992px) {
            left: 50px;
            position: relative;
            top: 0;
            height: auto;
            margin-top: 100px;
            width: 600px;
        }
        .white-text {
            color: #FFF !important;
        }
        h2 {
            font-size: 24px;
            color: $pink;
            margin-bottom: 10px;
        }
        p {
            color: #B3B3B3;
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
    /* Shapes */
    .forA {
        height: 1200px;
        width: 225px;
        float: left;
        -webkit-shape-outside: polygon(nonzero, 0px 0px, 95.1% 47.9%, 8% 53.15%);
                shape-outside: polygon(nonzero, 0px 0px, 95.1% 47.9%, 8% 53.15%);
        @media all and (max-width: 992px) {
            display: none;
        }
    }
    .forJ {
        height: 800px;
        width: 250px;
        float: right;
        -webkit-shape-outside: polygon(nonzero, 73.6% 69.25%, -25px 8px, 85.2% 74.4%, 80% 74.75%);
                shape-outside: polygon(nonzero, 73.6% 69.25%, -25px 8px, 85.2% 74.4%, 80% 74.75%);
        @media all and (max-width: 992px) {
            display: none;
        }
    }
    .forSecondA {
        height: 730px;
        width: 225px;
        float: left;
        -webkit-shape-outside: polygon(nonzero, -33px -14px, -17px -30px, -18px -23px, 73.35% 80.7%, 57.8% 87.25%);
                shape-outside: polygon(nonzero, -33px -14px, -17px -30px, -18px -23px, 73.35% 80.7%, 57.8% 87.25%);
        @media all and (max-width: 992px) {
            display: none;
        }
    }
    .forB {
        height: 800px;
        width: 100px;
        float: right;
        -webkit-shape-outside: polygon(nonzero, -40% 82.9%, 56% 71%, 55% 52.15%, -9% 44.15%, -97% 42%, -91% 37.9%, -14% 30.4%, 19% 19.25%, -10% 6.9%, -144px 3px, 113px -20px, 129% 84.15%);
                shape-outside: polygon(nonzero, -40% 82.9%, 56% 71%, 55% 52.15%, -9% 44.15%, -97% 42%, -91% 37.9%, -14% 30.4%, 19% 19.25%, -10% 6.9%, -144px 3px, 113px -20px, 129% 84.15%);
        @media all and (max-width: 992px) {
            display: none;
        }
    }
    .forS {
        height: 800px;
        width: 285px;
        float: right;
        -webkit-shape-outside: polygon(nonzero, 290px 421px, 271px 349px, 240px 308px, 185px 274px, 154px 250px, 117px 216px, 74px 181px, 43px 149px, 14px 104px, -18px 14px, 110px -40px, 90px 41px, 108px 99px, 170px 170px, 336px 352px, 344px 499px, 304px 531px, 287px 481px);
                shape-outside: polygon(nonzero, 290px 421px, 271px 349px, 240px 308px, 185px 274px, 154px 250px, 117px 216px, 74px 181px, 43px 149px, 14px 104px, -18px 14px, 110px -40px, 90px 41px, 108px 99px, 170px 170px, 336px 352px, 344px 499px, 304px 531px, 287px 481px);
        @media all and (max-width: 992px) {
            display: none;
        }
    }
    .forThirdA {
        /*height: 900px;*/
        height:785px;
        width: 225px;
        float: right;
        -webkit-shape-outside: polygon(nonzero, 281px -51px, 8.45% 91.45%, -32% 88.55%);
                shape-outside: polygon(nonzero, 281px -51px, 8.45% 91.45%, -32% 88.55%);
        @media all and (max-width: 992px) {
            display: none;
        }
    }
    .resources {
        position: relative;
        top: 4900px;
        width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
    .contentScroll {
        height: 120px;
    }
    .goToTopContainer {
        color: #FFF;
        top: 4820px;
        font-family: 'Merriweather';
        position: absolute;
        left: 650px;
        z-index: 1;
        font-size: 12px;
        .goToTop {
            color: $pink;
            font-size: 14px;
        }
        @media all and (max-width: 992px) {
            top: auto;
            left: auto;
            right: 65px;
        }
    }
    .goToTopIcon {
        position: absolute;
        top: 4800px;
        left: 720px;
        z-index: 1;
        @media all and (max-width: 992px) {
            top: auto;
            bottom: 70px;
            right: 15px;
            left: auto;
        }
    }
}