body {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.splash_screen {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: $zIndex-splash;
}

.slide_bottom {
  position: fixed;
  bottom: 0;
  right: 20%;

  display: block;
  width: 220px;
  height: 148px;
  background: url(/user/img/introduction/enter-pink.png) no-repeat;
  &:hover {
    background: url(/user/img/introduction/enter-grey.png) no-repeat;
  }

  a.enter {
    display: inline-block;
    width: 135px;
    height: 38px;
    cursor: pointer;
    position: relative;
    top: 10px;
    left: 8px;
  }

  /*a.pravesh {
    display: inline-block;
    margin: 40px 0px 0 2px;
    color: #fff;
    width: 70px;
    height: 20px;
    cursor: pointer;
  }*/
}
