.footer-container {
    position: relative;
    clear: both;
    z-index: 1;
}

.footer-tree {
    background: transparent url('/user/img/common/bg/footer_tree.png') 0 top no-repeat;
    background-size: contain;
    height: 85px;
    margin-left: 5%;
    width: 45px;
    padding-top: 127px;
    margin-top: -200px;
}

.subscription-form {
    margin-top: 10px;
}

.footer-box {
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: center;
    //    height: 295px;
    margin-top: 99px;
    background: black;
    .footer-bg {
        background: transparent url('/user/img/common/bg/footer-bg.png') -40px -1px repeat;
        background-size: cover;
        position: relative;
        margin-top: -180px;
        //        height: 397px;
        .content-wrapper {
            width: 100%;
            height: inherit;
            padding-top: 115px;
        }
        .footer-content {
            font-weight: 300;
            color: $light-grey;
            font-family: 'Merriweather Sans';
            font-weight: 300;
            display: inline-block;
            width: 90%;
            text-align: left;
            @media screen and (max-width: 1280px) {
                width: 95%;
            }
            @media screen and (max-width: 768px) {
                width: 98%;
            }
            a {
                &:hover {
                    color: $pink;
                }
                &.disable {
                    color: $dark-grey;
                    cursor: default;
                }
            }
            .first-row-footer {
                font-size: 12px;
                width: 100%;
                float: left;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin-bottom: 20px;
                .highlight {
                    font-size: 12px;
                    color: $pink;
                    font-weight: 200;
                }
                .vertical-separator {
                    background: transparent url("/user/img/common/directives/footer/dotted.png") 0 top;
                    background-repeat: no-repeat repeat;
                    padding: 0 3%;
                    position: relative;
                    //                    height: 170px;
                    @media screen and (max-width: 1281px) {
                        padding: 0 2%;
                        // height: 187px;
                    }
                }
                .footer-title {
                    font-size: 18px;
                    color: $pink;
                    margin-bottom: 5px;
                    font-weight: 200;
                }
                .top-align {
                    margin-top: -2px !important;
                }
                .first-row-col {
                    float: left;
                    width: 25%;
                    margin-top: 2%;
                    padding: 0 3%;
                    position: relative;
                    p {
                        line-height: 22px;
                    }
                    @media all and (max-width: 1281px) {
                        padding: 0 2%;
                    }
                    @media screen and (max-width: 1024px) {
                        width: 24%;
                    }
                }
                .support-col {
                    float: left;
                    width: 29%;
                    margin-top: 2%;
                    p {
                        line-height: 22px;
                    }
                    @media screen and (max-width: 1024px) {
                        width: 28%;
                    }
                }
                .subscribe-col {
                    float: left;
                    width: 31%;
                    margin-top: 2%;
                    p {
                        line-height: 22px;
                        margin-top: 5px;
                    }
                    input {
                        padding: 5px;
                        &.subscribe-mail-input {
                            background: #808080;
                            box-shadow: none;
                            border: 1px solid #fff;
                            outline: 0;
                            margin-right: 5px;
                            @media all and (max-width: 1100px) {
                                width: 100%;
                            }
                            &::-webkit-input-placeholder {
                                color: #fff;
                            }
                            &:-moz-placeholder {
                                /* Firefox 18- */
                                color: #fff;
                            }
                            &::-moz-placeholder {
                                /* Firefox 19+ */
                                color: #fff;
                            }
                            &:-ms-input-placeholder {
                                color: #fff;
                            }
                        }
                    }
                    .subscribe-mail-btn {
                        padding: 5px 10px;
                        margin-top: 1px;
                        display: inline-block;
                        box-sizing: border-box;
                        background-color: transparent;
                        color: $pink;
                        border-radius: 6px;
                        font-size: 12px;
                        border: 1px solid #808080;
                        cursor: pointer;
                        @media all and (max-width: 1100px) {
                            width: 100%;
                            margin-top: 15px;
                        }
                        &:hover {
                            color: #b3b3b3;
                        }
                    }
                    #mc_embed_signup {
                        @include clearfix;
                        input[type='email'] {
                            width: 73%;
                            padding: 2px;
                            background: #FFF;
                            float: left;
                            outline: none;
                            margin-bottom: 5px;
                            color: #000;
                            font-family: 'Merriweather Sans';
                            @media(min-width: 768px) and (max-width: 1170px) {
                                width: 100%;
                            }
                        }
                        input[type='submit'] {
                            text-transform: uppercase;
                            padding: 5px;
                            float: right;
                            background: $pink;
                            color: #FFF;
                            border: none;
                            border-radius: 5px;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                        }
                        .mce_inline_error {
                            padding: 3px;
                            margin: 0;
                            color: #FFF;
                            background: none;
                            font-weight: normal;
                            border: none;
                        }
                    }
                }
                .site-links {
                    float: left;
                    width: 15%;
                    margin-top: 2%;
                    font-size: 14px;
                    ul {
                        margin-top: 0;
                        li {
                            margin-bottom: 20px;
                            text-transform: capitalize;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        width: 17%;
                    }
                }
                a {
                    &:hover {
                        color: #b3b3b3;
                    }
                    .highlight {
                        &:hover {
                            color: #b3b3b3;
                        }
                    }
                }
            }
            .content-links {
                background: transparent url("/user/img/common/bg/horizon-separator.png") left top repeat-x;
                padding: 20px 20px 5px;
                font-size: 14px;
                width: 100%;
                float: left;
                text-align: center;
                ul {
                    width: 100%;
                    display: inline-block;
                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }
                }
                li {
                    width: auto;
                    display: inline-block;
                    border-right: solid thin;
                    padding: 0 1%;
                    margin-bottom: 10px;
                    &:last-child {
                        border: none;
                        margin-right: 0;
                    }
                }
            }
            #dark-font {
                color: $dark-grey;
                display: inline;
            }
            .footer-info {
                background: transparent url("/user/img/common/bg/horizon-separator.png") left top repeat-x;
                padding: 20px 0;
                width: 100%;
                float: left;
                ul {
                    width: 100%;
                    float: left;
                    @media all and (max-width: 992px) {
                        padding-left: 15px;
                    }
                }
                li {
                    float: left;
                    .srishti-logo {
                        background: url(/user/img/common/directives/footer/icons/srishti-logo-light.png) no-repeat;
                        background-size: contain;
                        width: 56px;
                        height: 60px;
                        float: left;
                        margin-right: 15px;
                        &:hover{
                            background: url(/user/img/common/directives/footer/icons/srishti_logo.png) no-repeat;
                              background-size: contain;
                            
                        }
                    }
                    .kabir-logo {
                        background: url("/user/img/common/directives/footer/icons/kabirlogo-light.png") -1px 0 no-repeat;
                        width: 35px;
                        float: left;
                        height: 50px;
                        margin-top: 5px;
                        &:hover {
                            background: url("/user/img/common/directives/footer/icons/kabirlogo-pink.png") -1px 0 no-repeat;
                        }
                    }
                    &.project-details {
                        width: 74%;
                        float: left;
                        height: 50px;
                    }
                    &.fbook,
                    &.sound-cloud,
                    &.ok-listen,
                    &.social-utube,
                    &.disqus {
                        margin-right: 15px;
                        float: right;
                        margin-top: 10px;
                    }
                    &.disqus {
                        a {
                            background: url(/user/img/common/directives/footer/icons/disqus.png);
                            width: 50px;
                            height: 9px;
                            margin-top: 7px;
                            float: left;
                            &:hover {
                                background: url(/user/img/common/directives/footer/icons/disqus-pink.png);
                            }
                        }
                    }
                    ;
                    &.ok-listen {
                        margin-right: 0;
                        a:hover {
                            background-position: -15px -119px;
                        }
                    }
                    &.fbook a:hover {
                        background-position: -162px -15px;
                    }
                    &.sound-cloud a:hover {
                        background-position: -147px -167px;
                    }
                    &.social-utube a:hover {
                        background-position: -14px -174px;
                    }
                    &.fbook {
                        margin-right: 1px;
                    }
                }
                .created-by {
                    width: 90%;
                    margin-left: 15px;
                    float: left;
                    font-size: 12px;
                    line-height: 18px;
                    .website-design {
                        display: inline;
                        @media screen and (max-width: 768px) {
                            display: block;
                            float: left;
                        }
                    }
                }
            }
        }
    }
}
