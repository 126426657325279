#couplets_explore {
    min-height: 900px;
    width: 100%;
    background: transparent url("/user/img/couplets/couplets_explore.jpg") repeat fixed;
    background-size: cover;

    .page-title {
        margin-left: 100px;
        margin-top: 22px;
        text-align: left;

        .main {
            margin-bottom: 5px;
        }
    }

/*    .main-content {
        margin: 0;
    }*/

    & .page-header {
        background: transparent url("/user/img/couplets/pardah_long.png") -800px bottom no-repeat;
    }

    & .exploreDescription {
        font-family: 'Merriweather';
        /*margin-left: 125px;*/
        margin-right: auto;
        margin-bottom: 30px;
        line-height: 1.3em;
        color: $dark-grey;
        font-weight: 300;
        margin-top: 155px;
        text-align: left;
        padding-bottom: 5px;
        border-bottom: $light-grey solid 1px;
        @media all and (max-width:1024px) {
            margin-top: 0;
        }

        & p {
            font-size: 14px;
        }

        & .word-italic {
            font-style: italic;
            color: $grey;
        }
    }

    .sub-main {
        font-size: 16px !important;
        font-style: italic;
        color: $color-regular-text;
        font-family: 'Merriweather';
    }

    .link {
        color: $pink;
    }

    .coupletsWords {
        & a {
            color: $pink;
            cursor: pointer;

            &:hover {
                color: $dark-grey;
            }
            &.couplet-reset-button{
                color: #E61B72;
                text-transform: uppercase;
                font-weight: 300;
                font-family: 'Merriweather Sans';
                cursor: pointer;
                font-size: 10px;
            }
        }

        .active {
            color: $dark-grey;
            /*text-decoration: underline;*/
            a{
                color: $dark-grey;
            }
        }

        & .relatedThroughWords {
            font-style: italic;
        }
    }

    .couplets_content {
        width: 560px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 350px;
        text-align: center;

        & p {
            font-size: 18px;
            font-family: 'Merriweather';
            margin-bottom: 20px;
            line-height: 1.5em;
            color: $dark-grey;
        }

        & span {
            font-size: 12px;

            & .link {
                font-size: 12px;
                color: $pink;
            }
        }
    }

    .left-nav {
        width: 70px;
        left: 0;
        top: -5px;
        position: absolute;
        display: inline-block;
        text-align: left;
        transition: all 0.3s linear -0.1s;

        & ul {
            margin-left: -15px;

            & li {
                width: 85px;
                padding: 0 32px 5px 15px;

                & a {
                    display: block;
                    font-weight: 300;
                    font-family: 'Merriweather';
                    color: #E61B72;
                    font-size: 14px;
                    margin-top: 8px;
                    margin-bottom: 3px;

                    &:hover {
                        color: $dark-grey;
                    }
                }

                & a.active {
                    cursor: default;
                    color: #4d4d4d;
                    font-weight: 300;
                    text-decoration: underline;
                }
            }

            .seperater {
                background: transparent url('/user/img/songs/sep2.png');
                background-size: 300px 10px;
                background-position: left bottom;
                background-repeat: no-repeat;
                height: 7px;
            }
        }

        .child-list {
            margin-left: 0 !important;
            margin-top: -9px !important;

            li {
                height: 17px;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0;

                a {
                    font-size: 12px;
                    height: 15px;
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }
        }
    }
    /*.left-nav {
        width: 70px;
        left: 0%;
        top: -5px;
        position: absolute;
        display: inline-block;
        text-align: left;
        transition: all 0.3s linear -0.1s;

        & ul {
            margin-left: -15px;
            & li {
                width: 85px;
                padding: 0px 32px;
                padding-bottom: 2px;
                padding-left: 30px;
                & a {
                    display: block;
                    font-weight: 300;
                    font-family: 'Merriweather';
                    color: #E61B72;
                    font-size: 14px;
                    margin-top: 8px;
                    margin-bottom: 3px;
                    &:hover {
                        color: $dark-grey;
                    }
                }
                & a.active {
                    cursor: default;
                    color: #4d4d4d;
                    font-weight: 300;
                    text-decoration: underline;
                }
            }
            .seperater {
                background: transparent url('/user/img/songs/sep2.png');
                background-size: 300px 10px;
                background-position: left bottom;
                background-repeat: no-repeat;
                height: 7px;
            }
        }


          .child-list {
        margin-left: 0 !important;
        margin-top: -9px !important;
        li {
            height: 17px;
            margin-top: 0;
            margin-bottom: 0;
            padding-bottom: 0;
            a {
                font-size: 12px;
                height: 15px;
                margin-bottom: 0;
                margin-top: 0;
            }
        }
    }
    }*/
    .ajab-wrapper {
        width: 98%;
        padding: 0;
        display: block;
        margin: 0 auto;

        .logo-container {
            position: absolute;
            @media screen and (max-width: 768px) {
                left: 0.5%;
            }

            & > a {
                font-size: 18px;
                color: $pink;

                &:hover {
                    color: $dark-grey;
                }

                & > .page-logo {
                    background-color: white;
                    padding: 4px;
                    border-radius: 30px;
                    opacity: 0.85;
                }
            }
        }

        .content-wrapper {
            box-sizing: border-box;
            padding: 2%;
            width: 70%;
            text-align: left;
            margin: -30px auto 0;
            min-height: 1300px;
            font-weight: 300;
        }
    }

    .lyrics-footer {
        background: transparent url("/user/img/songs/song_bottom.png") 0 0 no-repeat;
        height: 100px;
        width: 100%;
        margin-bottom: 100px;
        display: inline-block;
        position: relative;

        ul {
            margin: 20px auto 0;
            display: inline-block;
            width: auto;

            & > li {
                float: left;
                margin-left: 30px;

                &:first-child {
                    margin-left: 0;
                }

                & > a {
                    float: left;
                    width: 36px;
                    height: 36px;
                    background: transparent url("/user/img/common/icons/sprite_icons.png") 0 0 no-repeat;

                    &.active {
                        cursor: default;
                    }
                }

                &.original {
                    & > a {
                        width: 39px;
                        height: 39px;
                        background-position: -84px -200px;

                        &.active,
                        &:hover {
                            width: 39px;
                            height: 39px;
                            background-position: -153px -131px;
                        }

                        &.disabled {
                            width: 39px;
                            height: 39px;
                            background-position: -15px -200px;
                        }
                    }
                }

                &.transliteration {
                    & > a {
                        width: 39px;
                        height: 40px;
                        background-position: -15px -270px;

                        &.active,
                        &:hover {
                            width: 39px;
                            height: 40px;
                            background-position: -289px -60px;
                        }

                        &.disabled {
                            width: 39px;
                            height: 40px;
                            background-position: -291px -200px;
                        }
                    }
                }

                &.translation {
                    & > a {
                        width: 39px;
                        height: 39px;
                        background-position: -84px -131px;

                        &.active,
                        &:hover {
                            width: 39px;
                            height: 39px;
                            background-position: -111px -62px;
                        }

                        &.disabled {
                            width: 39px;
                            height: 39px;
                            background-position: -15px -131px;
                        }
                    }
                }
            }
        }

        .about-link {
            position: absolute;
            left: 30px;
            bottom: 55px;
            font-size: $medium;
            color: $grey;
            font-weight: 300;

            & > a {
                text-transform: uppercase;
                color: $pink;

                &:hover {
                    color: $dark-grey;
                }
            }
        }

        .glossary-link {
            position: absolute;
            right: 30px;
            bottom: 55px;
            font-size: $medium;
            color: $grey;
            font-weight: 300;

            & > a {
                text-transform: uppercase;
                color: $pink;

                &:hover {
                    color: $dark-grey;
                }
            }
        }

        .play-icon {
            position: absolute;
            right: 13%;
            bottom: 40px;
            background: transparent url("/user/img/songs/songs_sprite.png") -52px 0 no-repeat;
            width: 50px;
            height: 102px;
            display: inline-block;
        }
    }
}
