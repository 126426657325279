.reflections {
    .page-header {
        .logo-container {
            &>a {
                font-size: 12px;
            }
            .rounded-corner {
                font-size: 18px;
                background: white;
                padding: 4px;
                margin-left: -7px;
                border-radius: 30px;
                background-color: #FFF;
                opacity: 0.85;
            }
        }
        .page-title {
            margin-top: 22px;
            font-weight: 300;
            margin-left: 2%;
            @media all and (max-width: 1024px) {
                margin-left: 30px;
            }
            .main {
                text-align: left;
            }
            .sub-main {
                font-family: 'Merriweather Sans';
                text-align: left;
                color: $grey;
                line-height: 26px;
                font-style: normal;
                .name {
                    font-size: $medium;
                    color: $pink;
                    text-transform: uppercase;
                }
            }
        }
    }
    .main-content .detail-text-wrapper {
        margin: 130px auto;
        background-size: 100% 100%;
        margin-bottom: 0px;
        @media all and (max-width: 1024px) {
            margin-top: 0px;
        }
    }
}
