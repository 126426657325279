.second-layer-list {

    position: fixed;
    left: 220px;
    top: 0;
    min-height: 650px;
    width: 275px;
    text-align: left;
    z-index: -1;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 12px;
    box-sizing: border-box;

    &.ng-hide-add {
        @include animation(pardah2-slideRight 0.5s forwards);
    }
    .overlay {
        background: transparent url('/user/img/songs/filter-pardah2.png') right -5px repeat-y;
        position: fixed;
        min-width: 300px;
        z-index: -1;
        background-size: cover;
        height: 100%;
        width: 90px;

        & > ul {
            display: block;
            margin-top: 32px;
            margin-left: 35px;
            width: 80%;
            height: 92%;
            overflow-x: hidden;
            overflow-y: auto;
            margin-right: 30px;
            padding-right: 15px;
            @media all and (max-width: 1024px) {
                margin-top: 95px;
            }
            & > .list-item {
                padding: 5px 2%;
                & > a {
                    line-height: 1.4em;
                    text-transform: capitalize;
                    color: $color-sub-link;
                    font-size: $small;

                    .child-list-item-value {
                        &:nth-child(2) {
                            font-size: $small;
                            font-weight: 100;
                            font-style: italic;
                            text-transform: capitalize;
                            color: $color-regular-text-light;
                            cursor: pointer;
                        }

                        &:hover {
                            color: $color-hover-link ;
                        }
                    }

                    &:hover {
                        color: $color-hover-link ;
                    }
                }

            }
        }
    }

    &.ng-hide {
      @include animation(pardah2-slideLeft 0.5s forwards);
    }
}

@include keyframes(pardah2-slideRight) {
    0% {
      left: -200px;
    }
    100% {
      left: 220px;
    }
}

@include keyframes(pardah2-slideLeft) {
    0% {
      left: 220px;
    }
    100% {
      left: -200px;
    }
}
