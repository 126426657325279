/*
.all-songs {
    position: fixed;
    .pagination-head {
        width: 87% !important;
        margin-top: 75px;
        position: fixed;

        @include media($tab-landscape) {
             width: 83%;
        }
    }
}
*/

.all-songs {
    .thumbnails-grid .thumbnail .bubble
    {
        @media all and (max-width: 1024px) {
            margin-top: -10px;
        }
    }
    .pagination-head {
      position: absolute;
      @include span-columns(10);
        margin-top: 75px;
        height: 150px;
        @include shift(1.5);

        @include media($tab-landscape) {
          @include span-columns(9);
          @include shift(2);


        }
    }

    .qualifier.ajab-container{
        position: absolute;
        top: 130px;
        left:0px;
        right:0px;
        bottom:0px;
        overflow-y: scroll;
    }
}

