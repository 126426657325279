.explore {

    .sub-main {
        font-size: $large !important;
        color: $grey;
    }

    .main-content {
        margin-top: 155px;
        @media all and (max-width: 1024px) {
            margin-top: 0px !important;
        }

        .song-explore-text {
                        width: 85%;
                        margin: 0 auto;
                        font-family: 'Merriweather';
                        font-size: 16px;
                        color: $grey;
                        line-height: 20px;
                        padding-bottom: 10px;
                        font-weight: 300;
                        position: relative;
                        top: -25px;
                        .explore-words {
                            a {
                              color: $pink;
                            }
                            font-family: 'Merriweather';
                            text-transform: capitalize;
                        }
                    }


        .grid-wrapper {
            margin-left: 125px;




        }


    }

    #container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;

        .media {
            min-height: 0px;
            width: 25%;
            margin-bottom: 48px;
            float: left;
            @include media($tab-landscape) {
               width: 33%;
            }

            @media all and (max-width: 1250px){
               width: 33%;
            }

            @include media($tab-portrait) {
               width: 50%;
            }

             .bubble {
                position: relative;
                top: -4px;
                left: -9px;
            }
        }
    }


}
