html {
    overflow-x: hidden;
}

body {
    width: 100%;
    /*overflow: hidden;*/
    background: #fff;
}

a:active,
a:selected,
a:visited {
    border: none;
    outline: none;
}


/*br , p br{
  display: none !important;
}*/


/* Featured Content Page Layout*/

.main-content-wrapper {
    overflow: hidden;
    margin-top: -5%;
}

.menuMarginSet {
    .songs-sub-menu {
        // margin-top: 10px !important;
    }
}

.ajab-container {
    width: 100%;
    text-align: center;
    display: inline-block;
}

.mandatory-fields {
    color: $pink;
}

.ajab-wrapper {
    width: 98%;
    text-align: center;
    display: block;
    margin: 0 auto;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.shareItemsDropDown {
    width: 100%;
    float: left;
}
.shareItems{
    position: relative;
    top: -10px;
}
.shareItemsDropDown ul {
    display: block;
    box-sizing: border-box;
    float: left;
    width: 127px;
    margin-left: 50px;
    color: #b3b3b3;
    background: #ffffff;
    /*box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.1), -1px 1px 1px rgba(0, 0, 0, 0.1);*/
    border-radius: 5px;
    line-height: 4;
    background: transparent url('/user/img/songs/relatedlinks_parda.png') 0 0 no-repeat;
    background-size: 100% 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

.shareItemsDropDown ul li {
    display: inline;
    /*padding: 0 6%;*/
    padding: 0 15px;
    color: $pink;
}

.shareItemsDropDown ul li:hover {
    color: $grey;
}

[ng\:cloak],
[ng-cloak],
.ng-cloak {
    display: none;
}


/*White Space*/

.whiteSpace {
    height: 100px;
}

.golu-rounded-text {
    font-size: 18px !important;
    & a {
        background-color: #FFF;
        border-radius: 30px;
        padding: 5px;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip.active a{
      color: #4d4d4d !important;
}

.tooltip .tooltiptext {
    visibility: hidden;
    font-size: 10px;
    width: 200px;
    background-color: $dark-grey;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $dark-grey transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.reset-margin {
    margin-left: 45px;
}

.highlight-text:active {
    color: $dark-grey;
}

.text-typography .notes-desc-bg {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
