#films_explore {
  min-height: 1210px;
  width: 100%;
  background: transparent url(/user/img/films/filmsDetail.jpg) 0 0 repeat;
  background-size: cover;
  .page-title {
    margin-left: 100px;
    margin-top: 22px;
    text-align: left;
  }
  .sub-main {
    font-family: 'Merriweather';
    font-style: italic;
    line-height: 26px;
    font-size: 16px !important;
  }
  .left-nav {
    margin-top: 10px;
    li {
      background: none;
      padding-bottom: 0;
      padding-left: 15px;
      a {
        cursor: pointer;
        margin: 0;
        text-decoration: none;
      }
      & .active {
        color: #4d4d4d;
        text-decoration: underline;
      }
      & .child-list {
        margin-top: 0;
        margin-left: 0;
      }
    }
    .child-list {
      li {
        height: 19px;
      }
    }
    .seperater {
      background: transparent url('/user/img/songs/sep2.png');
      background-size: 300px 10px;
      background-position: left bottom;
      background-repeat: no-repeat;
      height: 7px;
      margin-bottom: 8px;
    }
  }
  .ajab-wrapper {
    width: 98%;
    padding: 0;
    display: block;
    margin: 0 auto;
    & .main-content {
      margin-top: 150px;
    }
    & .filmTitle {
      font-size: $large;
      color: $dark-grey;
      & span {
        font-size: $medium;
        font-style: italic;
      }
    }
    & .filmByBlock {
      margin-top: 5px;
      font-size: $small;
      font-family: 'Merriweather Sans';
      color: $grey;
    }
    & .filmBy {
      color: $pink;
      & a {
        color: $pink;
        text-transform: uppercase;
        &:hover {
          color: $dark-grey;
        }
      }
    }
    .filmDescription {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      width: 853px;
      span {
        color: $grey;
      }
      a {
        color: $pink;
      }
      h2 {
        font-size: $extra-large;
        color: $dark-grey;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
    .logo-container {
      position: absolute;
      @media screen and (max-width: 768px) {
        left: 0.5%;
      }
      & > a {
        font-size: 18px;
      }
    }
    .content-wrapper {
      box-sizing: border-box;
      padding: 2%;
      width: 70%;
      text-align: left;
      margin: -30px auto 0;
      min-height: 1300px;
      font-weight: 300;
    }
  }
  .grid-wrapper {
    & .exploreDescription {
      font-family: 'Merriweather';
      width: 680px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      line-height: 1.3em;
      color: $grey;
      font-weight: 200;
    }
  }
  & .andSymbol {
    color: $grey;
  }
}
