.header-box {
    margin-top: 0%;
    position: absolute !important;
    left: 0;
    &.fixed {
        position: fixed !important;
        width: 100%;
        top: 0;
        display: block;
        z-index: 90;
        height: 0px;
    }
    .headerWrapper {
        transition: none;
        background: #fff;
        padding: 0% 0 0% 0;
        position: relative;
        z-index: $zIndex-headerWrapper;
        box-shadow: #DDD 0 1px 15px;
        header {
            display: inline-block;
            width: 98%;
            padding: 10px 0 20px;
            margin: 0 auto;
        }
    }
    .headerMap-desk {
        @media all and (max-width: 1024px) {
            display: none;
        }
    }
    .headerArt-ipad {
        display: none;
        @media all and (max-width: 1024px) {
            display: block;
        }
    }
    .headerArt-desk {
        @media all and (max-width: 1024px) {
            display: none;
        }
    }
    .headerArt {
        background: url(/user/img/common/directives/header/header.png) 100% -1px no-repeat;
        transition: none;
        margin-top: -1px;
        height: 90px;
        position: relative;
        z-index: $zIndex-headerart;
        width: 812px;
        float: right;
        @media all and (max-width: 1025px){
            cursor: pointer;
        }
        @media all and (max-width: 992px) {
            width: 650px;
            background: url(/user/img/common/directives/header/header-992.png) 100% -1px no-repeat;
          
        }
        .map-image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        /*.menuTree {
      display: inline-block;
      width: 130px;
      height: 70px;
      float: right;
      margin: 28px 115px 0 -100px;
      cursor: pointer;
    }*/
    }
}

@media all and (max-width: 1024px) {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
        width: 12px;
    }
    ::-webkit-scrollbar:horizontal {
        height: 12px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .5);
        border-radius: 10px;
        border: 2px solid #ffffff;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #ffffff;
    }
}
