#resources_people_page {
    &.resources-container {
        padding: 0 !important;
    }

    .people-grid-wrapper {
        margin: 0;
        @include media($tab-portrait) {
            margin: 0;
        }

        .image {
            img {
                width: 99%;
                border: 3px solid #FFF;
                box-shadow: 1px 1px 10px #777;
                height: 150px;
            }
        }

        .pagination-head {
            margin-top: 75px;
        }

        .row-wrapper {
            clear: both;
            //  height: 180px;
            .image {
                float: left;
                width: 255px;
                text-align: left;
            }

            .people-block {
                margin-bottom: 30px;
                float: left;
                width: 100%;
                /*overflow: hidden;*/
            }

            .people-wrapper {
                text-align: left;
                float: left;
                width: calc(97% - 255px);
                margin-left: 2%;
                @media all and (max-width: 992px) {
                    width: 100%;
                    float: left;
                    margin-top: 15px;
                    margin-left: 0;
                       margin-bottom: 30px;
                }

                a {
                    &:nth-child(2) {
                        border-right: solid thin;
                        padding-right: 10px;
                    }

                    &:nth-child(3) {
                        margin-left: 10px;
                        background-position: 55px -1px;
                    }

                    &:nth-child(2):not(.read-link) {
                        border: none;
                        padding-right: 0;
                        background-position: 55px -1px;
                    }
                }

                .person-content {
                    color: $grey;
                    font-family: 'Merriweather';
                    font-size: $small;
                    /*text-transform: capitalize;*/
                    margin-top: 0;
                    margin-bottom: 5px;
                    line-height: 20px;
                }

                .people-info {
                    font-size: $medium;
                    font-family: 'Merriweather';
                    color: $grey;
                    // min-height: 124px;
                    height: 125px;
                    overflow: hidden;
                    line-height: 1.45em;
                    margin-top: 2px;


                    .labelcolor {
                        color: $color-icon;
                    }

                    .person-labelOrAuthor {
                        text-transform: uppercase;
                        font-family: 'Merriweather Sans';
                    }

                    .person-info {
                        font-style: italic;
                        color: $color-icon;
                        font-size: $small;
                        font-family: 'Merriweather Sans';
                    }

                    .title {
                        text-transform: Capitalize;
                        font-size: 18px;
                        color: $color-heading;
                        margin-right: 5px;
                        display: inline-block;
                        margin-top: 2px;
                        font-weight: 100;

                        &:hover {
                            color: $pink;
                        }
                    }

                    .job-title {
                        text-transform: uppercase;
                        font-size: $small;
                        color: $grey;
                        font-family: 'Merriweather Sans';
                    }

                    .comma-space:after {
                        content: "\00a0";
                    }
                }

                .explore {
                    background: transparent url('/user/img/people/explore-icon.png') 55px 3px no-repeat;
                    background-size: 15px 16px;
                    display: inline-block;
                    float: left;
                    width: 100px;
                    color: $pink;
                    font-weight: 300;
                    font-size: $small;
                    font-family: 'Merriweather Sans';
                    text-transform: Uppercase;
                    margin-top: 5px;

                    &:hover {
                        color: $dark-grey;
                    }
                }

                .read-link {
                    color: $pink;
                    font-weight: 300;
                    font-family: 'Merriweather Sans';
                    font-size: $small;
                    margin-top: 5px;
                    float: left;
                    text-transform: uppercase;

                    &:hover {
                        color: $dark-grey;
                    }
                }
            }

            .people-wrapper-width {
                width: 100% !important;
            }
        }

        .no-image-default-height {
            min-height: auto !important;

            .person-content {
                margin-bottom: 0 !important;
            }
        }
    }
}
