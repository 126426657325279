
$lower-resolution: new-breakpoint(max-width 1024px);
.word-explore {
  .left-nav {
    li {
      padding-left: 20px !important;
       a {
         height: 27px;
       }

      .child-list {
        margin-left: 0 !important;
      }
    }
  }
  .page-header {
    height: 150px;
    .logo-container {
      padding-left: 6px;
      padding-top: 5px;
      float: left;
    }
    &> a {
      top: 5px;
      position: relative;
    }

    .page-title {
      width: 60%;
     text-align: left;
     margin-top: 26px;
     color: #4d4d4d;
     font-weight: 300;
     display: flex;
     line-height: 24px;
     height: 95px;
     align-items: center;
     margin: 0 17px;
     p {
        width: 100%;
      }
      .sub-main + span {
       & > span {
         &:first-child {
           margin-left: 4px;
         }
       }
     }
     @include media($lower-resolution) {
        width: 55%;
      }
      .sub-main {
        font-size: $large;
        color: $grey;
      }
      .header-translit-text {
        color: $dark-grey;
        white-space: nowrap;
        font-size: $medium;
        font-weight: 300;
        font-family: 'merriweather';
      }
      .header-translate-text {
        color: $light-grey;
        font-style: italic;
        display: inline-block;
        white-space: nowrap;
      }
    }
  }
}
.word-explore .left-nav ul li{
  width: auto;
  // padding: 0px;
  padding-right: 0px;
}
.word-explore .left-nav{
  width: auto;
}
.word-explore .child-list{
  margin-top: 0px !important;
}
a.relatedWordLink{
  height: 20px !important;
}
