 #about_page {
     &.about_bg_1 {
         background: transparent url(/user/img/about/about_bg_1.jpg) 0 0 repeat fixed;
         background-size: 100%;
         @media all and (max-width: 1024px) {
             background-size: cover;
         }
     }
     &.about_bg_2 {
         background: transparent url(/user/img/about/about_bg_2.jpg) 0 0 repeat fixed;
         background-size: 100%;
         @media all and (max-width: 1024px) {
             background-size: cover;
         }
     }
     &.about_bg_3 {
         background: transparent url(/user/img/about/about_bg_3.jpg) 0 0 repeat fixed;
         background-size: 100%;
         @media all and (max-width: 1024px) {
             background-size: cover;
         }
     }
     .ajab-wrapper {
         margin: 0 auto 0 0 !important;
     }
 }
 
 .about-wrapper {
     min-height: 1000px;
     margin: 0 auto;
     display: block;
     position: relative;
     background-size: cover;
     font-family: $font-stack;
     @media screen and (max-width: 1280px) {
         width: 100%;
     }
     .ajab-wrapper {
         @media screen and (max-width: 1024px) {
             width: 100%;
         }
     }
     .leftMenu {
         height: 480px;
         width: 66%;
         overflow-y: auto;
         line-height: 1.5em;
         @media all and (max-width: 1280px) {
             width: 100%;
         }
         @media all and (max-width: 1024px) {
             width: 200px;
             height: auto;
         }
         // @media screen and (max-width: 980px) {
         //     width: 45%;
         // }
         // @media screen and (max-width: 880px) {
         //     width: 40%;
         // }
         // @media screen and (max-width: 800px) {
         //     width: 35%;
         // }
     }
     .page-header {
         float: left;
         margin-bottom: 20px;
         margin-left: 15px;
     }
     .active {
         text-decoration: underline;
     }
     .sidebar-container {
         position: fixed;
         @media screen and (max-width: 1280px) {
             width: 200px;
         }
     }
     .spacing {
         height: 100px;
     }
     .ajab-wrapper {
         .logo-container {
             margin-bottom: 20px;
             a {
                 cursor: default!important;
                 text-align: center !important;
                 color: #4D4D4D !important;
             }
         }
     }
     .about-container {
         padding: 135px 50px 50px 50px;
         @media screen and (max-width: 1280px) {
             padding: 135px 20px 50px 20px;
         }
     }
     .about-content-parda {
         width: 69%;
         margin: 0 auto;
         position: relative;
         @media screen and (max-width: 1280px) {
             width: calc(100% - 250px);
             margin-left: 250px;
         }
         @media screen and (max-width: 1180px) {
             width: 80%;
             margin: 0;
             float: right;
         }
         @media all and (max-width: 1024px) {
             width: calc(100% - 200px);
         }
     }
     .about-parda-width {
         background: transparent url('/user/img/people/all-people-content-bg.png') left top;
         background-size: 100% auto;
         min-height: 1200px;
         width: 100%;
         padding-bottom: 150px;
     }
     /*  &:before, &:after {
    content: '';
    display: inline-block;
    background: transparent url(/user/img/mainLandingPage/parallax/side_pattern.png) 0 0;
    width: 45px;
    background-repeat: no-repeat repeat;
    position: absolute;
    top: 0;
    height: 100%;

    @media screen and (max-width: 1280px) {
      display: none;
    }
  }
  &:before {
    left: 0px;
  }
  &:after {
    right: 0;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }*/
     .about-content-block {
         font-family: "Merriweather";
         color: $dark-grey;
         font-size: $medium;
         & .about-nav {
             width: 20%;
             float: left;
             text-align: left;
             & h4 {
                 margin-top: 10px;
                 margin-bottom: 10px;
                 margin-left: 15px;
                 color: $pink;
                 font-size: $medium;
                 font-weight: bold;
                 &.active {
                     color: $dark-grey;
                 }
             }
             & ul {
                 padding-left: 15px;
                 & li {
                     font-size: 14px;
                     margin-top: 5px;
                     margin-bottom: 5px;
                     list-style: none;
                     &:before {}
                     a {
                         word-break: break-word;
                     }
                 }
             }
         }
         .separator {
             height: 6px;
             background: transparent url('/user/img/songs/sep2.png');
             background-size: 300px 10px;
             background-position: left bottom;
             background-repeat: no-repeat;
             width: 150px;
             padding: 0px 32px;
             padding-bottom: 5px;
             padding-left: 30px;
             margin-left: -5px;
         }
         & .about-content {
             margin-right: 20px;
             width: 62%;
             float: left;
             text-align: left;
             padding-bottom: 150px;
             @media all and (max-width: 1024px) {
                 padding-right: 20px;
             }
             @media screen and (max-width: 990px) {
                 width: 52%;
                 margin-right: 1%;
             }
             .about-header-name {
                 font-size: $extra-large;
                 margin-bottom: 20px
             }
             @media screen and (max-width: 990px) {
                 margin-right: 0;
             }
             & h3 {
                 padding-top: 15px;
                 padding-bottom: 15px;
                 color: $color-heading;
                 font-size: $large;
             }
             & p {
                 font-size: 14px;
                 line-height: 1.8em;
                 /* &:first-of-type:first-letter {
                     font-size: 24px;
                     font-weight: 200;
                 }*/
             }
         }
         & .about-image {
             width: 35%;
             float: right;
             font-family: "Merriweather";
             &:after {
                 clear: both;
             }
             .image-block {
                 margin-bottom: 0px;
                 width: 255px;
                 float: right;
                 img {
                     width: 100%;
                     border: 3px #FFF solid;
                     box-shadow: $light-grey 2px 2px 10px;
                     height: 140px;
                     margin-bottom: 0px;
                 }
                 .image-caption {
                     line-height: 16px;
                     margin-bottom: 10px !important;
                     margin-top: 2px !important;
                     text-align: left;
                     color: $grey;
                     font-size: 12px !important;
                     font-family: 'Merriweather Sans';
                     word-break: break-word;
                     font-weight:300;
                 }
             }
         }
         & .clearfix {
             clear: both;
         }
     }
 }
