#classroom_page {
    width: 100%;
    background: transparent url(/user/img/classroom/bg.jpg) 0 0 repeat fixed;
    .clearfix {
        clear: both;
    }
    .classroom-logo {
        background: transparent url(/user/img/classroom/classroom-main.png);
        width: 96px;
        height: 91px;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
    }
    #contributeBubbleClick a {
        cursor: pointer;
        display: block;
    }
    .thumbnail .image img {
        box-shadow: none;
    }
    .ideasShow {
        display: none;
    }
    .logo-with-filter {
        z-index: 80;
        position: relative;
    }
    #container {
        // display: flex;
        // flex-wrap: wrap;
        // flex-direction: column;
        // width: 100%;
        .duration {
            font-size: 12px;
        }
        .media {
            min-height: 0px;
            width: 33.333333%;
            margin-bottom: 48px;
            float: left;
            @include media($tab-landscape) {
                width: 33.333333%;
            }
            @include media($tab-portrait) {
                width: 50%;
            }
            .bubble {
                position: relative;
                top: -4px;
                left: -10px;
            }
        }
    }
    .category-logo {
        padding-top: 34px !important;
        color: #4d4d4d !important;
        cursor: default !important;
        font-size: 18px !important;
        margin-left: -2px;
        font-family: 'Merriweather';
        a {
            cursor: default !important;
            padding: 4px;
            border-radius: 30px;
            background-color: #FFF;
            margin-left: -5px;
        }
    }
    .emptySpace {
        height: 200px;
    }
    .classroomContent {
        width: 900px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        text-align: center;
        @media all and (max-width: 1024px) {
            width: 100%;
        }
    }
    .classroomBubble {
        max-width: 100%;
    }
    .thumbnailBlock {
        margin-top: 30px;
        min-height: 400px;
        @include clearfix;
        @media all and (max-width: 1024px) {
            padding-left: 30px;
            padding-right: 30px;
            width: 100%;
        }
        .ideaDesktop{
          position: absolute;
          left: 555px;
          top: 0px;
          opacity: 1;
        }

        .main-class-divs1{
          position: absolute;
          left: 0px;
          top: 0px;
          opacity: 1;

          @media (max-width: 900px){
            width: 50%;
          }
        }
        .main-class-divs2{
          position: absolute;
          left: 297px;
          top: 0px;
          opacity: 1;
        }
        .idea-classroom{
          .cartoonRandom {
            left: 0px;
            right: 0px;
          }
          .thirdIdeaDiv{
            right: 0px;
            left: auto;
            .thumbnail{
              width: 250px;
              margin: auto;
            }
          }
            .cartoonRandom{
                @media (max-width: 900px){
                  left: auto;
                  right: 0px;
                }
            }
            @media (max-width: 900px){
              .ideas-first-two-divs{
                width: 50% !important;
              }
            }
        }

        .idea-sub-thubnails{
          .equal-ipad-divs{
            @media (max-width: 900px){
              width: 50% !important;
            }
          }
        }

    }
    .contributeBubble {
        margin-top: 30px;
    }

    .thumbnailClassroomTitle {
        border-bottom: 1px #CCC solid;
        padding-bottom: 10px;
        width: calc(100% - 50px);
        margin: 10px auto 20px auto;
        @media all and (max-width: 1024px) {
            width: 100%;
        }
        h1 {
            float: left;
            font-size: $double-x-large;
            margin: 0;
            font-family: 'Gloria Hallelujah', cursive;
            color: $light-grey;
        }
        .see-all {
            font-size: $small;
            color: $pink;
            float: right;
            margin-top: 20px;
            &:hover {
                text-decoration: underline;
            }
            @include clearfix;
        }
    }
    .contribute-bubble-img-wrapper {
        position: relative;
        height: 170px;
    }
    .contribute-bubble-img-wrapper .contributeBubble {
        position: absolute;
        top: 65px;
        width: 472px;
        left: -50%;
        margin-left: calc(100% - 236px);
        margin-top: 0px;
    }
    .thumbnail .bubble .summary:after {
        background: url(/user/img/common/directives/thumbnail/bottom-bubble_noglow.png) scroll repeat-y top left;

    }
    .thumbnail .bubble {
        background: url(/user/img/common/directives/thumbnail/center-bubble_noglow.png) scroll repeat-y top left;

    }
    // .thumbnail .bubble .summary:before {
    //     width: 250px;
    //     height: 19px;
    //     left: 0px;
    //     top: -18px;
    //     background: url(/user/img/common/directives/thumbnail/top-bubble_noglow.png);

    //     // @media all and (ma) {

    //     // }
    // }
}
#idea-block {
  .thumbnail {
    width: 250px;
  }
}
#experiment-block{
  .exp-second-div{
    left: 325px !important;
    @media (max-width: 900px){
      left: auto !important;
      right: 0px;

      .thumbnail {
        width: 250px;
      }
    }

  }
  .media {
    @media (max-width: 900px){
        width: 50% !important;
    }
  }
  .third-experiment-image{
    left: auto;
    right: 0px;
    width: 300px;
    position: absolute;
    .experiment-img-div{
      width: 250px;
      img{
        float: right;
      }
    }
  }
  .thumbnail {
    width: 250px;
  }
}
#reflection-block{
  .reflection-div1{
     position: absolute;
     left: 0px;
     top: 0px;
     opacity: 1;
  }
  .cartoonRandom{
    min-height: 0px;
    width: 33.333333%;
    margin-bottom: 48px;
    float: left;
  }
  .reflection-div2{
    position: absolute;
    left: 325px;
    top: 0px;
    opacity: 1;
    @media (max-width: 900px){
      right: 0px;
      top: 0px;
      left: auto !important;
      width: 50% !important;
    }
  }
  .reflection-div3{
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 1;
    .thumbnail{
      width: 250px;
    }
  }
  @media (max-width: 900px){
    .media{
      width: 50% !important;
    }
  }
  .thumbnail {
    width: 250px;
  }
}
.classroomContent .summary .title span{
  line-height: 20px;
}
