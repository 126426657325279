$pink: #E61B72;
$light-grey: #b3b3b3;
$dark-grey: #4D4D4D;
$clickable-grey:#808080;
$color-primary: #E83785;
$grey: #808080;
// Text Colors

$color-heading: #333333;
$color-text: #666666;
$color-menu: #7F8080;
$color-link-active: #7bd0ca;

// Others

$color-icon: #B3B3B3;
$color-border: #C7C6C5;


/*** According to new color scheme ***/
$color-link: $pink;
$color-sub-link: $clickable-grey;
$color-hover-link: $pink;

$color-regular-text: #999;
$color-highlight-text: #4d4d4d;
$color-regular-text-light: #b3b3b3;

$color-active-link: #4d4d4d;
$color-disabled-link: #b3b3b3;
$color-resource-label:#989898;
