@mixin border-bottom-dotted {
    padding: 5px;
    border-bottom: 1px dotted $light-grey;
}

#search_page {
    width: 100%;
    min-height: 800px;
    background: transparent url(/user/img/search/search_bg.png) 0 0 repeat fixed;
    background-size: contain;
    
    
    
  .ajab-wrapper {
    width: 90%;
    padding: 0;
    display: block;
    margin: 0 auto;
      .logo-container {
          position: fixed;
          top: 30px;
          left: 2.5%;

      @media screen and (max-width: 768px) {
            left: 0.5%;
          }
        & > a {
            font-size: 15px;
            color: $dark-grey;
          }
        }
  }
    
    .searchWrapper {
        margin-top: 90px;
        color: $dark-grey;
        text-align: left;
        
        .searchVideo {
                float: left;
                
                img {
                    margin-right: 10px;
                    width: 200px;
                    height: 125px;
                    border: 1px #FFF solid;
                }
            }
         
        .videoResults {
            float: left;
        }
        
        .searchResultsMessage {
            font-size: 14px;
            @include border-bottom-dotted;
            .searchKeyword {
                font-family: 'Merriweather';
                font-size:  $double-x-large;
            }
        }
        
        .searchResultsBlock {
            font-family: 'Merriweather';
            line-height: 1em;
            @include border-bottom-dotted;
            @include clearfix;
            
            
            .searchInfoTitle {
                font-size: $extra-small;
                text-transform: uppercase;
                color: $dark-grey;
            }
            
            .searchTitle {
                color: $pink;
                font-size: $medium;
                margin-bottom: 5px;
            }
            
            .searchMessage {
                font-size: $small;
                margin-bottom: 5px;
            }
            
            .searchLink {
                color: $pink;
                font-size: $small;
                text-transform: uppercase;
            }
            
        }
    }
    
}