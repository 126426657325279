::-webkit-scrollbar{
    -webkit-appearance: none;
    width: 6px;
}

::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
}
.couplets {
    background: transparent url("/user/img/couplets/couplets_dashboard_bg.jpg") repeat;
    background-size: cover;
    height: 100%;
    margin: 0;
    @media(max-width: 1024px) {
        background-position: top;
    }
    @media all and (max-width: 992px) {
        background: none;
        // background: transparent url(/user/img/couplets/Couplet_background-06.jpg) repeat;
    }
    @media all and (max-width: 1250px) and (min-width: 1025px){
      background-position: 50%;
    }
    @media(min-width: 768px) and (max-width: 800px) {
        //sbackground-size: 100% 100%;
    }

    .ajab-wrapper {
        position: relative;
    }

    .filter-btn {
        .filter-button {
            display: block;
            margin-top: 7px;
            font-size: $small;
            text-transform: uppercase;
            color: $color-link;
            margin-left: 10px;

            & > .collapse {
                background: transparent url('/user/img/common/icons/minus.png') 0 0 no-repeat;
                display: inline-block;
                height: 9px;
                width: 9px;

                &.expand {
                    background-image: url('/user/img/common/icons/cross.png');
                }
            }
        }
    }

    .coupletsScroll {
        height: 200px;
    }

    .couplets_dynamic_content {
        width: 600px;
        max-height: 150px;
        @media(max-width: 992px) {
            width: 100%;
        }
    }

    .mt {
        margin-top: 2px;
    }

    .staticParda {
        width: 250px;
        background: transparent url('/user/img/couplets/staticParda.png') right -5px repeat-y;
        background-size: cover;
        height: 100%;
        position: fixed;
        padding-left: 10px;
        z-index: 1;

        & .toggle {
            display: inline-block;
            padding-top: 5px;
            width: 15em;
            background-size: contain;
            background-position: -2em 0;

            li {
                float: left;
                text-transform: uppercase;
                font-size: 12px;
                font-family: 'Merriweather Sans';
                margin-bottom: 5px;
                color: $pink;
                padding-left: 12px;
                padding-right: 12px;
                border-right: 1px $light-grey solid;

                .active {
                    color: $grey;
                    text-decoration: underline;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    border: 0;
                }
            }
        }

        li {
            font-size: 12px;
            color: $dark-grey;
            cursor: pointer;
            margin-bottom: 20px;
            font-family: 'Merriweather';

            &:hover {
                color: $pink;
            }
        }
    }

    .border-parda {
        height: 13px;
        background: transparent url('/user/img/songs/underline_curved.png') no-repeat;
        width: 14em;
        background-size: contain;
        background-position: -1em 0;
    }

    .head {
        margin-top: 170px;
    }

    .logo-with-filter {
        margin-left: 4% !important;
    }

    .filterBtn {
        color: $pink;
        margin-bottom: 20px;
        padding-bottom: 10px;
        font-size: 14px;
    }

    .line-seperator {
        background: transparent url('/user/img/couplets/underline_curved.png') no-repeat;
        height: 14px;
        margin-top: -10px;
        width: 15em;
        background-size: cover;
        background-position: -2em 0;
        position: absolute;
        margin-left: -45px;
    }

    .scrollFilter {
        width: 91%;
        height: 65%;
        margin-top: 10px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 2px;

        .active {
            color: $pink;
        }
    }

    .scrollWidth {
        width: 200px;
        padding-right: 5px;
    }

    .left-nav {
        width: 70px;
        left: 0;
        top: -5px;
        position: absolute;
        display: inline-block;
        text-align: left;
        -webkit-transition: all 0.3s linear -0.1s;
        transition: all 0.3s linear -0.1s;
        margin-top: 185px;

        & ul {
            margin-left: -15px;

            & li {
                background: transparent url('/user/img/songs/sep2.png');
                background-size: 300px 10px;
                background-position: left bottom;
                background-repeat: no-repeat;
                width: 85px;
                padding: 0 32px 5px 15px;

                & a {
                    display: block;
                    font-weight: 300;
                    font-family: 'Merriweather';
                    color: #e61b72;
                    font-size: 14px;
                    height: 27px;
                    margin-top: 8px;
                    margin-bottom: 3px;
                }

                & a.active {
                    cursor: default;
                    color: #4d4d4d;
                    font-weight: 300;
                }
            }
        }
    }

    .slide-nav-list {
        .translation-list {
            width: 220px;
            margin-top: 150px;
            font-size: 12px;
            margin-left: 10px;
            margin-bottom: 10px;
            color: $grey;
            padding: 10px;
            background: transparent url('/user/img/songs/underline_curved.png') no-repeat;
            background-size: contain;
            background-position: 0 21px;
            font-size: 10px;

            .coupletsCount {
                font-size: $extra-small;
                float: right;
            }

            .toggle {
                font-size: $extra-small;
                text-transform: uppercase;
                width: 125px;
                float: left;

                li {
                    float: left;

                    &:first-child {
                        & > span {
                            padding-left: 0;
                        }
                    }

                    &:last-child {
                        & > span {
                            border: none;
                        }
                    }

                    & > span {
                        float: left;
                        border-right: solid 1px #575756;
                        padding: 0 8px;
                        line-height: 12px;
                        cursor: pointer;
                        color: $color-link;
                        cursor: pointer;
                        text-transform: uppercase;
                    }

                    & > .active {
                        cursor: default;
                        color: $color-active-link;
                    }
                }
            }
        }

        .filters {
            margin-right: 20px !important;
            height: 380px;
            margin-top: 0 !important;

            li {
                a {
                    color: $grey !important;

                    .active {
                        color: $pink !important;
                    }
                }
            }
        }

        .reset {
            &:hover {
                color: $pink;
            }
        }

        .note-text {
            font-size: 12px;
            padding-left: 10px;
            padding-right: 30px;
            line-height: 1.5em;
            color: $grey;
            margin-left: 20px;
            position: absolute;
            bottom: 20px;
            @media all and (max-width: 1024px) {
                bottom: 60px;
            }
        }

        .filterBy {
            margin-right: 20px !important;
            height: 450px;
            margin-top: 0 !important;
            margin-left: 30px !important;

            li {
                a {
                    font-size: 12px !important;
                    color: $grey !important;

                    .active {
                        color: $pink !important;
                    }
                }
            }
        }
    }

    .category-logo {
        padding-top: 33px !important;
        color: #4d4d4d !important;
        cursor: default !important;
        font-size: 18px !important;

        a {
            cursor: default !important;
            padding: 4px;
            border-radius: 30px;
            background-color: #FFF;
            opacity: 0.85;
        }
    }

    .coupletsCount {
        font-size: 10px;
        color: $light-grey;
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: uppercase;
    }

    .coupletsToggle {
        color: $pink;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 12px;
    }

    .link {
        color: $pink;
        cursor: pointer;
    }
    ::-webkit-scrollbar{
        -webkit-appearance: none;
        width: 6px;
    }

    ::-webkit-scrollbar-thumb{
        border-radius: 8px;
        background-color: rgba(0, 0, 0, .5);
    }

    .couplets_content_wrap {
        margin-left: 11.5%;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        @media(max-width: 1250px) {
            width: calc(100% - 230px) !important;
            float: right;
            margin: 0 !important;
          }
            @media all and (max-width: 992px) {
                width: calc(100% - 213px) !important;
                margin-right: -10px !important;
                background: transparent url("/user/img/couplets/Couplet_background-06.jpg") 10% repeat;
                background-size: cover;
            }
            @media all and (max-width: 780px) {
                background-size: contain;
            }

            // @media all and (max-width: 1250px) and (max-width: 1025px){
            //   width: calc(100% - 230px) !important;
            //
            // }



        .couplets_content {
            font-weight: 200;
            height: 150px;
            width: 600px;

            @media all and (max-width: 992px) {
                width: 100%;
            }
            .couplets_scroll::-webkit-scrollbar{
                -webkit-appearance: none;
                width: 6px;
            }

            .couplets_scroll::-webkit-scrollbar-thumb{
                border-radius: 8px;
                background-color: rgba(0, 0, 0, .5);
            }

            .couplets_scroll {
                overflow-x: hidden;
                overflow-y: auto;
                height: 185px;
                margin-top: 5px;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                @media (max-width: 992px) {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            & h1,
            & h2,
            & h3,
            & h4,
            & h5,
            & h6 {
                font-weight: 100 !important;
                color: #4D4D4D !important;
            }

            & h1 {
                font-size: $double-x-large !important;
                padding: 0 !important;
                margin-bottom: 5px;
                margin-top: 5px;

                span {
                    font-size: inherit;
                }
            }

            & h2 {
                font-size: $extra-large !important;
                padding: 0 !important;
                margin-bottom: 5px;
                margin-top: 5px;

                span {
                    font-size: inherit;
                }
            }

            & h3 {
                font-size: $large !important;
                padding: 0 !important;
                margin-bottom: 5px;
                margin-top: 5px;

                span {
                    font-size: inherit;
                }
            }

            & h4 {
                font-size: $medium-large !important;
                padding: 0 !important;
                margin-bottom: 5px;
                margin-top: 5px;

                span {
                    font-size: inherit;
                }
            }

            & h5 {
                font-size: $small !important;
                padding: 0 !important;
                margin-bottom: 5px;
                margin-top: 5px;

                span {
                    font-size: inherit;
                }
            }

            & h6 {
                font-size: $extra-small !important;
                padding: 0 !important;
                margin-bottom: 5px;
                margin-top: 5px;

                span {
                    font-size: inherit;
                }
            }

            & p {
                font-size: 15px;
                font-family: 'Merriweather';
                line-height: 1.5em;
                color: $dark-grey;
                width: 550px;
                margin-left: auto;
                margin-right: auto;
                text-align: center !important;
                @media all and (max-width: 992px) {
                    width: 100%;
                }
            }

            & span {
                font-size: inherit;

                & .link {
                    font-size: 12px;
                    color: $pink;
                }
            }
        }
    }

    .couplets_explorer_text {
        position: relative;
        top: 80px;
        margin-top: 25px;

        span {
            font-size: 12px;
            color: $dark-grey;
        }

        a {
            text-transform: uppercase;
            color: $pink;
            font-size: 14px;
            font-weight: bold;

            &:hover {
                color: $dark-grey;
            }
        }
    }

    .lyrics-footer {
        background: transparent url("/user/img/songs/song_bottom.png") 0 0 no-repeat;
        height: 100px;
        width: 100%;
        margin-bottom: 100px;
        display: inline-block;
        position: relative;

        ul {
            margin: 20px auto 0;
            display: inline-block;
            width: auto;

            & > li {
                float: left;
                margin-left: 30px;

                &:first-child {
                    margin-left: 0;
                }

                & > a {
                    float: left;
                    width: 36px;
                    height: 36px;
                    background: transparent url("/user/img/common/icons/sprite_icons.png") 0 0 no-repeat;

                    &.active {
                        cursor: default;
                    }
                }

                &.original {
                    & > a {
                        width: 39px;
                        height: 39px;
                        background-position: -84px -200px;

                        &.active,
                        &:hover {
                            width: 39px;
                            height: 39px;
                            background-position: -153px -131px;
                        }

                        &.disabled {
                            width: 39px;
                            height: 39px;
                            background-position: -15px -200px;
                        }
                    }
                }

                &.transliteration {
                    & > a {
                        width: 39px;
                        height: 40px;
                        background-position: -15px -270px;

                        &.active,
                        &:hover {
                            width: 39px;
                            height: 40px;
                            background-position: -289px -60px;
                        }

                        &.disabled {
                            width: 39px;
                            height: 40px;
                            background-position: -291px -200px;
                        }
                    }
                }

                &.translation-icons {
                    text-align: center;

                    & > a {
                        width: 39px;
                        height: 39px;
                        background-position: -84px -131px;

                        &.active,
                        &:hover {
                            width: 39px;
                            height: 39px;
                            background-position: -111px -62px;
                        }

                        &.disabled {
                            width: 39px;
                            height: 39px;
                            background-position: -15px -131px;
                        }
                    }
                }
            }
        }

        .about-link {
            position: absolute;
            left: 30px;
            bottom: 55px;
            font-size: $medium;
            color: $grey;
            font-weight: 300;

            & > a {
                text-transform: uppercase;
                color: $pink;

                &:hover {
                    color: $dark-grey;
                }
            }
        }

        .glossary-link {
            position: absolute;
            right: 30px;
            bottom: 55px;
            font-size: $medium;
            color: $grey;
            font-weight: 300;

            & > a {
                text-transform: uppercase;
                color: $pink;

                &:hover {
                    color: $dark-grey;
                }
            }
        }

        .play-icon {
            position: absolute;
            right: 13%;
            bottom: 40px;
            background: transparent url("/user/img/songs/songs_sprite.png") -52px 0 no-repeat;
            width: 50px;
            height: 102px;
            display: inline-block;
        }
    }
}
