/* Animation */

@include keyframes(rotation) {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@include keyframes(antirotation) {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@include keyframes(moveUp) {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 0 -4277px;
    }
}

@include keyframes(moveDown) {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 0 6400px;
    }
}

.featured-songs {
    .song-parallax-container {
        width: 1140px;
        position: relative;
        z-index: $zIndex-parallax-songs;
        margin: 0 auto;
        @media screen and (max-width: 1024px) {
            width: 1024px;
        }
        @media screen and (max-width: 768px) {
            width: 768px;
        }
        .pxSongLayers {
            width: 100%;
            top: 0;
            left: 0;
            height: 4000px;
            position: absolute;
        }
        .px-song-layer {
            &:before {
                float: left;
            }
            &:after {
                float: right;
                @include transform(rotate(180deg));
            }
            &1 {
                background: url(/user/img/songs/parallax/background_image.jpg) 0px 0px;
                /* @include retina-image("/user/img/songs/parallax/background_image", 1140px auto); */
                background-repeat: no-repeat repeat;
            }
            &2 {
                background: url(/user/img/songs/parallax/white_tambura.png) 0 0 no-repeat;
                width: 145px;
                @include animation(antirotation 100s linear -5s infinite);
                @include transform-origin(30% 20%);
                height: 495px;
                top: 560px;
                left: 12%;
                &.tambura-small-1 {
                    background: url(/user/img/songs/parallax/tambura_small_white.png) 0 0 no-repeat;
                    top: 1697px;
                    width: 190px;
                    left: 12%;
                    opacity: 0.8;
                    background: url(/user/img/songs/parallax/white_tambura.png) 0 0 no-repeat;
                    height: 500px;
                    @include animation(antirotation 100s linear 2s infinite);
                }
            }
            &3 {
                background: url(/user/img/songs/parallax/tambura_small.png) 0px 0px no-repeat;
                opacity: 0.1;
                top: 1000px;
                left: 70%;
                @include transform-origin(30% 20%);
                @include animation(rotation 60s linear 3s infinite);
                width: 190px;
                height: 600px;
                &.tambura-small-1 {
                    left: 67%;
                    top: 2000px;
                    @include animation(rotation 60s linear 1s infinite);
                    opacity: 0.15;
                }
            }
            &4 {
                background: url(/user/img/songs/parallax/triangles_new.png) 0px 0px;
                opacity: 0.4;
                background-size: contain;
            }
            &5 {
                background: url(/user/img/songs/parallax/viberations_new.png) 0px 0px repeat;
                opacity: 0.12;
                left: -4%;
                height: 4277px;
                background-size: contain;
                width: 100%;
                @include animation(moveUp 200s linear infinite);
                &.top-to-bottom {
                    top: -2000px;
                    opacity: 0.15;
                    height: 6400px;
                    @include animation(moveDown 500s linear infinite);
                }
            }
            &6 {
                background: url(/user/img/songs/parallax/ingla.png) 0px 0px;
                background-repeat: no-repeat repeat;
                background-size: contain;
                width: 53px;
                left: 22%;
                @media screen and (max-width: 768px) {
                    left: 25%;
                }
            }
            &7 {
                background: url(/user/img/songs/parallax/pingla.png) 0px 0px;
                background-repeat: no-repeat repeat;
                left: 68%;
                width: 112px;
                background-size: contain;
                @media screen and (max-width: 768px) {
                    left: 65%;
                }
            }
            &8 {
                background: url(/user/img/songs/parallax/sushamna.png) 0 0;
                background-repeat: no-repeat repeat;
                background-size: contain;
                left: 44%;
                width: 144px;
                @media screen and (max-width: 768px) {
                    left: 42%;
                }
            }
            &9 {
                background: url(/user/img/songs/parallax/snake.png) 0px 0 no-repeat;
                top: 2244px;
                left: 40%;
                opacity: 0.5;
                @media screen and (max-width: 768px) {
                    left: 36%;
                }
            }
            &11 {
                &:before,
                &:after {
                    content: '';
                    display: inline-block;
                    background: transparent url(/user/img/mainLandingPage/parallax/side_pattern.png) 0 0;
                    width: 30px;
                    background-repeat: no-repeat repeat;
                    height: 100%;
                    @media screen and (max-width: 1140px) {
                        display: none;
                    }
                }
                &:before {
                    float: left;
                }
                &:after {
                    float: right;
                    @include transform(rotate(180deg));
                }
            }
        }
    }
    .shift {
        &1 {
            top: -3%;
            left: 10%;
            position: absolute;
            @media screen and (max-width: 1024px) {
                left: 3%;
                top: 0%;
            }
        }
        &2 {
            left: 60%;
            position: absolute;
            top: 3%;
        }
        &3 {
            left: 33%;
            top: 13%;
            position: absolute;
            @media screen and (max-width: 1024px) {
                left: 27%;
                top: 20%;
            }
        }
        &4 {
            top: 24%;
            left: 10%;
            position: absolute;
            @media screen and (max-width: 1024px) {
                left: 2%;
                top: 37%;
            }
        }
        &5 {
            left: 39%;
            top: 35%;
            position: absolute;
            @media all and (max-width: 1024px) {
              top:54%;
            }
        }
        &6 {
            left: 59%;
            top: 50%;
            position: absolute;
            @media screen and (max-width: 1024px) {
                top: 40%;
            }
        }
        &7 {
            left: 12%;
            top: 44%;
            position: absolute;
            @media screen and (max-width: 1024px) {
                left: -2%;
                top: 68%;
            }
        }
        &8 {
            left: 33%;
            top: 59%;
            position: absolute;
            @media screen and (max-width: 1024px) {
                left: 30%;
                    top: 83%;
            }
        }
        &9 {
            left: 10%;
            top: 71%;
            position: absolute;
            @media screen and (max-width: 1024px) {
                left: 60%;
                top: 68%;
            }
        }
    }
}
