.details {
    background: transparent url(/user/img/songs/song_detail_bg_tiledY.png) left top;
    background-repeat: no-repeat repeat;
    background-size: 100% auto;
    .page-header {
        z-index: $zIndex-detail-page-header;
        .logo-container {
            @media all and (max-width: 1024px) {
                z-index: 9999;
            }
        }
    }
    .no-pardah {
        background: none;
        min-height: 120px !important;
        @media all and (max-width: 1024px) {
            min-height: 0px !important;
        }
    }
    .author-name {
        color: $pink;
        font-weight: 300;
        font-size: $medium;
        text-transform: uppercase;
        font-family: 'Merriweather Sans';
    }
    .verb {
        color: $grey;
        font-size: $medium;
        font-weight: 300;
        text-transform: lowercase;
        font-family: 'Merriweather Sans';
    }
    .main-content {
        margin-top: 50px;
        min-height: 500px;
        position: relative;
        padding-bottom: 15em;
        @media all and (max-width: 1024px) {
            margin-top: 0px !important;
        }
        .detail-text-wrapper {
            width: 80%;
            margin: 0 auto;
            position: relative;
            padding-left: 50px;
            padding-top: 20px;
            padding-right: 50px;
            padding-bottom: 50px;
            .header-text {
                font-size: $medium;
                margin-left: 1px;
                text-align: left;
                color: $dark-grey;
                padding-top: 15px;
                font-weight: 300;
                a {
                    color: $pink;
                }
                & > p {
                    line-height: 24px;
                }
            }
            .text-content {
                padding-bottom: 50px;
            }
            #audio_video_container {
                margin-top: 1em;
            }
            .text-top-background {
                background: transparent url(/user/img/words/one.png) left top;
                background-repeat: no-repeat no-repeat;
                background-size: 100% 100%;
                height: 130px;
            }
            .text-middle-background {
                background: transparent url(/user/img/words/two.png) left bottom;
                background-repeat: no-repeat repeat;
                background-size: 100% 100%;
                width: 100%;
                position: relative;
                .detail-content {
                    position: relative;
                    top: -100px;
                    width: 90%;
                    margin: 0 auto;
                    .share-title {
                        font-size: 30px;
                        margin-right: 5px;
                        color: #4d4d4d;
                    }
                    .english-title {
                        font-size: 28px;
                        color: #808080;
                        font-style: italic;
                    }
                }
            }
        }
        .detail-text-wrapper-bottom-image {
            background: transparent url(/user/img/words/three.png) left 0px no-repeat;
            height: 80px;
            width: 100%;
            position: relative;
            margin: 0 auto;
            background-size: 100% 100%;
        }
    }
    #audio_video_container {
        clear: both;
        width: 853px;
        margin: 0 auto;
        display: inline-block;
        @media screen and (max-width: 1280px) {
            width:100%;
        }
        @media screen and (max-width: 992px) {
            width:100%;
        }
        youtube {
            width: 853px;
            display: inline-block;
            height: 480px;
            @media screen and (max-width: 1280px) {
                width: 100%;
                /*height: 360px;*/
            }
            @media screen and (max-width: 1024px) {
                width: 100%;
                height: 360px;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                height: 315px;
            }
            iframe {
                @include box-shadow(10px, #777);
                border: solid 5px #fff;
                width: inherit;
                height: inherit;
            }
        }
        .about-link {
            font-weight: 500;
            color: $pink;
            font-family: 'Merriweather';
            font-size: $medium-large;
            &:hover {
                color: $dark-grey;
            }
            &.active {
                color: $dark-grey;
                cursor: pointer;
            }
        }
        .about-info {
            text-align: left;
            display: inline-block;
            max-height: 260px;
            margin: 15px 0;
            color: #666;
            font-size: 12px;
            font-family: 'Merriweather';
            font-weight: 300;
            overflow: auto;
            line-height: 21px;
            width:100%;
            a {
                color: $pink;
            }
            p {
                width: 98%;
            }

        }
        /* video or audio container*/
        .video-options {
            width: 100%;
            display: inline-block;
            & > ul {
                text-align: left;
                float: left;
                position: relative;
                font-size: 16px;
                width: auto;
                & > li {
                    float: left;
                    padding: 10px 5px;
                    color: #666;
                    font-weight: 200;
                    line-height: 20px;
                    padding: 10px 5px;
                }
                &:first-of-type {
                    & > li:first-of-type {
                        padding-left: 0 !important;
                        padding-top: 8px !important;
                    }
                }
            }
            ul.share-options {
                li {
                    padding: 10px;
                    a {
                        background: transparent url(/user/img/common/icons/sprite_icons.png) 0 0 no-repeat;
                        display: inline-block;
                        width: 26px;
                        height: 23px;
                        &.favorite {
                            background: transparent url(/user/img/songs/songs_sprite.png) 0 0 no-repeat;
                            background-position: 0 -25px;
                            height: 22px;
                        }
                        &.share {
                            width: 16px;
                            height: 15px;
                            background-position: -285px -15px;
                            &.active {
                                width: 16px;
                                height: 15px;
                                background-position: -199px -241px;
                                cursor: default;
                            }
                        }
                        &.comment {
                            background-position: -87px -280px;
                            width: 21px;
                            height: 16px;
                            &:hover {
                                background-position: -228px -280px;
                            }
                        }
                        &.comment-active {
                            background-position: -228px -280px !important;
                        }
                        &.download {
                            width: 18px;
                            height: 17px;
                            background-position: -189px -15px;
                            &.active {
                                width: 18px;
                                height: 17px;
                                background-position: -15px -62px;
                                cursor: default;
                            }
                        }
                    }
                }
            }
            ul.audio-video-options {
                float: right;
                & > li {
                    border-right: solid 1px #666;
                    line-height: 2px;
                    margin: 7px 0 0;
                    padding: 1px 7px;
                    &:last-child {
                        border: none;
                        padding-right: 0;
                    }
                    & > a {
                        /*background: transparent url('/user/img/songs/av_sprite.png') 0 0 no-repeat;
                width: 12px;
                height: 15px;
                display: inline-block;*/
                        background-image: url(/user/img/common/icons/sprite_icons.png);
                        background-repeat: no-repeat;
                        display: block;
                        &.active {
                            cursor: default;
                        }
                        &.video-icon {
                            width: 10px;
                            height: 12px;
                            background-position: -233px -200px;
                            &:hover,
                            &.active {
                                width: 10px;
                                height: 12px;
                                background-position: -249px -62px;
                            }
                        }
                        &.audio-icon {
                            width: 14px;
                            height: 22px;
                            background-position: -145px -16px;
                            &:hover,
                            &.active {
                                width: 14px;
                                height: 17px;
                                background-position: -15px -16px;
                            }
                        }
                    }
                }
            }
        }
    }
    /* Pardah links */
    .related-links-parda {
        width: auto;
        padding: 45px 0 40px 15px;
        background: transparent url('/user/img/songs/relatedlinks_parda.png') 0 0 no-repeat;
        background-size: 100% 100%;
        dl {
            margin: 4px auto;
            clear: both;
            line-height: 23px;
            text-align: center;
            display: block;
            .italicise {
                font-style: italic;
            }
            dt {
                color: $color-sub-link;
                text-transform: capitalize;
                font-weight: 300;
                display: inline-block;
                font-size: $medium;
                font-family: 'merriweather';
                a {
                    color: $color-sub-link;
                    &:hover {
                        //cursor: pointer;
                        color: $color-hover-link;
                    }
                }
                p {
                    color: $light-grey;
                    text-transform: uppercase;
                    display: inline-block;
                    margin: 0 20px 0 0;
                    font-size: $extra-small;
                    font-weight: 300;
                    font-family: 'Merriweather Sans';
                }
                .disabledLink {
                    color: $light-grey;
                    cursor: auto;
                }
            }
            dd {
                color: $color-disabled-link;
                text-transform: uppercase;
                font-family: 'merriweather sans';
                display: inline-block;
                margin: 0 20px 0 0;
                font-size: $extra-small;
                font-weight: 300;
            }
        }
    }
    /*Left fixed nav*/
    .left-nav {
        width: 70px;
        left: 0%;
        top: -5px;
        position: absolute;
        display: inline-block;
        text-align: left;
        transition: all 0.3s linear -0.1s;
        @media screen and (max-width: 768px) {
            width: 55px;
            a {
                padding-left: 0px !important;
            }
        }
        ul {
            margin-left: -15px;
            li {
                background: transparent url('/user/img/songs/sep2.png');
                background-size: 300px 10px;
                background-position: left bottom;
                background-repeat: no-repeat;
                width: 85px;
                padding: 0px 32px;
                padding-bottom: 5px;
                padding-left: 30px;
                &:last-child {
                    background: none;
                }
            }
            a {
                display: block;
                font-weight: 300;
                font-family: 'Merriweather';
                color: $pink;
                font-size: $medium;
                height: 27px;
                margin-top: 8px;
                margin-bottom: 3px;
                &:hover {
                    color: $dark-grey;
                }
                &.active {
                    cursor: default;
                    color: $color-active-link;
                    font-weight: 300;
                    text-decoration: underline;
                }
                &.disable {
                    color: $light-grey;
                    cursor: default;
                }
            }
            &.child-list {
                margin-top: -12px;
                li {
                    background: none;
                    height: 15px;
                    a {
                        font-size: $small;
                    }
                }
            }
        }
    }
}
