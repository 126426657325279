.thumbnail.word {

  .summary {
    .sub-title.item-title-translation {
      font-size: $large;
    }


  }


}

