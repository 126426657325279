* {
    word-break: normal !important;
}

.menu-container {
    width: auto;
    margin-left: 125px;
    margin-top: 5px;
    margin-right: 5px;
}

header ul {
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    clear: right;
    padding: 1% 0;
    border-bottom: solid 1px #d9d9d9;
    color: #444;
    &:last-child {
        border: 0;
    }
    li {
        padding: 0 1.5%;
        border-left: solid 1px #c7c6c5;
        display: inline-block;
        float: left;
        line-height: 15px;
        &:first-child {
            border: 0;
            padding-left: 0;
        }
        @media screen and (max-width: 770px) {
            padding: 0 1%;
        }
        a {
            float: left;
            &.active {
                color: $pink !important;
                //cursor: default !important;
            }
        }
    }
    &.top-line {
        float: right;
        text-align: right;
        border: 0;
        padding: 0;
        .selected {
            color: $color-link;
        }
        li {
            float: right;
            padding: 0 1%;
            font-size: $small;
            font-weight: 300;
            border-left: solid 1px #c6c6c5;
            &:hover {
                cursor: pointer;
                color: $color-hover-link;
            }
            a{
                color: $color-sub-link;
                &:hover{
                    color: $color-hover-link;
                }
            }

            &:last-child,
            &:nth-last-child(2) {
                border: 0;
            }
            &:first-child {
                padding-right: 0;
            }
            &.selected {
                border-left: solid 1px #c6c6c5;
                color: $color-link;
            }
            &.hindi {
                font-size: 15px;
                letter-spacing: 0.05em;
                padding: 0 0.5% 0 1%;
                &.disable {
                    cursor: default;
                    color: $color-disabled-link;
                }
            }
            &.english {
                padding: 0 0 0 0.5%;
            }
            @media screen and (max-width: 770px) {
                font-size: $extra-small;
            }
        }
    }
    &.first-line {
        margin-top: 0;
        padding: 19px 0 10px;
        & .songs-sub-menu {
            display: block;
            border: none !important;
            padding: 0;
            float: left;
            width: 0px;
            height: 0px;
            overflow: hidden;
            margin-top: 3px;
            li {
                padding-right: 15px;
                padding-left: 15px;
                border-left: solid 1px #c7c6c5;
                float: left;
                @media all and (max-width: 992px) {
                    padding-left: 6px;
                    padding-right: 6px;
                }
                &:first-child {
                    border: none !important;
                    padding-left: 15px;
                    @media all and (max-width: 992px) {
                        padding-left: 6px;
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
                a {
                    font-size: $medium;
                    text-transform: lowercase;
                    line-height: 16px;
                    padding-bottom: 1px;
                    &:hover {
                        color: $pink;
                    }
                }
            }
        }
        li > a {
            font-weight: 300;
            color: $color-highlight-text;
            font-size: $extra-large;
            line-height: 22px;
            &.disable {
                cursor: default;
                color: $color-disabled-link;
                &:hover {
                    color: $color-disabled-link;
                }
            }
            &.selected {
                color: $color-link;
            }
            &:hover {
                color: $color-hover-link;
            }
            @media screen and (max-width: 770px) {
                font-size: $large;
            }
        }
    }
    &.second-line {
        & .reflections-sub-menu {
            border: none !important;
            padding: 0;
            float: left;
            width: 0px;
            height: 0px;
            overflow: hidden;
            li {
                padding-right: 10px;
                padding-left: 10px;
                border-left: solid 1px #c7c6c5;
                @media all and (max-width: 992px) {
                    padding-left: 5px;
                    padding-right: 5px;
                }
                &:first-child {
                    border: none !important;
                    padding-left: 10px;
                    @media all and (max-width: 992px) {
                        padding-left: 5px;
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
                a {
                    font-size: $medium;
                    text-transform: lowercase;
                    &:hover {
                        color: $pink;
                    }
                }
            }
        }
        li {
            padding: 0 1%;
            &:first-child {
                padding-left: 0;
            }
            & > a {
                font-weight: 300;
                color: $dark-grey;
                font-size: $medium;
                cursor: pointer;
                &.disable {
                    cursor: default !important;
                    color: $color-disabled-link;
                    &:hover {
                        color: $color-disabled-link;
                    }
                }
                &:hover {
                    color: $color-hover-link;
                    cursor: pointer;
                }
                &.selected {
                    color: $color-link;
                }
                @media screen and (max-width: 770px) {
                    font-size: $small;
                }
            }
        }
        .search-box {
            float: right;
            margin-right: 0;
            border-bottom: 1px dotted #B3B3B3;
            width: 200px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-top: -5px;
            @media all and (max-width: 992px) {
                width: 20%;
            }
            input {
                padding: 5px;
                border: 0;
                &#searchKeyword {
                    height: 25px;
                    padding-left: 0;
                    width: calc(100% - 16px);
                    float: left;
                    background-color: transparent;
                    font-size: 14px;
                    font-family: 'Merriweather Sans', 'Merriweather', lucida grande, arial, tahoma, verdana, sans serif;
                    color: #888;
                    font-weight: 300;
                    position: relative;
                    z-index: 999;
                    &:focus {
                        outline: 0;
                        border: 0;
                    }
                }
                &.seach-btn {
                    background-image: url("/user/img/common/icons/search.png");
                    background-repeat: no-repeat;
                    background-color: transparent;
                    background-size: contain;
                    width: 16px;
                    height: 16px;
                    padding-bottom: 0;
                    cursor: pointer;
                    padding-right: 0;
                    outline: 0;
                }
            }
            img {
                width: 30px;
                float: right;
            }
        }
    }
}

#container[class-tag='contribute-section']>div:first-child {
}

@media all and (max-width:1024px) {
    .jcarousel-control-prev,
    .jcarousel-control-next {
        top: 145px !important;
    }
}
.signin-aganda{
    font-size: 14px;
}
.loading {
    vertical-align: middle;
    width: 20px;
    display: none;
    position: relative;
    float: left;
    margin-right: 10px;
}

.loading:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.loding-svg {
    -webkit-animation: loginloading 2s linear infinite;
    animation: loginloading 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loading-circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: circleAnimate 1.5s ease-in-out infinite;
    animation: circleAnimate 1.5s ease-in-out infinite;
    stroke-linecap: round;
}

.btnlabel {
    vertical-align: middle;
    margin-top: 3.5px;
    float: left;
}

@-webkit-keyframes loginloading {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes loginloading {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@-webkit-keyframes circleAnimate {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px
    }
    to {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px
    }
}

@keyframes circleAnimate {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px
    }
    to {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px
    }
}
