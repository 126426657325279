/********************************** For text content in lightboxes  ********************************************/
.media-introduction {
    .text-content {
       p ,div{
            margin-top: 0.8em;
            font-family: 'Merriweather';
            font-size: $medium;
            line-height: 24px;
            font-weight: 300;
            color: $color-highlight-text;
        }

        & > div:first-of-type {
           p::first-letter {
              font-size: $extra-large;
              font-weight: 400;
              line-height: 27px;
              color: $dark-grey;
           }
        }

    a {
        color: $pink;
    }


    }

}

/********************************** For text content in detail pages ********************************************/
.detail-content .text-content {
  p,div {
    margin-top: 0.5em;
    font-family: 'Merriweather';
    font-size: $medium;
    font-weight: 300;
    color: $color-highlight-text;
    text-align: left;
    line-height: 24px;
    word-break: keep-all;

  }
  h1, h2, h3, h4, h5, h6 {
    color: $dark-grey;
  }
  a {
    color: $pink;
  }

  #text-section:first-child{
    div > p:first-child:first-letter {
        font-size: $extra-large;
        font-weight: 400;
        line-height: 27px;
        color: $color-highlight-text;
    }
  }
  .couplet, .couplet p {
    font-family: 'Merriweather', serif;
    line-height: 21px;
    font-size: $medium;
    color: $grey;
    font-weight: 300;
    font-style: italic;
    .hindi {
      font-family: 'Lohit Devanagari';
      font-style: normal;
    }
  }
}

.clickable-grey{
  color: $clickable-grey;
}
