.comma {
  color: #808080;
}
#films_details {
  min-height: 1210px;
  width: 100%;
  background: transparent url(/user/img/films/filmsDetail.jpg) 0 0 repeat;
  background-size: cover;
  .detail-text-wrapper {
    margin: 20px auto 130px;
    /*padding-top: 0;*/
  }
  .films-episode-description{
    font-size: 12px;
    font-family: 'Merriweather Sans';
    color: #4D4D4D;
    font-weight: 100;
  }
  #audio_video_container {
    margin-top: -5px;
    @media all and  (max-width: 1280px) {
      width: 100% !important;
    }
  }
  .page-title {
    margin-left: 100px;
    text-align: left;
    @media all and (max-width: 1024px) {
      // margin: 0px;
      // padding:30px;
      // position: relative;
      // float: left;
      // margin-left: 2%;
      // width: 100%;
      // padding-left: 0px;
      // float: left;
    }
  }
  .sub-main {
    font-family: 'Merriweather';
    font-style: italic;
    color: #999;
  }
  .jcarousel-control-prev {
    background: transparent url(/user/img/songs/arrow-left.png) 0 0 no-repeat;
    z-index: 10 !important;
    left: 16px;
  }
  .jcarousel-control-next {
    background: transparent url(/user/img/songs/arrow-right.png) 0 0 no-repeat;
    z-index: 10 !important;
    right: 16px;
  }
  .left-nav {
    li {
      background: none;
      padding-bottom: 0;
      padding-left: 15px;
      @media all and (max-width: 1024px) {
        padding-left: 21px;
      }
      a {
        cursor: pointer;
        margin: 0;
      }
      & .active {
        color: #4d4d4d;
        text-decoration: underline;
      }
    }
    .seperater {
      background: transparent url('/user/img/songs/sep2.png');
      background-size: 300px 10px;
      background-position: left bottom;
      background-repeat: no-repeat;
      height: 7px;
      margin-bottom: 8px;
    }
  }
  .ajab-wrapper {
    width: 98%;
    padding: 0;
    display: block;
    margin: 0 auto;
    & .main-content {
      margin-top: 210px;
      // @media all and (max-width: 1024px) {
      //   margin-top: 0px !important;
      // }
    }
    & .filmTitle {
      font-size: $large;
      color: $dark-grey;
      & span {
        font-size: $medium;
        font-style: italic;
      }
    }
    & .filmByBlock {
      margin-top: 5px;
      font-size: $small;
      font-family: 'Merriweather Sans';
      color: $grey;
    }
    & .filmBy {
      color: $pink;
      // @media all and (max-width: 1024px) {
      //   line-height: 20px;
      // }
      & a {
        color: $pink;
        text-transform: uppercase;
        &:hover {
          color: $dark-grey;
        }
      }
    }
    .filmDescription {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      width: 853px;
      span {
        color: $grey;
        font-weight: 300;
        b {
          font-weight: 300;
        }
      }
      a {
        color: $pink;
        font-weight: 300;
      }
      h2 {
        font-size: $extra-large;
        color: $dark-grey;
        margin-bottom: 20px;
        margin-top: 10px;
      }
    }
    .logo-container {
      position: absolute;
      // @media all and (max-width: 1024px) {
      //   position: relative;
      // }
      @media screen and (max-width: 768px) {
        left: 0.5%;
      }
      & > a {
        font-size: 18px;
      }
    }
    .content-wrapper {
      box-sizing: border-box;
      padding: 2%;
      width: 70%;
      text-align: left;
      margin: -30px auto 0;
      min-height: 1300px;
      font-weight: 300;
    }
  }
  .crewDetails {
    width: 670px;
    height: 214px;
    margin: 0 auto;
    background: transparent url(/user/img/films/crew_bg.png) 0 0 repeat;
  }
  & .andSymbol {
    color: $grey;
  }
}
