$small-desktop: new-breakpoint(max-width 1380px);
$generic-notebook: new-breakpoint(max-width 1280px);
$medium-desktop: new-breakpoint(max-width 1024px);
$medium-tablet: new-breakpoint(max-width 998px);
$tablet: new-breakpoint(max-width 768px);
#radio_page {
  width: 100%;
  height: 100vh;
  position: relative;
  opacity: 0;
  &.radio_bg_1 {
/*    background: transparent url(/user/img/radio/radio_bg_1.jpg) 0 0 repeat fixed;
    background-size: cover;*/

  }
  .g-box-full{
    display: none !important;
  }
  .ajab-wrapper {
    width: 90%;
    padding: 0;
    display: block;
    margin: 0 auto;
    .logo-container {
      position: fixed;
      top: 30px;
      left: 2.5%;
      cursor: default;
      @media screen and (max-width: 768px) {
        left: 0.5%;
      }
      & > a {
        font-size: 18px;
        color: $dark-grey;
      }
    }
  }
  .separator {
    font-size: 11px;
  }
  .shopSeparator {
    font-size: 11px;
  }
  .radioWrapper {
    width: 900px;
    height: 500px;
    text-align: center;
    display: inline-block;
    margin-top: 35px;
    position: absolute;
    bottom: 110px;
    left: 25%;
    font-family: 'Merriweather';
    font-weight: 100;
    @include media($generic-notebook) {
      margin-top: 0;
      width: 100%;
      left: 0%;

    }
    @include media($tablet) {
      width: 100%;
      height: 450px;
      margin-top: 40px;
      left: 0;
    }

    @media all and (max-width: 992px) {
      top:30%;
      bottom: 0px;
    }
    .bubble_links {
      margin-top: 5px;
      margin-bottom: 5px;
      a {
        color: $pink;
        font-size: $small;
        font-family: 'Merriweather Sans';
        font-weight: normal;
        &:hover {
          color: $dark-grey;
        }
      }
    }
    .bubble-block-text {
      line-height: 1.1em;
      margin-top: 5px;
    }
    .bubble-static-text {
      /* line-height: 1.1em;
*/
      color: $dark-grey;
      font-size: 12px;
      margin-bottom: 5px;
      margin-top: 5px;
      a {
        text-decoration: underline;
        &:hover {
          color: $pink;
        }
      }
    }
    .bubble_text {
      line-height: 1.1em;
      width: 229px;
      height: 249px;
      background: transparent url("/user/img/radio/bubble_text_1.png") 0 0 no-repeat;
      position: absolute;
      left: 280px;
      @include media($medium-tablet) {
        left: 13%;
      }
      @include media($tablet) {
        left: 7%;
      }
      p {
        width: 175px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 65px;
        line-height: 1.2em;
        font-size: 18px;
        color: $dark-grey;
      }
    }
    .bubble_text_2 {
      width: 390px;
      height: 427px;
      background: transparent url("/user/img/radio/bubble_text_2.png") 0 0 no-repeat;
      position: absolute;
      bottom: 0;
      right: 30px;
      color: $grey;
      @include media($medium-tablet) {
        right: 90px;
      }
      font-weight: normal;
      .bubble-desc {
        width: 280px;
        margin-left: auto;
        margin-right: auto;
        .bubble-heading {
          font-size: $medium-large-dev;
          color: $dark-grey;
          margin-bottom: 5px;
          line-height: 1em;
        }
        .bubble-text {
          color: $dark-grey;
          font-size: $small;
        }
      }
    }
  }
  .audio-player {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .bubble-img-height {
    height: 346px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
