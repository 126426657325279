#films_page {
    min-height: 1210px;
    width: 100%;
    font-family: 'Merriweather';
    position: relative;
    & .pagination-head {
        margin-top: 0;
    }
    .removePipe.view-btn::before {
        content: "";
        padding: 0;
    }
    .film-readmore {
        position: relative;
        overflow: hidden;
        top: 10px;
    }
    .read-mores {
        &:hover {
            color: #4d4d4d;
        }
        position: relative;
        bottom: 0px;
        padding-right: 5px;
        left: 0px;
        color: #E61B72;
        font-size: 12px;
        text-transform: uppercase;
        font-family: 'Merriweather Sans';
        cursor: pointer;
        float: left;
    }
    a.read-more,
    a.read-less {
        position: absolute;
        bottom: 5px;
        left: 0px;
    }
    &.films_bg_1 {
        background: transparent url(/user/img/films/film_01.jpg) 0 0 repeat;
        background-size: 100%;
    }
    &.films_bg_2 {
        background: transparent url(/user/img/films/film_02.jpg) 0 0 repeat;
        background-size: 100%;
    }
    &.films_bg_3 {
        background: transparent url(/user/img/films/film_03.jpg) 0 0 repeat;
        background-size: 100%;
    }
    &.films_bg_4 {
        background: transparent url(/user/img/films/film_04.jpg) 0 0 repeat;
        background-size: 100%;
    }
    .original-html-size {
        font-size: 14px;
        //line-height: 1.5em;
    }
    .view-btn {
        background-position: 70px 5px !important;
    }
    .no-pipe {
        background-position: 60px 5px !important;
    }
    .ajab-wrapper {
        width: 98%;
        padding: 0;
        display: block;
        margin: 0 auto;
        .logo-container {
            position: absolute;
            top: 25px;
            left: 17.5%;
            // @media screen and (max-width: 768px) {
            //     left: 0.5%;
            // }
            & > a {
                font-size: 18px;
                color: $dark-grey;
                cursor: default;
            }
            .clearfix {
                clear: both;
            }
            .filmsCount {
                margin-top: 14px;
                font-size: 12px;
                color: $grey;
                font-family: 'Merriweather Sans';
            }
        }
        .content-wrapper {
            background: transparent url('/user/img/films/films_bg.png') left top;
            background-size: 100% auto;
            padding: 12% 5%;
            width: 75%;
            text-align: left;
            margin: -30px auto 0;
            min-height: 1300px;
            font-weight: 300;
            @media all and (max-width: 1024px) {
                padding-top: 160px;
            }
            & .filmsBlock {
                line-height: 20px;
                margin-bottom: 35px;
                & .filmImg {
                    float: left;
                    width: 255px;
                    text-align: left;
                    img {
                        width: 99%;
                        height: 150px;
                        border: 3px solid #fff;
                        box-shadow: 1px 1px 10px #777;
                    }
                }
                & .filmContentBlock {
                    text-align: left;
                    float: left;
                    width: calc(97% - 255px);
                    margin-left: 3%;
                    position: relative;
                    @media all and (max-width: 992px) {
                        width: 100%;
                        margin-left: 0px;
                        margin-top: 15px;
                        height: auto;
                    }
                    & .filmContentLink,
                    & .read-link {
                        color: $pink;
                        font-size: 12px;
                        text-transform: uppercase;
                        font-family: 'Merriweather Sans';
                        float: left;
                        &:hover {
                            color: $dark-grey;
                        }
                    }
                    & .read-link {
                        &:after {
                            padding-left: 5px;
                            content: "|";
                        }
                    }
                    & .view-film {
                        background: transparent url('/user/img/people/explore-icon.png') 60px 5px no-repeat;
                        background-size: 15px 16px;
                        display: inline-block;
                        width: 100px;
                    }
                    & h1 {
                        display: inline;
                        margin-right: 10px;
                        font-size: $extra-large;
                        color: $dark-grey;
                        &:hover {
                            color: $pink;
                        }
                    }
                    & h2 {
                        font-size: $large;
                        color: $grey;
                        display: inline;
                    }
                    & .mins {
                        color: #808080;
                        font-size: $small;
                        font-family: 'Merriweather Sans';
                        & .filmBy {
                            color: $pink;
                            font-family: 'Merriweather Sans';
                            text-transform: uppercase;
                            &:hover {
                                color: $dark-grey;
                            }
                            & .comma {
                                color: $dark-grey !important;
                            }
                        }
                    }
                    p {
                        word-break: break-word;
                        text-align: left;
                        font-size: $medium;
                    }
                    & .filmContent {
                        color: #808080;
                        font-size: $medium-large;
                        position: relative;
                        overflow: hidden;
                        height: 125px;
                        padding-top: 2px;
                       /* @media all and (max-width: 992px) {
                            height: auto;
                            /*max-height: 118px;*
                        }*/
                        br {
                            line-height: 1em;
                        }
                        .retitle1 {
                            padding-top: 2px;
                        }
                    }
                }
            }
            & .andSymbol {
                color: $grey;
            }
            & .clearfix {
                clear: both;
            }
        }
    }
}
