.comment-active {
    background-position: -228px -280px !important;
}

sound-cloud {
    margin-top: 10px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
}

#participateView {
    width: 100%;
    background: transparent url("/user/img/participate/participate_view.jpg") 0 0 repeat fixed;
    background-size: 100%;

    .clearfix {
        clear: both;
    }

    .logo-with-filter {
        z-index: 80;
        position: absolute;
    }

    .notes-block {
        @media all and (max-width: 992px) {
            position: relative;
        }
    }

    .participateContent {
        width: 800px;
        margin-left: auto;
        margin-right: auto;
        @media all and (max-width: 992px) {
            width: 100%;
            padding: 30px;
        }

        h1 {
            font-size: $large;
            margin-bottom: 10px;
        }

        h2 {
            font-size: $medium;
            color: $dark-grey;
            margin-bottom: 10px;
            font-family: "Merriweather Sans", "Merriweather", lucida grande, arial, tahoma, verdana, sans serif;
        }

        .audio_video_container {
            margin-top: 10px;
            margin-bottom: 20px;
            float: left;
            width: 100%;
        }

        .audio_video_container youtube {
            width: 100%;
            display: inline-block;
            height: 480px;
        }

        .audio_video_container youtube iframe {
            width: 853px;
            display: inline-block;
            height: 480px;
            box-shadow: rgb(119, 119, 119) 1px 1px 10px;
            border-image-source: initial;
            border-image-slice: initial;
            border-image-width: initial;
            border-image-outset: initial;
            border-image-repeat: initial;
            width: inherit;
            height: inherit;
            border-width: 5px;
            border-style: solid;
            border-color: rgb(255, 255, 255);
        }

        .participateDescription {
            margin-bottom: 20px;
            color: $grey;
            font-size: 14px;
            line-height: 1.5em;
            text-align: left;
            font-family: 'Merriweather';
            float: left;
        }
    }

    .category-logo {
        padding-top: 34px !important;
        color: #4d4d4d !important;
        cursor: default !important;
        font-size: 18px !important;
        margin-left: -2px;
        font-family: 'Merriweather';

        a {
            cursor: pointer !important;
            padding: 4px;
            border-radius: 30px;
            background-color: #FFF;
            margin-left: -10px;
            color: $pink;
            opacity: 0.85;

            &:hover {
                color: $dark-grey;
            }
        }
    }

    .crew-parda-info {
        background: transparent url("/user/img/couplets/couplets_bottom.png") 0 0 no-repeat;
        height: 75px;
        width: 100%;
        display: inline-block;
        position: relative;
        bottom: 0;
        @media all and (max-width: 992px) {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            height: auto;
            min-height: 75px;
            background-repeat: repeat-y;
        }

        .crew-details {
            position: absolute;
            left: 25%;
            height: 45px;
            margin-left: -20px;
            top: 25px;
            width: 800px;
            line-height: 1.2em;
            text-align: center;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            @media all and (max-width: 992px) {
                position: relative;
                left: auto !important;
                padding-left: 30px;
                padding-right: 30px;
                margin-left: 0;
                width: calc(100% - 100px);
                height: auto;
            }

            & .crew-block {
                margin-bottom: 10px;

                .crew-no-margin {
                    margin-right: 0 !important;
                }

                & .crew-person-block {
                    display: inline-block;
                    font-size: 14px;
                    font-family: 'Merriweather';
                    margin-right: 30px;

                    & .crew-name {
                        & .italicise {
                            font-style: italic !important;
                        }

                        & a {
                            color: $grey;
                            font-weight: 300;
                            font-family: 'merriweather';

                            &:hover {
                                color: $pink;
                            }
                        }
                    }

                    & .crew-dept {
                        color: $light-grey;
                        font-family: 'Merriweather Sans';
                        font-size: 10px;
                    }
                }
            }
        }

        .no-notes-margin {
            left: 5px !important;
        }

        ul.share-options {
            text-align: left;
            float: left;
            position: relative;
            font-size: 16px;
            width: auto;
            top: 30px;
            left: 10px;

            li {
                padding: 10px;
                float: left;
                color: #666;
                font-weight: 200;
                line-height: 20px;

                a {
                    background: transparent url("/user/img/common/icons/sprite_icons.png") 0 0 no-repeat;
                    display: inline-block;
                    width: 26px;
                    height: 23px;

                    &.favorite {
                        background: transparent url("/user/img/songs/songs_sprite.png") 0 0 no-repeat;
                        background-position: 0 -25px;
                        height: 22px;
                    }

                    &.share {
                        width: 16px;
                        height: 15px;
                        background-position: -285px -15px;

                        &.active {
                            width: 16px;
                            height: 15px;
                            background-position: -199px -241px;
                            cursor: default;
                        }
                    }

                    &.comment {
                        background-position: -87px -280px;
                        width: 21px;
                        height: 16px;

                        &:hover {
                            background-position: -228px -280px;
                        }
                    }

                    &.comment-icon-active {
                        background-position: -228px -280px !important;
                    }

                    &.download {
                        width: 18px;
                        height: 17px;
                        background-position: -189px -15px;

                        &.active {
                            width: 18px;
                            height: 17px;
                            background-position: -15px -62px;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .notes-link {
            position: absolute;
            left: 15px;
            bottom: 22px;
            font-size: $medium;
            color: $grey;
            font-weight: 300;

            & > a {
                font-size: $medium;
                color: $pink;
                text-transform: lowercase;
                font-family: 'Merriweather';
                font-weight: 500;

                &:hover {
                    color: $dark-grey;
                }
            }
        }

        .notes-toggle-active {
            color: $dark-grey !important;
            text-decoration: underline !important;
        }

        .play-icon {
            position: absolute;
            right: 13%;
            bottom: 40px;
            background: transparent url("/user/img/songs/songs_sprite.png") -52px 0 no-repeat;
            width: 50px;
            height: 102px;
            display: inline-block;
        }
    }

    .emptySpace {
        height: 120px;
    }

    .participateDescription h1 {
        color: $dark-grey;
        font-size: 30px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .participateDescription h2 {
        color: $dark-grey;
        font-size: 21px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .participateDescription h3 {
        color: $dark-grey;
        font-size: 18px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .participateDescription h4 {
        color: $dark-grey;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .participateDescription h5 {
        color: $dark-grey;
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .participateDescription h6 {
        color: $dark-grey;
        font-size: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .participateDescription p {
        font-size: 14px;
        text-align: left;
        padding-top: 5px;
        padding-bottom: 5px;
        color: $dark-grey;
    }

    .participateDescription a {
        color: $pink;
    }

    .participateDescription a:hover {
        color: $dark-grey;
    }
}
