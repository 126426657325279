.header-with-carousel {
  .vertical-spacer {
    min-height: 155px;
    width: 100%;
    position: relative;
  }

  .lower-pardah {

    .versions {
      .carousel-wrapper {
        .jcarousel.thumbnail-carousel {

          > ul li {
            /*margin: 0 0 0 47px;*/

            &.selected {
              pointer-events: none;
              .bubble{
                    background: url(/user/img/common/directives/thumbnail/bubble-250-content-active.png) scroll repeat-y top left;
                    
              }
              .summary{
                color: #fff;
                &:before{
                  background: url(/user/img/common/directives/thumbnail/bubble-250-top-active.png) scroll repeat-y top left;
                }
                &:after{
                      background: url(/user/img/common/directives/thumbnail/bubble-250-bottom-active.png) scroll repeat-y top left;
                }
              }
              .en-title,.episode-detail-text,.singers,.categoryName {
                    color: #000;
                }

            }

            .thumbnail {
              width: 245px;

            }
          }
        }
      }
    }
  }
}
