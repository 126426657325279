.stories-thumbnail-block {
    .thumbnail img {
        width: 100%;
        height: 100%;
    }
}


#story {
    width: 100%;
    background: transparent url(/user/img/classroom/classroom-bg.jpg) 0 0 repeat fixed;
    margin-top: -20px;
    padding-bottom: 160px;
    .clearfix {
        clear: both;
    }
    .edit-custom {
        display: none !important;
    }
    .note-parada {
        // width: auto;
        // padding: 45px 0 40px 15px;
        // background: transparent url('/user/img/songs/relatedlinks_parda.png') 0 0 no-repeat;
        // background-size: 100% 100%;
        .story-text {
            font-family: 'Merriweather';
            font-weight: 300;
            margin-top: 25px;
        }
    }
   /* .category-logo {
        padding-left: 14px !important;
    }*/
    .logo-with-filter {
        z-index: 80;
        position: relative;

    }
    .comment-active {
        background-position: -228px -280px !important;
    }
    .storyToolHtml {
        width: 900px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        @media all and (max-width: 1024px) {
            width: 95%;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
           /* font-family: 'Merriweather';*/
            font-weight: normal;
            /*color: $dark-grey;*/
        }
        .im-story-loading {
            text-align: center;
            height: 300px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            .loding-svg{
              display: block;
              width: 80px;
              position: relative;
              circle{
                stroke: #868686;
              }
            }
        }
    }
    .category-logo {
        padding-top: 33px !important;
        color: #4d4d4d !important;
        cursor: default !important;
        font-size: 18px !important;
        margin-left: 7px;
        font-family: 'Merriweather';
        a {
            cursor: pointer !important;
            padding: 4px;
            border-radius: 30px;
            //  background-color: #FFF;
            /*background: rgba(255, 255, 255, 0.85);*/
            margin-left: 8px;
            color: $pink;
            &:hover {
                color: $dark-grey;
            }
        }
        .classroom-inner {
            /*marsgin-left: 2px;*/
        }
    }

    .notes-active{
        color: #908686;
    }
    .crew-parda-info {
        background: transparent url(/user/img/couplets/couplets_bottom1.png) 0 0 no-repeat;
        min-height: 50px;
        background-size: cover;
        width: 100%;
        display: block;
        position: relative;
        bottom: 0;
        z-index:100;
        .notes-block {
            height: 100%;
        }
        .no-notes-margin {
            left: 5px !important;
        }
        ul.share-options {
            text-align: left;
            float: left;
            position: relative;
            font-size: 16px;
            width: auto;
            left: 55px;
            @media all and (max-width: 1024px) {
                left: 0px;
            }
            top:calc(50% - 20px);
            .notes-anchor-link {
                color: #E61B72;
                cursor: pointer;
            }
            li {
                padding: 15px 10px;
                float: left;
                color: #666;
                font-weight: 200;
                line-height: 20px;
                a {
                    background: transparent url(/user/img/common/icons/sprite_icons.png) 0 0 no-repeat;
                    display: inline-block;
                    width: 26px;
                    height: 23px;
                    margin-top:5px;
                    &.favorite {
                        background: transparent url(/user/img/songs/songs_sprite.png) 0 0 no-repeat;
                        background-position: 0 -25px;
                        height: 22px;
                    }
                    &.share {
                        width: 16px;
                        height: 15px;
                        background-position: -285px -15px;
                        &.active {
                            width: 16px;
                            height: 15px;
                            background-position: -199px -241px;
                            cursor: default;
                        }
                    }
                    &.comment {
                        background-position: -87px -280px;
                        width: 21px;
                        height: 16px;
                        &:hover {
                            background-position: -228px -280px;
                        }
                    }
                    &.comment-icon-active {
                        background-position: -228px -280px !important;
                    }
                    &.download {
                        width: 18px;
                        height: 17px;
                        background-position: -189px -15px;
                        &.active {
                            width: 18px;
                            height: 17px;
                            background-position: -15px -62px;
                            cursor: default;
                        }
                    }
                }
            }
        }
        .notes-link {
            position: absolute;
            left: 15px;
            bottom: 22px;
            font-size: $medium;
            color: $grey;
            font-weight: 300;
            & > a {
                font-size: $medium;
                color: $pink;
                text-transform: lowercase;
                font-family: 'Merriweather';
                font-weight: 500;
                &:hover {
                    color: $dark-grey;
                }
            }
        }
        .notes-toggle-active {
            color: $dark-grey !important;
            text-decoration: underline !important;
        }
        .play-icon {
            position: absolute;
            right: 13%;
            bottom: 40px;
            background: transparent url(/user/img/songs/songs_sprite.png) -52px 0 no-repeat;
            width: 50px;
            height: 102px;
            display: inline-block;
        }
        .crew-details {
            // position: absolute;
            // left: 25%;
            // height: 100%;
            // margin-left: -20px;
            // top: 0px;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: 0 auto;
            width: 800px;
            line-height: 1.2em;
            text-align: center;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            @media all and (max-width: 992px) {
                width: 600px;
            }
            & .crew-block {
                .crew-no-margin {
                    margin-right: 0 !important;
                }
                & .crew-person-block {
                    display: inline-block;
                    font-size: 14px;
                    font-family: 'Merriweather';
                    margin-right: 30px;
                    & .crew-name {
                        & .italicise {
                            font-style: italic !important;
                        }
                        & a {
                            color: $grey;
                            font-weight: 300;
                            font-family: 'merriweather';
                            &:hover {
                                color: $pink;
                            }
                        }
                    }
                    & .crew-dept {
                        color: $light-grey;
                        font-family: 'Merriweather Sans';
                        font-size: 10px;
                    }
                }
            }
        }
    }
    .emptySpace {
        height: 24px;
    }
    // .img-text-wrapper{
    //   position: absolute;
    //   bottom: 0px;
    //   width: 100%;
    //   background-color: rgba(0,0,0,0.5);
    //   padding-left: 50px;
    // }
    // .img-text-wrapper p{
    //   color: #FFFFFF;
    //   margin-bottom: 0px;
    // }
    .videoIframe {
        height: 500px;
        width: 100%;
    }
    .videoItem {
        height: 500px;
    }
    #contentarea .item {
        position: relative;
    }
}
.story-loader-wapper{
  width: 200px;
  height: 200px;
}
.story-loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.story-loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.story-loader:after {
  background: #0dc5c1;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}