 #resources_more_page {
   &.resources-container{
     padding: 0px !important;
   }
   .more-complete-info{
     height: 125px;
     padding-top: 6px;
     overflow: hidden;
   }
   .more-block {
     text-align: left;
     margin-top: 5px;
     margin-bottom: 30px;
      .no-margin-left{
        margin-left: 0px;
      }
       .more-title {
         color: $color-icon;
         font-family: 'Merriweather Sans';
         text-transform: uppercase;
         font-size: $small;
         margin-bottom: 5px;
       }
       .more-main-title {
         color: $dark-grey;
         font-family: 'Merriweather';
         font-size: 18px;
         text-transform: capitalize;
         margin-top: 5px;
         margin-bottom: 5px;
         font-weight: 100;
       }

       .more-desc {
         font-size: $small;
         font-style: italic;
         font-family: 'Merriweather Sans';
         color: $color-icon;
         margin-top: 5px;
         margin-bottom: 3px;
       }
       .more-content {
         color: $grey;
         font-family: 'Merriweather';
         font-size: $small;
         /*text-transform: capitalize;*/
         margin-top: 0px;
         margin-bottom: 5px;
         line-height: 20px;
       }
   }
   .more-image {
     float: left;
     width: 255px;
      img {
        width: 255px;
        width: 99%;
        border: 3px solid #FFF;
        box-shadow: 1px 1px 10px #777;
        height: 145px;
      }
   }
   .more-content-block {
     float: left;
     margin-left: 5px;
     width: calc(97% - 255px);
     margin-left: 3%;
     @media all and (max-width: 992px) {
         width: 100%;
         float: left;
         margin-top: 15px;
         margin-left: 0;
         margin-bottom: 30px;
     }
   }
 }
