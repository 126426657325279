#support_page {
    width: 100%;
    min-height: 900px;
    .ajab-wrapper {
        width: 90%;
        padding: 0;
        display: block;
        margin: 0 auto;
        .logo-container {
            position: fixed;
            top: 30px;
            left: 2.5%;
            @media screen and (max-width: 768px) {
                left: 0.5%;
            }
            & > a {
                font-size: 15px;
                color: $dark-grey;
            }
        }
    }
    &.support_bg_1 {
        background: transparent url(/user/img/support/support_bg_1.jpg) 0 0 repeat fixed;
        background-size: contain;
    }
    &.support_bg_2 {
        background: transparent url(/user/img/support/support_bg_2.jpg) 0 0 repeat fixed;
        background-size: 100%;
    }
    &.support_bg_3 {
        background: transparent url(/user/img/support/support_bg_3.jpg) 0 0 repeat fixed;
        background-size: contain;
    }
    &.support_bg_4 {
        background: transparent url(/user/img/support/support_bg_4.jpg) 0 0 repeat fixed;
        background-size: 100%;
    }
    .supportWrapper {
        background: transparent url(/user/img/support/support_bg.png)no-repeat;
        width: 780px;
        height: 800px;
        margin: 100px auto 0;
        padding: 60px;
        @media all and (max-width: 992px) {
            background: transparent url(/user/img/support/support_bg_992.png)no-repeat;
            width: 100%;
            background-size: contain;
        }
        & .contentWrapper {
            text-align: left;
            margin-bottom: 25px;
            font-size: 13px;
            line-height: 1.6em;
            color: $grey;
            font-weight: 200;
            & h4 {
                font-size: 16px;
                margin-bottom: 10px;
                color: $dark-grey;
            }
            & p {
                color: $grey;
                margin-bottom: 15px;
            }
            & .link {
                color: $pink;
                &:hover {
                    color: $dark-grey;
                }
            }
        }
    }
}
