#resources_film_page {
    //  min-height: 1210px;
    width: 100%;
    font-family: 'Merriweather';
    position: relative;

    .original-html-size {
        font-size: 14px;
        line-height: 1.4em;
    }

    &.resources-container {
        padding: 0 !important;
    }

    .view-btn {
        background-position: 70px 5px !important;
    }

    .no-pipe {
        background-position: 60px 5px !important;
    }

    .ajab-wrapper {
        width: 98%;
        padding: 0;
        display: block;
        margin: 0 auto;

        .filmsBlock {
            height: 150px;
            line-height: 24px;
            margin-bottom: 35px;

            & .filmImg {
                float: left;
                width: 255px;
                text-align: left;
                height: 100%;

                img {
                    width: 99%;
                    height: 100%;
                    border: 3px solid #fff;
                    box-shadow: 1px 1px 10px #777;
                }
            }

            & .filmContentBlock {
                text-align: left;
                float: left;
                width: calc(97% - 255px);
                margin-left: 3%;
                @media all and (max-width: 992px) {
                    width: 100%;
                    margin-top: 15px;
                    margin-left: 0;
                    margin-bottom: 30px;
                }

                & .filmContentLink,
                & .read-link {
                    color: $pink;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-family: 'Merriweather Sans';

                    &:hover {
                        color: $dark-grey;
                    }
                }

                & .read-link {
                    &:after {
                        padding-left: 5px;
                        content: "|";
                    }
                }

                & .view-film {
                    background: transparent url('/user/img/people/explore-icon.png') 60px 5px no-repeat;
                    background-size: 15px 16px;
                    display: inline-block;
                    width: 100px;
                }

                & h1 {
                    display: inline;
                    margin-right: 10px;
                    font-size: 18px;
                    color: $dark-grey;

                    &:hover {
                        color: $pink;
                    }
                }

                & h2 {
                    font-size: $large;
                    color: $color-resource-label;
                    display: block;
                }

                & .film-desc {
                    color: $grey;
                    font-family: 'Merriweather';
                    font-size: $small;
                    /*text-transform: capitalize;*/
                    margin-top: 0;
                    margin-bottom: 5px;
                    line-height: 20px;
                }

                & .mins {
                    color: #b3b3b3;
                    font-family: 'Merriweather Sans';
                    font-size: 12px;
                    font-style: italic;
                    line-height: 18px;
                    margin-top: -3px;
                    & .filmBy {
                        color: $pink;
                        font-family: 'Merriweather Sans';
                        text-transform: uppercase;

                        &:hover {
                            color: $dark-grey;
                        }

                        & .comma {
                            color: $dark-grey !important;
                        }
                    }
                }

                p {
                    word-break: break-word;
                    text-align: justify;
                    font-size: $medium;
                }

                & .filmContent {
                    color: #808080;
                    font-size: $medium-large;
                    height: 125px;
                    overflow: hidden;
                    padding-top: 2px;
                }
            }
        }

        & .andSymbol {
            color: $grey;
        }

        & .clearfix {
            clear: both;
        }
    }
}
