.thumbnails-grid {
    .thumbnail {
        width: 230px;
        margin: 0 auto;
        .image {
            width: 215px;
            height: 140px;
        }
        .bubble {
            background-image: url(/user/img/common/directives/thumbnail/bubble-215-content.png);
            width: 230px;
            top: 120px;
            left: -9px;
            @media all and (max-width: 1250px) {
                top: 0px;
                height: auto;
                position: relative;
            }
            .summary {
                &:before {
                    background-image: url(/user/img/common/directives/thumbnail/bubble-215-top.png);
                    width: 230px;
                    height: 25px;
                    top: -25px;
                    @media all and (max-width: 1250px){
                      top:-24px;
                    }
                }
                &:after {
                    background-image: url(/user/img/common/directives/thumbnail/bubble-215-bottom.png);
                    width: 230px;
                    height: 25px;
                    bottom: -25px;
                    @media all and (max-width: 1250px) {
                        position: relative;
                        bottom: auto;
                        left: -20px;
                        float: left;
                    }
                }
                .thumbnailDetails.toggleBySlide {
                    @media all and (max-width: 1024px) {
                        display: block !important;
                    }
                }
            }
        }
    }
}
