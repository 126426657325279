.disableOuterScrollbar {
  height: 100%;
}

.featured-all {
  .main-homepage-content {
    width: 70%;
    position: relative;
    margin: 18% auto 450px;

    @media screen and (max-width: 1024px) {
      margin-top: 34%;
    }
    @media screen and (max-width: 768px) {
      margin-top: 42%;
    }

    .thumbnail {
      margin-bottom: 27%;
      margin-top: 0px;

      @media screen and (max-width: 1280px) {
        margin-bottom: 31%;
      }
      @media screen and (max-width: 1024px) {
        margin-bottom: 41%;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 55%;
      }
    }

  }

  .shift {

    &1 {
      margin: 0 0 0 35%;
      @media screen and (max-width: 1024px) {
        margin-left: 32%;
      }
      @media screen and (max-width: 768px) {
        margin-left: 18%;
      }
    }
    &2 {
      margin: 0 0 0 42%;
      @media screen and (max-width: 1024px) {
        margin-left: 35%;
      }
      @media screen and (max-width: 768px) {
        margin-left: 28%;
      }
    }
    &3 {
      margin: 0 0 0 40%;
      @media screen and (max-width: 1024px) {
        margin-left: 27%;
      }
      @media screen and (max-width: 768px) {
        margin-left: 27%;
      }
    }
    &4 {
      margin: 0 0 0 38%;
      @media screen and (max-width: 1024px) {
        margin-left: 30%;
      }
      @media screen and (max-width: 768px) {
        margin-left: 27%;
      }
    }
    &5 {
      margin: 0 0 0 42%;
      @media screen and (max-width: 1024px) {
        margin-left: 33%;
      }
      @media screen and (max-width: 768px) {
        margin-left: 27%;
      }
    }
    &6 {
      margin: 0 0 0 30%;
      @media screen and (max-width: 1024px) {
        margin-left: 33%;
      }
      @media screen and (max-width: 768px) {
        margin-left: 27%;
      }
    }
    &7 {
      margin: 0 0 0 40%;
      @media screen and (max-width: 1024px) {
        margin-left: 33%;
      }
    }
    &8 {
      margin: 0 0 0 36%;
      @media screen and (max-width: 1024px) {
        margin-left: 25%;
      }
      @media screen and (max-width: 768px) {
        margin-left: 18%;
      }
    }
    &9 {
      margin: 0 0 0 40%;
      @media screen and (max-width: 1024px) {
        margin-left: 33%;
      }
      @media screen and (max-width: 768px) {
        margin-left: 27%;
      }
    }

  }
}
