#loader_wrapper {
  width: 100%;
  min-height: 1000px;
  background-color: #fff;
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  top: 0;
  display: none;

  & > span {
    background: #fff url(/user/img/common/icons/spinner.gif) 0 0px;
    height: 300px;
    width: 300px;
    display: block;
    margin: 10% auto 0;
  }

}
#Allloader_wrapper{
  width: 100%;
  min-height: 1000px;
  background-color: #fff;
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  top: 0;

  & > span {
    background: #fff url(/user/img/common/icons/spinner.gif) 0 0px;
    height: 300px;
    width: 300px;
    display: block;
    margin: 10% auto 0;
  }
}
